import { useSelector } from 'react-redux';
import CheckoutEventBookingDetails from "../../components/sections/CheckoutEventBookingDetails";
import CheckoutEventVolunteers from '../../components/sections/CheckoutEventVolunteers';
import CheckoutPaymentSection from "../../components/sections/CheckoutPaymentSection";
import ShouldAuthenticate from "../../components/sections/ShouldAuthenticate";
import UserLayout from "../../components/sections/UserLayout";
import CheckoutProvider, { CheckoutContext } from "../../context/CheckoutProvider";
import { TranslationLanguage, useT } from '../../i18n';
import { StoreState } from '../../redux/reducers';
import { EventCart } from '../../redux/reducers/eventCart.reducer';
import { Shop } from '../../redux/reducers/shop.reducer';
import ceckoutStyle from '../../styles/components/checkout-shopping-cart.module.scss';
import style from '../../styles/pages/checkout.module.scss';
import { toCurrencyFormat } from "../../utils";


const CheckoutEvent = () => {
    const t = useT()
    const eventCart = useSelector<StoreState, EventCart>((state) => state.eventCart);
    const shop = useSelector<StoreState, Shop | null>(state => state.shops.find(s => s.id === state.eventCart.shopId) || null)
    const translationLanguage = useSelector<StoreState, TranslationLanguage>(state => state.translationLanguage)
    return (
        <ShouldAuthenticate>
            <UserLayout>
                <CheckoutProvider>
                    <CheckoutContext.Consumer>
                        {context => {
                            return (
                                <>
                                    <div className='row'>
                                        <div className={style.title}>{eventCart.item?.names?.length! > 1 ?
                                            eventCart.item?.names.find(n => n.language === translationLanguage)?.name :
                                            eventCart.item?.names[0].name} {t("Booking")}</div>
                                        <div className='col-lg-12 order-2 order-lg-1'>
                                            {/* <SectionWithTitle title='Order type' className='mb-4' /> */}

                                            {/* <CheckoutCustomerInformation /> */}
                                            <CheckoutEventBookingDetails />
                                            <div className={ceckoutStyle.container}>
                                                <div className={`${ceckoutStyle.priceSection}`}>
                                                    <div className={ceckoutStyle.inTotal}>{t("In total")}</div>
                                                    <div className={ceckoutStyle.inTotal}>{toCurrencyFormat(context?.total, shop?.currency)}</div>
                                                </div>
                                            </div>
                                            {eventCart.item?.volunteers && eventCart.item?.volunteers.length !== 0 && <CheckoutEventVolunteers />}
                                            <CheckoutPaymentSection otherPayments={shop?.otherPayments} countinueHandler={context?.continueBookingClickHandler} eventType={eventCart.item?.eventType} paytrailStatus={shop?.paytrail.status} category='Event' />
                                        </div>
                                    </div>
                                </>
                            )
                        }}

                    </CheckoutContext.Consumer>
                </CheckoutProvider>

            </UserLayout>
        </ShouldAuthenticate>

    )
}

export default CheckoutEvent;