import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { UserContext, UserContextType } from '../../context/UserProvider'
import { TranslationLanguage, useT } from '../../i18n'
import { addFavourite, removeFavourite } from '../../redux/actions'
import { StoreState } from '../../redux/reducers'
import { Appointment } from '../../redux/reducers/appointment.reducer'
import { Favourite } from '../../redux/reducers/favourite.reducer'
import { AppDispatch } from '../../redux/store'
import { getAppointmentOfferPrice, getAppointmentPrice, monthNames } from '../../utils'
import Card from './Card'

export interface ShopAppointmentCategorySectionCardProps {
    appointment?: Appointment
}

export const ResponsiveShopAppointmentCard = (props: ShopAppointmentCategorySectionCardProps) => {
    return <div className='col-sm-6 col-lg-4 col-xxl-3 mb-3'>
        <div className='d-flex align-items-center justify-content-center'>
            <ShopAppointmentCard {...props} />
        </div>
    </div>
}

const ShopAppointmentCard = (props: ShopAppointmentCategorySectionCardProps) => {
    const userContext = React.useContext<UserContextType | null>(UserContext)
    const favourites = useSelector<StoreState, Favourite[]>(state => state.favourites.filter(fav => fav.type === 'item' && fav.shopId === props.appointment?.shopId))
    const dispatch = useDispatch<AppDispatch>()
    const t = useT()
    const [appointmentDate, setAppointmentDate] = useState<string>("")
    const translationLanguage = useSelector<StoreState, TranslationLanguage>(state => state.translationLanguage)
    const price = getAppointmentPrice(props.appointment)
    const offerPrice = getAppointmentOfferPrice(props.appointment)
    const navigate = useNavigate()

    const isFavourite = favourites.some(fav => fav.itemId && fav.itemId === props.appointment?.id)

    const onAddToCartClickHandler = () => {
        // if (props.appointment) {
        //     userContext?.openCartAppointmentModal?.(props.appointment)
        // }
        if (props.appointment) {
            navigate(`/appointment/${props.appointment?.shopId}/${props.appointment?.id}`)
        }
    }
    useEffect(() => {
        const fromDate = props.appointment?.date;
        const from = new Date(fromDate ? fromDate : '');
        const date = monthNames[from.getMonth()] + ' ' + from.getDate() + ', ' + from.getFullYear();
        setAppointmentDate(date)
    }, [props])
    const favouritesClickHandler = () => {
        if (props.appointment) {
            if (isFavourite) {
                dispatch(removeFavourite({
                    shopId: props.appointment?.shopId,
                    type: 'item',
                    itemId: props.appointment?.id
                }))
            } else {
                dispatch(addFavourite({
                    shopId: props.appointment?.shopId,
                    type: 'item',
                    itemId: props.appointment?.id
                }))
            }
        }
    }
    const obj = props.appointment?.names?.length! > 1 ? props.appointment?.names.find(n => n.language === translationLanguage) : props.appointment?.names[0]
    return <Card
        img={props.appointment?.image}
        offerLabel={props.appointment?.offerLabel || ''}
        favourites
        isFavourite={isFavourite}
        name={obj?.name}
        includes={""}
        appointmentDate={appointmentDate}
        // locationPrefix = {props.appointment?.location}
        buttonText={t('View Appointment')}
        onClick={onAddToCartClickHandler}
        onFavouritesClick={favouritesClickHandler}
        startsFrom={false}
        from={false}
    />
}

export default ShopAppointmentCard