import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ShopContext, ShopContextType } from '../../context/ShopProvider'
import { UserContext, UserContextType } from '../../context/UserProvider'
import { TranslationLanguage, useT } from '../../i18n'
import { addFavourite, removeFavourite } from '../../redux/actions'
import { StoreState } from '../../redux/reducers'
import { Favourite } from '../../redux/reducers/favourite.reducer'
import { Food } from '../../redux/reducers/food.reducer'
import { Shop } from '../../redux/reducers/shop.reducer'
import { AppDispatch } from '../../redux/store'
import { getMinOfferPrice, getMinPrice } from '../../utils'
import Card from './Card'

export interface ShopProductCategorySectionCardProps {
    food?: Food
}

export const ResponsiveShopProductCard = (props: ShopProductCategorySectionCardProps) => {
    return <div className='col-sm-6 col-lg-4 col-xxl-3 mb-3'>
        <div className='d-flex align-items-center justify-content-center'>
            <ShopProductCard {...props} />
        </div>
    </div>
}

const ShopProductCard = (props: ShopProductCategorySectionCardProps) => {
    const shopContext = React.useContext<ShopContextType | null>(ShopContext)
    const userContext = React.useContext<UserContextType | null>(UserContext)
    const shop = useSelector<StoreState, Shop | null>(state => state.shops.find(s => s.id === shopContext?.shopId) || null)
    const translationLanguage = useSelector<StoreState, TranslationLanguage>(state => state.translationLanguage)
    const navigate = useNavigate()

    const favourites = useSelector<StoreState, Favourite[]>(state => state.favourites.filter(fav => fav.type === 'item' && fav.shopId === props.food?.shopId))
    const dispatch = useDispatch<AppDispatch>()
    const t = useT()

    const includes = props.food?.toppings.map(topping =>
        topping.names?.length! > 1 ?
            topping.names.find(n => n.language === translationLanguage)?.name :
            topping.names[0].name).join(', ')
    const price = getMinPrice(props.food)
    const offerPrice = getMinOfferPrice(props.food)

    const isFavourite = favourites.some(fav => fav.itemId && fav.itemId === props.food?.id)

    const onAddToCartClickHandler = () => {
        if (props.food) {
            // userContext?.openCartModal?.(props.food)
            navigate(`/product/${props.food?.shopId}/${props.food?.id}`)
        }
    }

    const favouritesClickHandler = () => {
        if (props.food) {
            if (isFavourite) {
                dispatch(removeFavourite({
                    shopId: props.food?.shopId,
                    type: 'item',
                    itemId: props.food?.id
                }))
            } else {
                dispatch(addFavourite({
                    shopId: props.food?.shopId,
                    type: 'item',
                    itemId: props.food?.id
                }))
            }
        }
    }
    const obj = props.food?.names?.length! > 1 ? props.food?.names.find(n => n.language === translationLanguage) : props.food?.names[0]
    return <Card
        img={props.food?.image}
        offerLabel={props.food?.offerLabel || ''}
        favourites
        isFavourite={isFavourite}
        name={obj?.name}
        includes={includes}
        price={price}
        offerPrice={offerPrice}
        buttonText={t('Order Now')}
        onClick={onAddToCartClickHandler}
        onFavouritesClick={favouritesClickHandler}
        startsFrom={props.food?.type === 'detail'}
        from={true}
        currency={shop?.currency}
    />
}

export default ShopProductCard