import { nanoid } from '@reduxjs/toolkit'
import { ContentState, EditorState, convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import React from 'react'
import { Editor } from 'react-draft-wysiwyg'
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { useDispatch, useSelector } from 'react-redux'
import { api } from '../../api'
import { DEFAULT_LANGUAGES } from '../../config'
import { AdminContext, AdminContextType } from '../../context/AdminProvider'
import { TranslationLanguage, useT } from '../../i18n'
import { AddAppointment, addAppointment, addLanguage, addSize, updateAppointment } from '../../redux/actions'
import { StoreState } from '../../redux/reducers'
import { Appointment, AppointmentType, Slot } from '../../redux/reducers/appointment.reducer'
import { AuthAdmin } from '../../redux/reducers/authAdmin.reducer'
import { Category } from '../../redux/reducers/category.reducer'
import { AppDispatch } from '../../redux/store'
import errorStyle from '../../styles/components/error-text.module.scss'
import formStyle from '../../styles/components/form.module.scss'
import { times, toNumber, toString } from '../../utils'
import { ToastType, useToast } from '../ToastProvider'
import ModalWithHeader from '../modals/ModalWithHeader'
import SelectableTileSection from '../sections/SelectableTileSection'
import SlotCard from '../sections/SlotCard'
import Camera from '../svgs/Camera'
import Edit from '../svgs/Edit'
import Button from '../utils/Button'
import ErrorText from '../utils/ErrorText'
import FormInput from '../utils/FormInput'
import Radio from '../utils/Radio'
import ResponsiveFormInput from '../utils/ResponsiveFormInput'
import SmallImage from '../utils/SmallImage'
import TimeInput from '../utils/TimeInput'
import AppointmentPriceForm from './AppointmentPriceForm'
import InstantInputForm from './InstantInputForm'

interface Name {
    language: string
    name: string
    errorText: string
    id: string
}

interface Description {
    language: string
    description: string
    editContent: EditorState
    errorText: string
    id: string
}

interface Price {
    price: string
    priceError: string
    offerPrice: string
    offerPriceError: string
    size: string
    id: string
}

interface AppointmentAdditionImage {
    image: string
    id: string
}

interface AppointmentFormProps {
    onClose?: () => void
    id?: string | null
}

const AppointmentForm = (props: AppointmentFormProps) => {
    const dispatch = useDispatch<AppDispatch>()
    const toast = useToast()
    const t = useT()

    const authAdmin = useSelector<StoreState, AuthAdmin | null>(state => state.authAdmin)
    const adminContext = React.useContext<AdminContextType | null>(AdminContext)

    const languages = useSelector<StoreState, string[]>(state => state.languages)
    const categories = useSelector<StoreState, Category[]>(state => state.categories)
    const sizes = useSelector<StoreState, string[]>(state => state.sizes)
    const translationLanguage = useSelector<StoreState, TranslationLanguage>(state => state.translationLanguage)
    const Appointment = useSelector<StoreState, Appointment | null>(state => props.id ? state.appointment.find(f => f.id === props.id) || null : null)
    const [onCamera, setOnCamera] = React.useState<boolean>(false)
    const [selectedLanguages, setSelectedLanguages] = React.useState<string[]>(DEFAULT_LANGUAGES)
    const [selectedLanguagesError, setSelectedLanguagesError] = React.useState<string>('')
    const [additionalImages, setAdditionalImages] = React.useState<AppointmentAdditionImage[]>([])
    const [additionalImagesError, setAdditionalImagesError] = React.useState<string>('')
    const [selectedCategories, setSelectedCategories] = React.useState<string[]>([])
    const [selectedSizesError, setSelectedSizesError] = React.useState<string>('')
    const [selectedCategoriesError, setSelectedCategoriesError] = React.useState<string>('')
    const [descriptions, setDescriptions] = React.useState<Description[]>([])
    const [image, setImage] = React.useState<any>(authAdmin?.shop.theme.bannerImage ? authAdmin?.shop.theme.bannerImage : '')
    const [imageError, setImageError] = React.useState<string>('')
    const [names, setNames] = React.useState<Name[]>([])
    const [offerLabel, setOfferLabel] = React.useState<string>('')
    const [offerLabelError, setOfferLabelError] = React.useState<string>('')
    const [prices, setPrices] = React.useState<Price[]>([])
    const [tax, setTax] = React.useState<string>('')
    const [taxError, setTaxError] = React.useState<string>('')
    const [stock, setStock] = React.useState<string>('')
    const [stockError, setStockError] = React.useState<string>('')
    const [fromTime, setFromTime] = React.useState<{ time: string, timePeriod: string }>({ time: '', timePeriod: 'AM' })
    const [fromTimeError, setFromTimeError] = React.useState<string>('')
    const [toTime, setToTime] = React.useState<{ time: string, timePeriod: string }>({ time: '', timePeriod: 'AM' })

    const [toTimeError, setToTimeError] = React.useState<string>('')
    const [capacity, setCapacity] = React.useState<string>('')
    const [date, setdate] = React.useState<string>('')
    const [dateError, setdateError] = React.useState(new Date())
    const [type, setType] = React.useState<AppointmentType>('detail')
    const [typeError, setTypeError] = React.useState<string>('')
    const [price, setPrice] = React.useState<string>('')
    const [priceError, setPriceError] = React.useState<string>('')
    const [offerPrice, setOfferPrice] = React.useState<string>('')
    const [offerPriceError, setOfferPriceError] = React.useState<string>('')
    const [slots, setSlots] = React.useState<Slot[]>([])
    const [editSlot, setEditSlot] = React.useState<number | null>(null);

    const [loading, setLoading] = React.useState<boolean>(false)

    const languageClickHandler = (lng?: string) => {
        if (lng && lng !== '') {
            setSelectedLanguages(prev => {
                return prev.some(p => p === lng)
                    ? prev.length > 1
                        ? prev.filter(p => p !== lng)
                        : prev
                    : [
                        ...prev,
                        lng
                    ]
            })

            setSelectedLanguagesError('')
        }
    }

    const addLanguageClickHandler = (language: string) => {
        dispatch(addLanguage(language))
    }

    const nameChangeHandler = (id: string | null, value: string) => {
        setNames(prev => {
            const names = [...prev]
            const index = names.findIndex(p => p.id === id)

            if (index > -1) {
                names[index] = {
                    ...names[index],
                    name: value,
                    errorText: ''
                }
            }

            return names
        })
    }

    const descriptionChangeHandler = (lang: string | null, value: string, state: EditorState) => {
        setDescriptions(prev => {
            const descriptions = [...prev]
            const index = descriptions.findIndex(p => p.language === lang)
            if (index > -1) {
                descriptions[index] = {
                    ...descriptions[index],
                    description: value,
                    editContent: state,
                    errorText: ''
                }
            }

            return descriptions
        })
    }

    const priceChangeHandler = (value: string, id?: string) => {
        if (id) {
            setPrices(prev => {
                const prices = [...prev]
                const index = prices.findIndex(p => p.id === id)

                if (index > -1) {
                    prices[index] = {
                        ...prices[index],
                        price: value,
                        priceError: ''
                    }
                }

                return prices
            })
        } else {
            setPrice(value)
            setPriceError('')
        }
    }

    const offerPriceChangeHandler = (value: string, id?: string) => {
        if (id) {
            setPrices(prev => {
                const prices = [...prev]
                const index = prices.findIndex(p => p.id === id)

                if (index > -1) {
                    prices[index] = {
                        ...prices[index],
                        offerPrice: value,
                        offerPriceError: ''
                    }
                }

                return prices
            })
        } else {
            setOfferPrice(value)
            setOfferPriceError('')
        }
    }

    const addNewSizeClickHandler = (size: string) => {
        dispatch(addSize(size))
    }

    const addMoreImageClickHandler = async (image: string) => {
        if (image !== '') {
            setAdditionalImages(prev => {
                return [
                    ...prev,
                    {
                        image: image,
                        id: nanoid()
                    }
                ]
            })
            setAdditionalImagesError('')
        }
    }

    const imageChangeHandler: React.ChangeEventHandler<HTMLInputElement | HTMLFormElement> = async (e) => {
        let response: any
        if (onCamera) {
            const formData: any = new FormData()
            formData.append("file", e.target.files[0]);
            response = await api.post<Response>(`upload-img/Appointment`, formData);
        }
        onCamera ? setImage(response.data.data) : setImage(e.target.value)
        setImageError('')
    }

    const removeAdditionalImage = (id: string) => {
        setAdditionalImages(prev => {
            return prev.filter(img => img.id !== id)
        })
    }

    const categoryClickHandler = (id?: string) => {
        if (id && id !== '') {
            setSelectedCategories(prev => {
                return prev.some(p => p === id)
                    ? prev.filter(p => p !== id)
                    : [
                        ...prev,
                        id
                    ]
            })
            setSelectedCategoriesError('')
        }
    }

    const selectSizeHandler = (size?: string) => {
        if (size && size !== '') {
            adminContext?.setSelectedSizes?.(prev => {
                return prev.some(p => p === size)
                    ? prev.filter(p => p !== size)
                    : [
                        ...prev,
                        size
                    ]
            })
            setSelectedSizesError('')
        }
    }

    const offerLabelChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setOfferLabel(e.target.value)
        setOfferLabelError('')
    }

    const taxChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setTax(e.target.value)
        setTaxError('')
    }

    const stockChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setStock(e.target.value)
        setStockError('')
    }

    const fromTimeChangeHandler = (value: string) => {
        setFromTime({ ...fromTime, time: value })
        setFromTimeError('')
    }
    const toTimeChangeHandler = (value: string) => {
        setToTime({ ...toTime, time: value })
        setToTimeError('')
    }

    const dateChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setdate(e.target.value)
    }

    const capacityChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setCapacity(e.target.value)
    }

    const typeClickHandler = (value: AppointmentType) => {
        setType(value)
        setTypeError('')
    }

    const submitHandler: React.MouseEventHandler<HTMLButtonElement> = (e) => {
        e.preventDefault()

        let error = false

        if (selectedLanguages.length <= 0) {
            error = true
            setSelectedLanguagesError(t('Language required'))
        }

        names.forEach((name, i) => {
            if (toString(name.name) === '') {
                error = true
                setNames(prev => {
                    const names = [...prev]
                    names[i].errorText = name.language.charAt(0).toUpperCase() + name.language.substring(1) + t('Name required')
                    return names
                })
            }
        })

        descriptions.forEach((description, i) => {
            if (toString(description.description) === '') {
                error = true
                setDescriptions(prev => {
                    const descriptions = [...prev]
                    descriptions[i].errorText = description.language.charAt(0).toUpperCase() + description.language.substring(1) + t('Description required')
                    return descriptions
                })
            }
        })

        if (selectedCategories.length <= 0) {
            error = true
            setSelectedCategoriesError(t('Categories required'))
        }

        if (toString(type) === '') {
            error = true
            setTypeError(t('Type required'))
        } else if ((['detail', 'simple'] as AppointmentType[]).indexOf(type) === -1) {
            error = true
            setTypeError(t('Invalid type given'))
        }

        if (toString(tax) !== '' && toNumber(tax) < 0) {
            error = true
            setTaxError(t('Invalid tax given'))
        }

        if (toString(stock) !== '' && toNumber(stock) < 0) {
            error = true
            setStockError(t('Invalid stock given'))
        }
        if (toString(fromTime) !== '' && toNumber(fromTime) < 0) {
            error = true
            setFromTimeError(t('From Time required'))
        }
        if (toString(toTime) !== '' && toNumber(toTime) < 0) {
            error = true
            setToTimeError(t('Invalid To Time given'))
        }

        if (type === 'detail') {
            if (prices.length <= 0) {
                error = true
                setSelectedSizesError(t('Prices required'))
            } else {
                prices.forEach((price, i) => {
                    if (toString(price.size) === '') {
                        error = true
                        setPrices(prev => {
                            const prices = [...prev]
                            prices[i].priceError = (t('Size required'))
                            return prices
                        })
                    }

                    if (toString(price.price) === '') {
                        error = true
                        setPrices(prev => {
                            const prices = [...prev]
                            prices[i].priceError = (t('Price required'))
                            return prices
                        })
                    } else if (toNumber(price.price) < 0) {
                        error = true
                        setPrices(prev => {
                            const prices = [...prev]
                            prices[i].priceError = t('Invalid price given')
                            return prices
                        })
                    }

                    if (toString(price.offerPrice) !== '' && toNumber(price.offerPrice) < 0) {
                        error = true
                        setPrices(prev => {
                            const prices = [...prev]
                            prices[i].offerPriceError = t('Invalid offer price given')
                            return prices
                        })
                    }
                })
            }

        }

        if (type === 'simple') {
            if (toString(price) === '') {
                error = true
                setPriceError(t('Price required'))
            } else if (toNumber(price) < 0) {
                error = true
                setPriceError(t('Invalid price given'))
            }

            if (toString(offerPrice) !== '' && toNumber(offerPrice) < 0) {
                error = true
                setOfferPriceError(t('Invalid offer price given'))
            }
        }

        if (!error) {
            const AppointmentData: AddAppointment = {
                additionalImages: additionalImages.map(img => toString(img.image)),
                categories: selectedCategories.map(toString),
                descriptions: descriptions.map(d => {
                    return {
                        description: toString(d.description),
                        language: toString(d.language)
                    }
                }),
                image: toString(image),
                names: names.map(n => {
                    return {
                        language: toString(n.language),
                        name: toString(n.name)
                    }
                }),
                offerLabel: toString(offerLabel) !== '' ? toString(offerLabel) : null,
                price: type === 'simple' ? toNumber(price) : null,
                offerPrice: type === 'simple' && toNumber(offerPrice) > 0 ? toNumber(offerPrice) : null,
                tax: toNumber(tax) || null,
                type: type,
                stock: toNumber(stock) || null,
                // fromTime: '',
                // toTime: '',
                capacity: toNumber(capacity),
                date: toString(date),
                slots: slots,
                prices: type === 'detail'
                    ? prices.map(p => {
                        return {
                            price: toNumber(p.price),
                            size: toString(p.size),
                            offerPrice: toString(p.offerPrice) !== '' && toNumber(p.offerPrice) > 0 ? toNumber(p.offerPrice) : null
                        }
                    }) : [],
            }

            if (props.id && Appointment) {
                setLoading(true)
                dispatch(updateAppointment(AppointmentData, Appointment.id)).then(() => {
                    toast(t('Appointment updated'))
                    props.onClose?.()
                }).catch(text => {
                    toast(text, ToastType.ERROR)
                }).finally(() => {
                    setLoading(false)
                })
            } else {
                setLoading(true)
                dispatch(addAppointment(AppointmentData)).then(() => {
                    toast(t('Appointment added'))
                    props.onClose?.()
                }).catch(text => {
                    toast(text, ToastType.ERROR)
                }).finally(() => {
                    setLoading(false)
                })
            }
        }
    }

    React.useEffect(() => {
        setPrices((prev): any => {
            const newState = [...prev]
            return adminContext?.selectedSizes?.map<Price>(size => {
                const index = newState.findIndex(p => p.size === size)
                return index > -1
                    ? newState[index]
                    : {
                        offerPrice: '',
                        offerPriceError: '',
                        price: '',
                        priceError: '',
                        size: size,
                        id: nanoid()
                    }
            })
        })
    }, [adminContext?.selectedSizes])

    React.useEffect(() => {
        setNames(prev => {
            return selectedLanguages.map(lng => {
                return prev.find(p => p.language === lng) || {
                    errorText: '',
                    id: nanoid(),
                    language: lng,
                    name: ''
                }
            })
        })

        setDescriptions(prev => {
            return selectedLanguages.map(lng => {
                return prev.find(p => p.language === lng) || {
                    errorText: '',
                    id: nanoid(),
                    language: lng,
                    editContent: EditorState.createEmpty(),
                    description: ''
                }
            })
        })
    }, [selectedLanguages])

    React.useEffect(() => {
        if (props.id && Appointment) {
            setAdditionalImages(Appointment.additionalImages.map(i => ({ id: nanoid(), image: i })))
            setSelectedCategories(Appointment.categories.map(c => c.id))
            setDescriptions(() => {
                const descriptions: Description[] = []

                Appointment.descriptions.forEach(n => {
                    let contenthtmlToDraft = htmlToDraft(n.description);
                    const contentState = ContentState.createFromBlockArray(contenthtmlToDraft.contentBlocks);
                    if (!descriptions.some(p => p.language === n.language)) {
                        descriptions.push({
                            errorText: '',
                            id: n.id,
                            language: n.language,
                            editContent: EditorState.createWithContent(contentState),
                            description: n.description
                        })
                    }
                })

                return descriptions
            })
            setImage(Appointment.image)
            setNames(() => {
                const names: Name[] = []

                Appointment.names.forEach(n => {
                    if (!names.some(p => p.language === n.language)) {
                        names.push({
                            errorText: '',
                            id: n.id,
                            language: n.language,
                            name: n.name
                        })
                    }
                })

                return names
            })
            setOfferLabel(Appointment.offerLabel || '')
            setTax(Appointment.tax?.toString() || '')
            setStock(Appointment.stock?.toString() || '')
            // setFromTime(Appointment.fromTime || {time:'',timePeriod:'AM'})
            // setToTime(Appointment.toTime || {time:'',timePeriod:'AM'})
            setdate(Appointment.date?.toString() || '')
            setCapacity(Appointment.capacity?.toString() || '')
            // setSelectedToppings(Appointment.toppings.map(t => t.id))
            setType(Appointment.type)
            setSlots(Appointment.slots)
            setPrices(() => {
                const prices: Price[] = []
                Appointment.prices.forEach(n => {
                    if (!prices.some(p => p.size === n.size)) {
                        prices.push({
                            id: n.id,
                            size: n.size,
                            offerPrice: n.offerPrice?.toString() || '',
                            offerPriceError: '',
                            price: n.price.toString(),
                            priceError: ''
                        })
                    }
                })

                return prices
            })

            setPrice(Appointment.price?.toString() || '')
            setOfferPrice(Appointment.offerPrice?.toString() || '')
            setSelectedLanguages(() => {
                const lngs: string[] = []
                Appointment.names.forEach(n => {
                    if (lngs.indexOf(n.language) === -1) {
                        lngs.push(n.language)
                    }
                })
                return lngs
            })
            adminContext?.setSelectedSizes?.(() => {
                const sizes: string[] = []
                Appointment.prices.forEach(n => {
                    if (sizes.indexOf(n.size) === -1) {
                        sizes.push(n.size)
                    }
                })
                return sizes
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Appointment])

    const editorChangeHandler = (editorState: EditorState, lang: string) => {
        const contentState = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        const description = contentState;
        descriptionChangeHandler(lang, description, editorState)
    }

    const timePeriodHandler = (value: string, type: string) => {
        if (type === "from") setFromTime({ ...fromTime, timePeriod: value })
        else setToTime({ ...toTime, timePeriod: value })

    }

    const slotClickHandler = () => {
        if (fromTime.time !== '' && toTime.time !== '') {
            const data = { fromTime: fromTime, toTime: toTime, capacity: capacity }

            if (editSlot === null)
                setSlots([...slots, data]);
            else {
                let existingSlots = slots;
                existingSlots[editSlot] = { ...data };
                setSlots(existingSlots);
                setEditSlot(null);
            }
            setCapacity('');
            setFromTime({ time: '', timePeriod: 'AM' })
            setToTime({ time: '', timePeriod: 'AM' })
        }
    }

    const editClickHandler = (index: number) => {
        const fromTime = slots[index]['fromTime']['time'];
        const fromTimePeriod = slots[index]['fromTime']['timePeriod'];
        const toTime = slots[index]['toTime']['time'];
        const toTimePeriod = slots[index]['toTime']['timePeriod'];
        const capacity = slots[index]['capacity'];
        setFromTime({ time: fromTime, timePeriod: fromTimePeriod });
        setToTime({ time: toTime, timePeriod: toTimePeriod });
        setCapacity(capacity);
        setEditSlot(index);
    }

    const deleteClickHandler = (index: number) => {
        const deletedSlots = slots.filter((s, i) => i !== index);
        setSlots(deletedSlots);
    }

    return <ModalWithHeader
        buttonText={t('Save')}
        headerText={props.id ? t('Edit Appointment Booking') : t('Add Appointment Booking')}
        loading={loading}
        onClose={props.onClose}
        onSave={submitHandler}
    >
        <div className='row mb-4'>
            <div className='col'>
                <SelectableTileSection
                    list={languages}
                    getSelectableTileProps={language => ({
                        key: language,
                        active: selectedLanguages.some(slng => slng === language),
                        onClick: languageClickHandler,
                        value: language,
                        label: language
                    })}
                    title={t('Language')}
                    instantInput={{
                        onAdd: addLanguageClickHandler,
                        label: t('Language')
                    }}
                    errorText={selectedLanguagesError}
                />
            </div>
        </div>

        <div className='row'>
            <div className='col'>
                <div className='row'>
                    {selectedLanguages.map(lng => {
                        const name = names.find(n => n.language === lng)

                        return <ResponsiveFormInput
                            responsiveClassName='col-md-6'
                            containerClass='mb-4'
                            type='text'
                            key={lng}
                            label={lng.charAt(0).toUpperCase() + lng.slice(1) + t('Name')}
                            value={name?.name || ''}
                            onChange={e => nameChangeHandler(name?.id || null, e.target.value)}
                            errorText={name?.errorText}
                        />
                    })}
                </div>
            </div>
        </div>
        <div className='row'>
            <div className='col'>
                <div className='row'>
                    {selectedLanguages.map(lng => {
                        const description = descriptions.find(d => d.language === lng)
                        let error = description?.errorText === '' ? false : true

                        return <div className='col-md-6 mb-4'>
                            {lng.charAt(0).toUpperCase() + lng.slice(1) + t(' description')}
                            <Editor
                                key={lng}
                                editorState={description?.editContent}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName border rounded-5 p-3"
                                editorClassName="editorClassName"
                                onEditorStateChange={(e) => editorChangeHandler(e, lng)}
                            />
                            <span className={errorStyle.errorText}>{description?.errorText}</span>
                        </div>
                    })}
                </div>
            </div>
        </div>

        {/* <div className='row'>
            <div className='col'>
                <div className='row'>
                    {selectedLanguages.map(lng => {
                        const description = descriptions.find(d => d.language === lng)

                        return <ResponsiveTextArea
                            key={lng}
                            label={lng.charAt(0).toUpperCase() + lng.slice(1) + ' description'}
                            value={description?.description || ''}
                            onChange={e => descriptionChangeHandler(description?.id || null, e.target.value)}
                            responsiveClassName='col-md-6'
                            containerClass='mb-4'
                            errorText={description?.errorText}
                        />
                    })}
                </div>
            </div>
        </div> */}

        <div className='row mb-4'>
            <div className='col'>
                <FormInput
                    type={onCamera ? 'file' : 'text'}
                    label={t('Image')}
                    placeholder={t('Image')}
                    value={onCamera ? image.filename : image}
                    onChange={imageChangeHandler}
                    containerClass='mb-4'
                    rightRenderer={!onCamera ? <Camera strokeWidth={2} /> : <Edit />}
                    onRightRendererClick={() => setOnCamera(onCamera === true ? false : true)}
                    errorText={imageError}
                />
                <div className='position-relative'>
                    <div className='hstack gap-2 flex-wrap'>
                        {image !== '' && <SmallImage
                            image={image}
                        />}
                        {additionalImages.length > 0 && additionalImages.map(img => {
                            return <SmallImage
                                key={img.id}
                                image={img.image}
                                onClose={() => removeAdditionalImage(img.id)}
                            />
                        })}
                        <InstantInputForm
                            onAdd={addMoreImageClickHandler}
                            type="Appointment"
                            label={t('More image')}
                        />
                    </div>
                    <ErrorText errorText={additionalImagesError} />
                </div>
            </div>
        </div>

        <div className='row mb-4'>
            <div className='col'>
                <SelectableTileSection
                    list={categories}
                    addButtonText={t('Add Category')}
                    errorText={selectedCategoriesError}
                    getSelectableTileProps={category => ({
                        key: category.id,
                        value: category.id,
                        label: category?.names?.length! > 1 ?
                            category?.names.find(n => n.language === translationLanguage)?.name :
                            category?.names[0].name,
                        active: selectedCategories.some(ss => ss === category.id),
                        onClick: categoryClickHandler,
                        onEdit: () => adminContext?.onEditCategory?.(category.id),
                        error: selectedCategoriesError !== ''
                    })}
                    onAdd={adminContext?.onAddCategory}
                    title={t('Categories')}
                />
            </div>
        </div>

        <div className='row'>
            <div className='col'>
                <FormInput
                    type='text'
                    label={t('Offer label')}
                    placeholder={t('Offer label')}
                    value={offerLabel}
                    errorText={offerLabelError}
                    onChange={offerLabelChangeHandler}
                    containerClass='mb-4'
                />
            </div>
        </div>

        <div className='row mb-4'>
            <div className='col'>
                <div className='position-relative'>
                    <div className={formStyle.sectionHeader}>{t("Type")}</div>
                    <div className='hstack gap-3'>
                        <Radio checked={type === 'detail'} onClick={() => typeClickHandler('detail')}>{t("Detail")}</Radio>
                        <Radio checked={type === 'simple'} onClick={() => typeClickHandler('simple')}>{t("Simple")}</Radio>
                    </div>
                    <ErrorText errorText={typeError} />
                </div>
            </div>
        </div>

        <div className='row mb-4'>
            <div className='col'>
                {type === 'detail' && <SelectableTileSection
                    list={sizes}
                    addButtonText={t('Add Size')}
                    errorText={selectedSizesError}
                    getSelectableTileProps={size => ({
                        key: size,
                        value: size,
                        label: size,
                        active: adminContext?.selectedSizes?.some(s => s === size),
                        onClick: selectSizeHandler,
                        error: selectedSizesError !== ''
                    })}
                    instantInput={{
                        onAdd: addNewSizeClickHandler,
                        label: t('Size')
                    }}
                    title={t('Prices')}
                />}
                <div className='row'>
                    {type === 'detail' && adminContext?.selectedSizes?.map(size => {
                        const price = prices.find(p => p.size === size)

                        return <AppointmentPriceForm
                            responsiveClassName='col-sm-6 col-md-4 my-2'
                            label={price?.size}
                            price={price?.price || ''}
                            priceError={price?.priceError}
                            offerPrice={price?.offerPrice || ''}
                            offerPriceError={price?.offerPriceError}
                            onOfferPriceChange={e => offerPriceChangeHandler(e.target.value, price?.id)}
                            onPriceChange={e => priceChangeHandler(e.target.value, price?.id)}
                            key={size}
                            showOfferPrice
                        />
                    })}

                    {type === 'simple' && <AppointmentPriceForm
                        responsiveClassName='col-4'
                        price={price}
                        priceError={priceError}
                        offerPrice={offerPrice}
                        offerPriceError={offerPriceError}
                        onOfferPriceChange={e => offerPriceChangeHandler(e.target.value)}
                        onPriceChange={e => priceChangeHandler(e.target.value)}
                        showOfferPrice
                    />}
                </div>
            </div>
        </div>

        <div className='row'>
            <SlotCard
                title={t('List of Slots')}
                buttonText={''}
                list={slots}
                onEdit={(i: number) => editClickHandler(i)}
                onDelete={(i: number) => deleteClickHandler(i)}
            />
            <div className='col'>
                <div className='row mb-4'>
                    <div className='col'>
                        <TimeInput
                            options={times}
                            value={fromTime?.time}
                            onChange={fromTimeChangeHandler}
                            timePeriod={fromTime?.timePeriod}
                            timePeriodHandler={(value: string) => timePeriodHandler(value, 'from')}
                            label={t('From Time')}
                        />
                    </div>
                    <div className='col'>
                        <TimeInput
                            options={times}
                            value={toTime?.time}
                            onChange={toTimeChangeHandler}
                            timePeriod={toTime?.timePeriod}
                            timePeriodHandler={(value: string) => timePeriodHandler(value, 'to')}
                            label={t('To Time')}
                        />
                    </div>
                    <div className='col'>
                        <FormInput
                            type='number'
                            label={t('Capacity Count')}
                            value={capacity}
                            onChange={capacityChangeHandler}
                            containerClass='mb-4'
                        />
                    </div>
                    <div className='col mt-4'>
                        <Button onClick={slotClickHandler}>{editSlot !== null ? t('Edit') : t('Add +')}</Button>
                    </div>
                </div>
            </div>
        </div>
        <div className='row'>
            <div className='col-12'>
                <FormInput
                    type='number'
                    label={t('Tax')}
                    placeholder={t('Tax')}
                    value={tax}
                    onChange={taxChangeHandler}
                    errorText={taxError}
                    containerClass='mb-4'
                />
            </div>
        </div>

        {/* <div className='row'>
            <label className={style.label}>Time</label>
            <div className='col-4'>
                <FormInput
                    type='time'
                    label={"From"}
                    placeholder={"From"}
                    value={fromTime}
                    onChange={fromTimeChangeHandler}
                    errorText={fromTimeError}
                    containerClass='mb-4'
                />
            </div>
            <div className='col-4'>
                <FormInput
                    type='time'
                    label={"To"}
                    placeholder={"To"}
                    value={toTime}
                    onChange={toTimeChangeHandler}
                    errorText={toTimeError}
                    containerClass='mb-4'
                />
            </div>
        </div> */}
        {/* <div className='row'>
            <div className='col-12'>
                <label className={style.label}>Date</label>
                <div>
                    <DatePicker value={dateError} onChange={dateChangeHandler} />
                </div>
            </div>
        </div> */}

    </ModalWithHeader>
}

export default AppointmentForm