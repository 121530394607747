import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { api } from '../api'
import assets from '../assets'
import { TranslationLanguage } from '../i18n'
import { fetchShops } from '../redux/actions'
import { StoreState } from '../redux/reducers'
import { Event } from '../redux/reducers/event.reducer'
import { Food } from '../redux/reducers/food.reducer'
import { Shop } from '../redux/reducers/shop.reducer'
import { AppDispatch } from '../redux/store'
import style from '../styles/pages/productview.module.scss'
import { getEventOfferPrice, getEventPrice, getMinOfferPrice, getMinPrice, toCurrencyFormat } from '../utils'


interface Price {
    worsePrice: string
    bestPrice: string
}

interface PreviewTheme {
    animation: string
    fontStyle: string
    backgroundColor: string
    color: string
    priceColor: Price
}

interface ItemType {
    itemName: string
    itemImage: string
    previewStatus: string
    itemPrice: string
    itemOfferPrice: string
}

interface ProductViewProps {
    shopId?: string
    onClose?: () => void
    previewTheme?: PreviewTheme
    previewHandler?: (type: string) => void
}

type ProductViewParams = {
    shopId?: string
}
const ProductView = (props: ProductViewProps) => {

    const { shopId } = useParams<ProductViewParams>()
    const id = props.shopId || shopId || ''
    const dispatch = useDispatch<AppDispatch>()
    const shop = useSelector<StoreState, Shop[]>(state => state.shops).find(f => f.id?.toString() === id)
    const [items, setItems] = useState<ItemType[]>([]);
    const translationLanguage = useSelector<StoreState, TranslationLanguage>(state => state.translationLanguage)
    const [previewTheme, setPreviewTheme] = React.useState<PreviewTheme>({
        animation: 'rightLeftAnimations',
        fontStyle: 'EB Garamond',
        backgroundColor: '#dfdea9',
        color: '#000000',
        priceColor: { worsePrice: '#ff0040', bestPrice: '#000000' }
    })

    const [itemCount, setItemCount] = React.useState<number>(0)

    useEffect(() => {
        setPreviewTheme((prev: PreviewTheme) => ({
            ...prev,
            animation: props.previewTheme?.animation ? props.previewTheme?.animation : 'rightLeftAnimations',
            fontStyle: props.previewTheme?.fontStyle ? props.previewTheme?.fontStyle : 'EB Garamond',
            backgroundColor: props.previewTheme?.backgroundColor ? props.previewTheme?.backgroundColor : '#dfdea9',
            color: props.previewTheme?.color ? props.previewTheme?.color : '#000000',
            priceColor: {
                worsePrice: props.previewTheme?.priceColor.worsePrice ? props.previewTheme?.priceColor.worsePrice : '#ff0040',
                bestPrice: props.previewTheme?.priceColor.bestPrice ? props.previewTheme?.priceColor.bestPrice : '#000000'
            }
        }))
    }, [props.previewTheme])

    useEffect(() => {
        setTimeout(() => {
            setItemCount(itemCount < items.length - 1 ? itemCount + 1 : 0)
        }, 5000);
    }, [itemCount, items])

    const foodLists = React.useMemo(() => {
        let foodItems: Food[] = [];
        api.get(`foods/${shopId ? shopId : props.shopId}`).then((res) => {
            foodItems.push(...res.data.data)
        });
        return foodItems
    }, [shopId, props.shopId])

    const eventLists = React.useMemo(() => {
        let eventItems: Event[] = [];
        api.get(`events/${shopId ? shopId : props.shopId}`).then((res) => {
            eventItems.push(...res.data.data)
        });
        return eventItems
    }, [shopId, props.shopId])

    useEffect(() => {
        setPreviewTheme((prev: PreviewTheme) => ({
            ...prev,
            animation: shop?.previewTheme?.animation ? shop?.previewTheme?.animation : 'rightLeftAnimations',
            fontStyle: shop?.previewTheme?.fontStyle ? shop?.previewTheme?.fontStyle : 'EB Garamond',
            backgroundColor: shop?.previewTheme?.backgroundColor ? shop?.previewTheme?.backgroundColor : '#dfdea9',
            color: shop?.previewTheme?.color ? shop?.previewTheme?.color : '#000000',
            priceColor: {
                worsePrice: shop?.previewTheme?.priceColor.worsePrice ? shop?.previewTheme?.priceColor.worsePrice : '#ff0040',
                bestPrice: shop?.previewTheme?.priceColor.bestPrice ? shop?.previewTheme?.priceColor.bestPrice : '#000000'
            }
        }))
    }, [shop])

    useEffect(() => {
        if (shop && foodLists && foodLists.length !== 0) {
            let arr = foodLists.filter(f => f.shopId?.toString() === shop?.id?.toString() && f.previewStatus === "active")

            let itemArray: any = arr?.map(a => {

                const price = getMinPrice(a)
                const offerPrice = getMinOfferPrice(a)

                return {
                    itemName: a?.names?.length! > 1 ?
                        a?.names.find(n => n.language === translationLanguage)?.name :
                        a?.names[0].name,
                    itemImage: a.image,
                    previewStatus: a.previewStatus,
                    itemOfferPrice: offerPrice?.toString() !== "" && offerPrice! > 0 && toCurrencyFormat(offerPrice) || '',
                    itemPrice: price?.toString() !== "" && price! > 0 && toCurrencyFormat(price) || ''
                }
            })
            setItems(itemArray)

        } else if (shop && eventLists && eventLists.length !== 0) {
            let arr = eventLists.filter(f => f.shopId?.toString() === shop?.id?.toString() && f.previewStatus === "active")

            let itemArray: any = arr?.map(a => {
                const price = getEventPrice(a)
                const offerPrice = getEventOfferPrice(a)

                return {
                    itemName: a?.names?.length! > 1 ?
                        a?.names.find(n => n.language === translationLanguage)?.name :
                        a?.names[0].name,
                    itemImage: a.image,
                    itemOfferPrice: offerPrice?.toString() !== "" && offerPrice! > 0 && toCurrencyFormat(offerPrice) || '',
                    itemPrice: price?.toString() !== "" && price! > 0 && toCurrencyFormat(price) || ''
                }
            })
            setItems(itemArray)
        }
    }, [shop, foodLists, eventLists])

    useEffect(() => {
        dispatch(fetchShops())
        const intervalId = setInterval(() => {
            dispatch(fetchShops()) // Fetch data every 2 minutes
        }, 3.6e+6);
        return () => clearInterval(intervalId);
    }, [])

    return <>
        <div className={style.viewContainer} style={{ backgroundColor: previewTheme.backgroundColor }}>
            <h1 className={`${style.companyName} ms-2`} style={{ fontFamily: previewTheme.fontStyle, color: previewTheme.color }}>{shop?.name ? shop?.name : 'Test shop'}</h1>
            <div className={style.logoContainer}>
                <ul className={style.products}>
                    {items && items.length !== 0 && items.map((item, i) => {
                        return <li className={i === itemCount ? (previewTheme.animation === 'rightLeftAnimations' ? style.rightLeftAnimations : (previewTheme.animation === 'bottomTopAnimations' ? style.bottomTopAnimations : style.fadeInOut)) : ''} style={{ display: i === itemCount ? 'inline-block' : 'none' }}>
                            <img className={style.productImage} src={item.itemImage} alt={item.itemName} />
                            <div className={style.productName} style={{ fontFamily: previewTheme.fontStyle, color: previewTheme.color }}>{item.itemName}</div>
                            <div className={`d-flex gap-2 ${style.priceContainer}`}>
                                {item.itemOfferPrice ?
                                    <>
                                        <div className={style.worsePrice} style={{ fontFamily: previewTheme.fontStyle, color: previewTheme.priceColor.worsePrice }}>{item.itemPrice}</div>
                                        <div className={style.bestPrice} style={{ fontFamily: previewTheme.fontStyle, color: previewTheme.priceColor.bestPrice }}>{item.itemOfferPrice}</div>
                                    </>
                                    :
                                    <div className={style.bestPrice} style={{ fontFamily: previewTheme.fontStyle, color: previewTheme.priceColor.bestPrice }}>{item.itemPrice}</div>
                                }
                            </div>
                        </li>
                    })
                    }
                </ul>
                <div className={style.logoText}>Powered by <img className={style.vshopLogo} src={assets.images.logo} alt={"vshops_logo"} /></div>
            </div>
        </div>
    </>
}

export default ProductView