import axios, { CancelTokenSource } from 'axios'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Response, api } from '../../api'
import assets from '../../assets'
import { UserContext } from '../../context/UserProvider'
import { TranslationLanguage, useT } from '../../i18n'
import { LoginForSuperAdmin, loginForSuperAdmin } from '../../redux/actions'
import { StoreState } from '../../redux/reducers'
import { AuthAdmin } from '../../redux/reducers/authAdmin.reducer'
import { FoodName } from '../../redux/reducers/food.reducer'
import { Shop } from '../../redux/reducers/shop.reducer'
import { AppDispatch } from '../../redux/store'
import style from '../../styles/components/user-header.module.scss'
import { useToast } from '../ToastProvider'
import Cart from '../svgs/Cart'
import Menu from '../svgs/Menu'
import Search from '../utils/Search'
import Spinner from '../utils/Spinner'
import AppMenu from './AppMenu'

interface UserHeaderProps {
    showSearch?: boolean
    onMenuClick?: () => void
}

type SearchResultType = 'shop' | 'item' | 'event' | 'blog'

interface SearchResult {
    type: SearchResultType
    itemId: string
    shopId: string
    shopName: string
    image: string
    names?: FoodName[]
    name?: string
    businessName?: string
}

const UserHeader = (props: UserHeaderProps) => {
    const t = useT()
    const searchContainerRef = React.useRef<HTMLDivElement>(null)
    const cartSize = useSelector<StoreState, number>(state => state.cart.items.length)
    const userContext = React.useContext(UserContext)
    const [searchTerm, setSearchTerm] = React.useState<string>('')
    const [showSearchResult, setShowSearchResult] = React.useState<boolean>(false)
    const [searching, setSearching] = React.useState<boolean>(false)
    const [searchResult, setSearchResult] = React.useState<SearchResult[]>([])

    const searchChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        if (e.target.value.trim() !== '') {
            setSearching(true)
        } else {
            setSearching(false)
        }

        setSearchTerm(e.target.value)
    }

    const searchFocusHandler = () => {
        setShowSearchResult(true)
    }

    React.useEffect(() => {
        let timeout: NodeJS.Timeout | undefined
        let source: CancelTokenSource | undefined

        if (searchTerm.trim() !== '') {
            timeout = setTimeout(() => {
                source?.cancel()
                source = axios.CancelToken.source()
                setSearching(true)
                api.get<Response<SearchResult[]>>(`search/${searchTerm.trim()}`, { cancelToken: source.token }).then(response => {
                    if (response.status === 200) {
                        setSearchResult(response.data.data)
                    } else {
                        // eslint-disable-next-line no-throw-literal
                        throw { response }

                    }
                }).catch(() => { }).finally(() => {
                    setSearching(false)
                })
            }, 300)
        } else {
            setSearchResult([])
        }

        return () => {
            clearTimeout(timeout)
            source?.cancel()
        }
    }, [searchTerm])

    React.useEffect(() => {
        window.addEventListener('click', e => {
            setShowSearchResult(!!searchContainerRef.current?.contains(e.target as Node))
        })
    }, [])

    return <header className='container py-2'>
        <div className='row'>
            <div className='col-md-5 col-xl-3 order-1'>
                <div className='d-flex align-items-center justify-content-between'>
                    <div className='d-flex align-items-center gap-1'>
                        <div className={style.menuIcon} onClick={props.onMenuClick}>
                            <Menu />
                        </div>
                        <Link to='/' className='d-inline-block text-decoration-none'>
                            <div className='hstack gap-3'>
                                <div className={style.logoWrapper}>
                                    <img src={assets.images.logo} alt='V-Shops logo' />
                                </div>
                                <div className={style.logoCaption}>{t("Virtual shopping")},<br />{t("Real value")}!</div>
                            </div>
                        </Link>
                    </div>
                    {userContext?.pathArray[1] !== "checkout-event" &&
                        <UserContext.Consumer>
                            {value => <div className={style.cartIcon} onClick={() => value?.setShowCart?.(true)}>
                                <Cart />
                                <div className={style.cartSize}>{cartSize}</div>
                            </div>}
                        </UserContext.Consumer>
                    }
                </div>
            </div>
            <div className='d-xl-block col-xl-5 order-2 order-md-3 order-xl-2'>
                {props.showSearch && <div className={style.searchContainer} ref={searchContainerRef}>
                    <Search
                        onChange={searchChangeHandler}
                        value={searchTerm}
                        onFocus={searchFocusHandler}
                        placeholder={t("Search for shop, events and products")}
                    />
                    {showSearchResult && <SearchResultModal
                        items={searchResult}
                        searchTerm={searchTerm}
                        searching={searching}
                    />}
                </div>}
            </div>
            <div className='d-none d-md-block col-md-7 col-xl-4 order-3 order-md-2 order-xl-3'>
                <AppMenu role='user' />
            </div>
        </div>
    </header>
}

export interface SearchBlogResults {
    blogId: string
    title: string
}

interface SearchResultModalProps {
    items?: SearchResult[]
    superAdminItems?: SearchResult[]
    blogItems?: SearchBlogResults[]
    searchTerm?: string
    searching?: boolean
    // superAdminSearch?: string
}

export const SearchResultModal = (props: SearchResultModalProps) => {
    const t = useT()

    return <div className={style.searchResultContainer}>
        {props.searching && <div className={`${style.searchResultItem} ${style.info}`}>
            <div className='hstack gap-2'>
                <Spinner color='#404040' size={15} />
                <div className={style.subName}>{t("Searching...")}</div>
            </div>
        </div>}

        {!props.searching && props.searchTerm === '' && <div className={`${style.searchResultItem} ${style.info}`}>
            <div className={style.subName}>{t("Type something above to search")}</div>
        </div>}

        {!props.searching && props.searchTerm !== '' && props.items && props.items.length === 0 && <div className={`${style.searchResultItem} ${style.info}`}>
            <div className={style.subName}>{t("Sorry, No results found. Try different keyword")}</div>
        </div>}

        {props.items && props.items?.map(item => {
            return <SearchResultItem item={item} key={item.type + item.itemId} />
        })}

        {!props.searching && props.searchTerm !== '' && props.blogItems && props.blogItems.length === 0 && <div className={`${style.searchResultItem} ${style.info}`}>
            <div className={style.subName}>{t("Sorry, No results found. Try different keyword")}</div>
        </div>}

        {props.blogItems && props.blogItems?.map(item => {
            return <SearchResultItem blogItems={item} key={item.blogId} />
        })}

        {!props.searching && props.searchTerm !== '' && props.superAdminItems && props.superAdminItems.length === 0 && <div className={`${style.searchResultItem} ${style.info}`}>
            <div className={style.subName}>{t("Sorry, No results found. Try different keyword")}</div>
        </div>}

        {props.superAdminItems && props.superAdminItems?.map(item => {
            return <SearchResultItem superSearchItem={item} key={item.type + item.itemId} />
        })}

        {/* {props.superAdminSearch !== '' && !props.searching && props.searchTerm !== '' && props.items && props.items.length === 0 && <div className={`${style.searchResultItem} ${style.info}`}>
            <div className={style.subName}>Sorry, No results found. Try different keyword</div>
        </div>}

        {props.superAdminItems && props.items && props.items?.map(item => {
            return <SearchResultItem superSearchItem={item} key={item.type + item.itemId} />
        })} */}
    </div>
}

interface SearchResultItemProps {
    item?: SearchResult
    superSearchItem?: SearchResult
    blogItems?: SearchBlogResults
}

const SearchResultItem = (props: SearchResultItemProps) => {
    const toast = useToast()
    const t = useT()

    const dispatch = useDispatch<AppDispatch>()
    const translationLanguage = useSelector<StoreState, TranslationLanguage>(state => state.translationLanguage)
    const shops = useSelector<StoreState, Shop[] | null>(state => state.shops)
    const admin = useSelector<StoreState, AuthAdmin | null>(state => state.authAdmin)

    const goToShopHanlder = async (shopId: string) => {
        let shop = shops?.find(s => s.id?.toString() === shopId?.toString())
        let adminObject = shop?.admins?.at(0);
        try {
            if (adminObject !== undefined) {
                let username = (adminObject.username !== undefined) ? adminObject.username : ''

                const data: LoginForSuperAdmin = {
                    username: username
                }
                dispatch(loginForSuperAdmin(data))
            }
            if (admin !== null) window.open('/admin', '_blank')

        } catch (exception) {
            toast(t("Invalid shop."));
        }
    }

    return <>
        {props.item && <Link to=
            {props.item?.type === 'event'
                ? `/shop/${props.item?.shopId || ''}`
                : props.item?.type === 'blog'
                    ? `/blog-post/${props.item?.itemId}`
                    : `/shop/${props.item?.shopId || ''}?search=${(props?.item.names?.length! > 1 ?
                        props?.item.names?.find(n => n.language === translationLanguage)?.name :
                        props?.item.names?.[0].name) || ''}`}
            className={style.searchResultItem}>
            <div className='hstack gap-2'>
                <div className={`${style.imageWrapper} ${props.item?.image === '' ? style.noImage : ''}`}>
                    <img src={props.item?.image} alt='' />
                </div>
                <div className='vstack'>
                    {props.item?.type === 'item' && <React.Fragment>
                        <div className={style.name}>{props?.item.names?.length! > 1 ?
                            props?.item.names?.find(n => n.language === translationLanguage)?.name :
                            props?.item.names?.[0].name}</div>
                        <div className={style.subName}>{props.item?.shopName}</div>
                    </React.Fragment>}
                    {props.item?.type === 'shop' && <React.Fragment>
                        <div className={style.name}>{props.item.shopName}</div>
                        <div className={style.subName}>{props.item.businessName}</div>
                    </React.Fragment>}
                    {props.item?.type === 'event' && <React.Fragment>
                        <div className={style.name}>{props?.item.names?.length! > 1 ?
                            props?.item.names?.find(n => n.language === translationLanguage)?.name :
                            props?.item.names?.[0].name}</div>
                    </React.Fragment>}
                    {props.item?.type === 'blog' && <React.Fragment>
                        <div className={style.name}>{props.item.name}</div>
                    </React.Fragment>}
                </div>
            </div>
        </Link>}

        {props.superSearchItem && <div onClick={() => goToShopHanlder(props.superSearchItem?.shopId ? props.superSearchItem?.shopId : '')} className={style.searchResultItem}>
            <div className='hstack gap-2'>
                <div className={`${style.imageWrapper} ${props.superSearchItem?.image === '' ? style.noImage : ''}`}>
                    <img src={props.superSearchItem?.image} alt='' />
                </div>

                <div className='vstack'>
                    {props.superSearchItem?.type === 'shop' && <React.Fragment>
                        <div className={style.name}>{props.superSearchItem.shopName}</div>
                        <div className={style.subName}>{props.superSearchItem.businessName}</div>
                    </React.Fragment>}
                </div>
            </div>
        </div>}

        {props.blogItems && <Link to={`/blog-post/${props.blogItems?.blogId || ''}`} className={style.searchResultItem}>
            <div className='hstack gap-2'>
                <div className='vstack'>
                    <div className={style.name}>{props.blogItems.title}</div>
                </div>
            </div>
        </Link>}
    </>
}

export default UserHeader