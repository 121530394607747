import { nanoid } from '@reduxjs/toolkit'
import { ContentState, convertToRaw, EditorState } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import React from 'react'
import { Editor } from 'react-draft-wysiwyg'
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { useDispatch, useSelector } from 'react-redux'
import { api, Response } from '../../api'
import { DEFAULT_LANGUAGES } from '../../config'
import { AdminContext, AdminContextType } from '../../context/AdminProvider'
import { TranslationLanguage, useT } from '../../i18n'
import { AddEvent, addEvent, addLanguage, addSize, updateEvent } from '../../redux/actions'
import { StoreState } from '../../redux/reducers'
import { AuthAdmin } from '../../redux/reducers/authAdmin.reducer'
import { Category } from '../../redux/reducers/category.reducer'
import { Event, EventType } from '../../redux/reducers/event.reducer'
import { AppDispatch } from '../../redux/store'
import errorStyle from '../../styles/components/error-text.module.scss'
import formStyle from '../../styles/components/form.module.scss'
import { toNumber, toString } from '../../utils'
import ModalWithHeader from '../modals/ModalWithHeader'
import SelectableTileSection from '../sections/SelectableTileSection'
import SelectableTileSectionEvent from '../sections/SelectableTileSectionEvent'
import Camera from '../svgs/Camera'
import Edit from '../svgs/Edit'
import { ToastType, useToast } from '../ToastProvider'
import ErrorText from '../utils/ErrorText'
import FormInput from '../utils/FormInput'
import Radio from '../utils/Radio'
import ResponsiveFormInput from '../utils/ResponsiveFormInput'
import SmallImage from '../utils/SmallImage'
import Switch from '../utils/Switch'
import FoodPriceForm from './FoodPriceForm'
import InstantInputForm from './InstantInputForm'

interface Name {
    language: string
    name: string
    errorText: string
    id: string
}

interface Description {
    language: string
    editContent: EditorState
    description: string
    errorText: string
    id: string
}

interface Price {
    price: string
    priceError: string
    offerPrice: string
    offerPriceError: string
    size: string
    id: string
}

interface EventAdditionImage {
    image: string
    id: string
}

interface EventFormProps {
    onClose?: () => void
    id?: string | null
}

const EventForm = (props: EventFormProps) => {
    const dispatch = useDispatch<AppDispatch>()
    const toast = useToast()
    const t = useT()

    const authAdmin = useSelector<StoreState, AuthAdmin | null>(state => state.authAdmin)
    const adminContext = React.useContext<AdminContextType | null>(AdminContext)

    const languages = useSelector<StoreState, string[]>(state => state.languages)
    const categories = useSelector<StoreState, Category[]>(state => state.categories)
    const provideFoodStatus = useSelector<StoreState, string[]>(state => ['Vegetarian', 'Vegan', 'Non-veg', 'Gluten-free', 'Mediterranean', 'Lactose-free', 'Keto', 'Paleo', 'Kids Menu'])
    const volunteersMenus = useSelector<StoreState, string[]>(state => ['Event organization', 'Photography', 'Registration', 'Decoration', 'Food serving', 'Cleaning', 'Windup', 'Food Preparation', 'Unfortunately, Not available'])
    const cultureVouchersMenus = useSelector<StoreState, string[]>(state => ['E-Passi', 'Smartum', 'Edenred'])
    const sizes = useSelector<StoreState, string[]>(state => state.sizes)
    const translationLanguage = useSelector<StoreState, TranslationLanguage>(state => state.translationLanguage)
    const event = useSelector<StoreState, Event | null>(state => props.id ? state.events.find(f => f.id === props.id) || null : null)

    const [onCamera, setOnCamera] = React.useState<boolean>(false)
    const [selectedLanguages, setSelectedLanguages] = React.useState<string[]>(DEFAULT_LANGUAGES)
    const [foodPreferences, setFoodPreferences] = React.useState<string[] | null>([])
    const [volunteers, setVolunteers] = React.useState<string[] | null>([])
    const [cultureVouchers, setCultureVouchers] = React.useState<string[] | null>([])
    const [selectedLanguagesError, setSelectedLanguagesError] = React.useState<string>('')
    const [additionalImages, setAdditionalImages] = React.useState<EventAdditionImage[]>([])
    const [additionalImagesError, setAdditionalImagesError] = React.useState<string>('')
    const [selectedCategories, setSelectedCategories] = React.useState<string[]>([])
    const [selectedSizes, setSelectedSizes] = React.useState<string[]>([])
    const [selectedSizesError, setSelectedSizesError] = React.useState<string>('')
    const [selectedCategoriesError, setSelectedCategoriesError] = React.useState<string>('')
    const [descriptions, setDescriptions] = React.useState<Description[]>([])
    const [image, setImage] = React.useState<any>(authAdmin?.shop.theme.bannerImage ? authAdmin?.shop.theme.bannerImage : '')
    const [imageError, setImageError] = React.useState<string>('')
    const [names, setNames] = React.useState<Name[]>([])
    const [offerLabel, setOfferLabel] = React.useState<string>('')
    const [offerLabelError, setOfferLabelError] = React.useState<string>('')
    const [prices, setPrices] = React.useState<Price[]>([])
    const [location, setLocation] = React.useState<string>('')
    const [locationError, setLocationError] = React.useState<string>('')
    const [tax, setTax] = React.useState<string>('')
    const [taxError, setTaxError] = React.useState<string>('')
    const [type, setType] = React.useState<EventType>('paid')
    const [typeError, setTypeError] = React.useState<string>('')
    const [price, setPrice] = React.useState<string>('')
    const [withoutRegistration, setWithoutRegistration] = React.useState<string>('No')
    // const [comments, setComments] = React.useState<string>('')
    const [eventRule, setEventRule] = React.useState<EditorState>(EditorState.createEmpty())
    const [priceError, setPriceError] = React.useState<string>('')
    const [offerPrice, setOfferPrice] = React.useState<string>('')
    const [offerPriceError, setOfferPriceError] = React.useState<string>('')
    const [fromDate, setFromDate] = React.useState<string>('');
    const [fromDateError, setFromDateError] = React.useState<string>('')
    const [toDate, setToDate] = React.useState<string>('');
    const [toDateError, setToDateError] = React.useState<string>('')
    const [fromTime, setFromTime] = React.useState<string>('');
    const [fromTimeError, setFromTimeError] = React.useState<string>('')
    const [toTime, setToTime] = React.useState<string>('');
    const [toTimeError, setToTimeError] = React.useState<string>('')
    const [eventStatus, setEventStatus] = React.useState<string>('active')

    const [loading, setLoading] = React.useState<boolean>(false)

    const languageClickHandler = (lng?: string) => {
        if (lng && lng !== '') {
            setSelectedLanguages(prev => {
                return prev.some(p => p === lng)
                    ? prev.length > 1
                        ? prev.filter(p => p !== lng)
                        : prev
                    : [
                        ...prev,
                        lng
                    ]
            })

            setSelectedLanguagesError('')
        }
    }
    const foodPreferenceClickHandler = (foodType?: string) => {
        if (foodType && foodType !== '') {
            setFoodPreferences((prev: any) => {
                return prev.some((p: string) => p === foodType)
                    ? prev.length > 0
                        ? prev.filter((p: string) => p !== foodType)
                        : prev
                    : [
                        ...prev,
                        foodType
                    ]
            })
        }
    }

    const VolunteersClickHandler = (volunteer?: string) => {
        if (volunteer && volunteer !== '') {
            setVolunteers((prev: any) => {
                return prev.some((p: string) => p === volunteer)
                    ? prev.length > 0
                        ? prev.filter((p: string) => p !== volunteer)
                        : prev
                    : [
                        ...prev,
                        volunteer
                    ]
            })
        }
    }

    const cultureVoucherClickHandler = (voucher?: string) => {
        if (voucher && voucher !== '') {
            setCultureVouchers((prev: any) => {
                return prev.some((p: string) => p === voucher)
                    ? prev.length > 0
                        ? prev.filter((p: string) => p !== voucher)
                        : prev
                    : [
                        ...prev,
                        voucher
                    ]
            })
        }
    }

    const addLanguageClickHandler = (language: string) => {
        dispatch(addLanguage(language))
    }

    const nameChangeHandler = (id: string | null, value: string) => {
        setNames(prev => {
            const names = [...prev]
            const index = names.findIndex(p => p.id === id)

            if (index > -1) {
                names[index] = {
                    ...names[index],
                    name: value,
                    errorText: ''
                }
            }

            return names
        })
    }

    const descriptionChangeHandler = (lang: string | null, value: string, state: EditorState) => {
        setDescriptions(prev => {
            const descriptions = [...prev]
            const index = descriptions.findIndex(p => p.language === lang)
            if (index > -1) {
                descriptions[index] = {
                    ...descriptions[index],
                    description: value,
                    editContent: state,
                    errorText: ''
                }
            }

            return descriptions
        })
    }

    const eventRuleChangeHandler = (editorState: EditorState) => {
        setEventRule(editorState);
    }

    const priceChangeHandler = (value: string, id?: string) => {
        if (id) {
            setPrices(prev => {
                const prices = [...prev]
                const index = prices.findIndex(p => p.id === id)

                if (index > -1) {
                    prices[index] = {
                        ...prices[index],
                        price: value,
                        priceError: ''
                    }
                }

                return prices
            })
        } else {
            setPrice(value)
            setPriceError('')
        }
    }

    const offerPriceChangeHandler = (value: string, id?: string) => {
        if (id) {
            setPrices(prev => {
                const prices = [...prev]
                const index = prices.findIndex(p => p.id === id)

                if (index > -1) {
                    prices[index] = {
                        ...prices[index],
                        offerPrice: value,
                        offerPriceError: ''
                    }
                }

                return prices
            })
        } else {
            setOfferPrice(value)
            setOfferPriceError('')
        }
    }

    const addNewSizeClickHandler = (size: string) => {
        dispatch(addSize(size))
    }

    const addMoreImageClickHandler = async (image: string) => {
        if (image !== '') {
            setAdditionalImages(prev => {
                return [
                    ...prev,
                    {
                        image: image,
                        id: nanoid()
                    }
                ]
            })
            setAdditionalImagesError('')
        }
    }

    const imageChangeHandler: React.ChangeEventHandler<HTMLInputElement | HTMLFormElement> = async (e) => {
        let response: any
        if (onCamera) {
            const formData: any = new FormData()
            formData.append("file", e.target.files[0]);
            response = await api.post<Response>(`upload-img/events`, formData);
        }
        onCamera ? setImage(response.data.data) : setImage(e.target.value)
        setImageError('')
    }

    const removeAdditionalImage = (id: string) => {
        setAdditionalImages(prev => {
            return prev.filter(img => img.id !== id)
        })
    }

    const categoryClickHandler = (id?: string) => {
        if (id && id !== '') {
            setSelectedCategories(prev => {
                return prev.some(p => p === id)
                    ? prev.filter(p => p !== id)
                    : [
                        ...prev,
                        id
                    ]
            })
            setSelectedCategoriesError('')
        }
    }

    const selectSizeHandler = (size?: string) => {
        if (size && size !== '') {
            setSelectedSizes(prev => {
                return prev.some(p => p === size)
                    ? prev.filter(p => p !== size)
                    : [
                        ...prev,
                        size
                    ]
            })
            setSelectedSizesError('')
        }
    }
    const locationChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setLocation(e.target.value)
        setLocationError('')
    }

    const offerLabelChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setOfferLabel(e.target.value)
        setOfferLabelError('')
    }

    const fromDateChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setFromDate(e.target.value)
        setFromDateError('')
    }
    const toDateChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setToDate(e.target.value)
        setToDateError('')
    }
    const fromTimeChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setFromTime(e.target.value)
        setFromTimeError('')
    }
    const toTimeChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setToTime(e.target.value)
        setToTimeError('')
    }
    const typeClickHandler = (value: EventType) => {
        setType(value)
        setTypeError('')
    }
    const taxChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setTax(e.target.value)
        setTaxError('')
    }

    const submitHandler: React.MouseEventHandler<HTMLButtonElement> = (e) => {
        e.preventDefault()

        let error = false

        const eventRuleState = draftToHtml(convertToRaw(eventRule.getCurrentContent()));
        let eventR = eventRuleState;

        if (selectedLanguages.length <= 0) {
            error = true
            setSelectedLanguagesError(t('Language required'))
        }

        names.forEach((name, i) => {
            if (toString(name.name) === '') {
                error = true
                setNames(prev => {
                    const names = [...prev]
                    names[i].errorText = name.language.charAt(0).toUpperCase() + name.language.substring(1) + t('Name required')
                    return names
                })
            }
        })

        descriptions.forEach((description, i) => {
            if (toString(description.description) === '') {
                error = true
                setDescriptions(prev => {
                    const descriptions = [...prev]
                    descriptions[i].errorText = description.language.charAt(0).toUpperCase() + description.language.substring(1) + t('Description required')
                    return descriptions
                })
            }
        })

        if (selectedCategories.length <= 0) {
            error = true
            setSelectedCategoriesError(t('Categories required'))
        }

        // if (toString(image) === '') {
        //     error = true
        //     setImageError(t('Image required')) 
        // }

        if (toString(location) === '') {
            error = true
            setLocationError(t('Location is required'))
        }

        if (toString(tax) !== '' && toNumber(tax) < 0) {
            error = true
            setTaxError(t('Invalid tax given'))
        }

        if (type === 'paid') {
            if (prices.length <= 0) {
                error = true
                setSelectedSizesError(t('Prices required'))
            } else {
                prices.forEach((price, i) => {
                    if (toString(price.size) === '') {
                        error = true
                        setPrices(prev => {
                            const prices = [...prev]
                            prices[i].priceError = t('Size required')
                            return prices
                        })
                    }

                    if (toString(price.price) === '') {
                        error = true
                        setPrices(prev => {
                            const prices = [...prev]
                            prices[i].priceError = t('Price required')
                            return prices
                        })
                    }
                    else if (toNumber(price.price) < 0) {
                        error = true
                        setPrices(prev => {
                            const prices = [...prev]
                            prices[i].priceError = t('Invalid price given')
                            return prices
                        })
                    }

                    if (toString(price.offerPrice) !== '' && toNumber(price.offerPrice) < 0) {
                        error = true
                        setPrices(prev => {
                            const prices = [...prev]
                            prices[i].offerPriceError = t('Invalid offer price given')
                            return prices
                        })
                    }
                })
            }

        }

        if (type === 'free') {
            prices.forEach((price, i) => {
                if (toString(price.size) === '') {
                    error = true
                    setPrices(prev => {
                        const prices = [...prev]
                        prices[i].priceError = t('Size required')
                        return prices
                    })
                }
            })
        }

        if (!error) {
            const eventData: AddEvent = {
                additionalImages: additionalImages.map(img => toString(img.image)),
                categories: selectedCategories.map(toString),
                descriptions: descriptions.map(d => {
                    return {
                        description: toString(d.description),
                        language: toString(d.language)
                    }
                }),
                image: toString(image),
                names: names.map(n => {
                    return {
                        language: toString(n.language),
                        name: toString(n.name)
                    }
                }),
                offerLabel: toString(offerLabel) !== '' ? toString(offerLabel) : null,
                location,
                fromDate,
                fromTime,
                toDate,
                toTime,
                price: type === 'free' ? toNumber(price) : null,
                offerPrice: type === 'free' && toNumber(offerPrice) > 0 ? toNumber(offerPrice) : null,
                tax: toNumber(tax) || 0,
                type: type,
                foodPreferences,
                volunteers,
                cultureVouchers,
                eventRule: eventR,
                eventStatus,
                withoutRegistration,
                prices: prices.map(p => {
                    return {
                        price: type === 'free' ? 0 : toNumber(p.price),
                        size: toString(p.size),
                        offerPrice: toString(p.offerPrice) !== '' && type !== 'free' ? toNumber(p.offerPrice) : null
                    }
                })
            }

            if (props.id && event) {
                setLoading(true)
                dispatch(updateEvent(eventData, event.id)).then(() => {
                    toast(t('Event updated'))
                    props.onClose?.()
                }).catch(text => {
                    toast(text, ToastType.ERROR)
                }).finally(() => {
                    setLoading(false)
                })
            } else {
                setLoading(true)
                dispatch(addEvent(eventData)).then(() => {
                    toast(t('Event added'))
                    props.onClose?.()
                }).catch(text => {
                    toast(text, ToastType.ERROR)
                }).finally(() => {
                    setLoading(false)
                })
            }
        }
    }

    React.useEffect(() => {
        setPrices(prev => {
            const newState = [...prev]
            return selectedSizes.map<Price>(size => {
                const index = newState.findIndex(p => p.size === size)
                return index > -1
                    ? newState[index]
                    : {
                        offerPrice: '',
                        offerPriceError: '',
                        price: '',
                        priceError: '',
                        size: size,
                        id: nanoid()
                    }
            })
        })
    }, [selectedSizes])

    React.useEffect(() => {
        setNames(prev => {
            return selectedLanguages.map(lng => {
                return prev.find(p => p.language === lng) || {
                    errorText: '',
                    id: nanoid(),
                    language: lng,
                    name: ''
                }
            })
        })

        setDescriptions(prev => {
            return selectedLanguages.map(lng => {
                return prev.find(p => p.language === lng) || {
                    errorText: '',
                    id: nanoid(),
                    language: lng,
                    editContent: EditorState.createEmpty(),
                    description: ''
                }
            })
        })
    }, [selectedLanguages])

    React.useEffect(() => {
        if (props.id && event) {
            setAdditionalImages(event.additionalImages.map(i => ({ id: nanoid(), image: i })))
            setSelectedCategories(event.categories.map(c => c.id))
            setDescriptions(() => {
                const descriptions: Description[] = []

                event.descriptions.forEach(n => {
                    let contenthtmlToDraft = htmlToDraft(n.description);
                    const contentState = ContentState.createFromBlockArray(contenthtmlToDraft.contentBlocks);
                    if (!descriptions.some(p => p.language === n.language)) {
                        descriptions.push({
                            errorText: '',
                            id: n.id,
                            language: n.language,
                            editContent: EditorState.createWithContent(contentState),
                            description: n.description
                        })
                    }
                })

                return descriptions
            })
            setLocation(event.location)
            setImage(event.image)
            setNames(() => {
                const names: Name[] = []

                event.names.forEach(n => {
                    if (!names.some(p => p.language === n.language)) {
                        names.push({
                            errorText: '',
                            id: n.id,
                            language: n.language,
                            name: n.name
                        })
                    }
                })

                return names
            })
            setOfferLabel(event.offerLabel || '')
            setFromDate(event.fromDate || '')
            setFromTime(event.fromTime || '')
            setToDate(event.toDate || '')
            setToTime(event.toTime || '')
            setType(event.type || '')
            setWithoutRegistration(event.withoutRegistration || '')
            setEventStatus(event.eventStatus || '')
            setTax(event.tax?.toString() || '')
            let eventRuleEngBlock = htmlToDraft(event.eventRule || '');
            const eventRuleState = ContentState.createFromBlockArray(
                eventRuleEngBlock.contentBlocks
            );
            const editorState = EditorState.createWithContent(eventRuleState);
            setEventRule(editorState)
            setPrices(() => {
                const prices: Price[] = []

                event.prices.forEach(n => {
                    if (!prices.some(p => p.size === n.size)) {
                        prices.push({
                            id: n.id,
                            size: n.size,
                            offerPrice: n.offerPrice?.toString() || '',
                            offerPriceError: '',
                            price: n.price.toString(),
                            priceError: ''
                        })
                    }
                })

                return prices
            })
            setPrice(event.price?.toString() || '')
            setOfferPrice(event.offerPrice?.toString() || '')
            setFoodPreferences(event.foodPreferences)
            setVolunteers(event.volunteers)
            setCultureVouchers(event.cultureVouchers)
            setSelectedLanguages(() => {
                const lngs: string[] = []
                event.names.forEach(n => {
                    if (lngs.indexOf(n.language) === -1) {
                        lngs.push(n.language)
                    }
                })
                return lngs
            })
            setSelectedSizes(() => {
                const sizes: string[] = []
                event.prices.forEach(n => {
                    if (sizes.indexOf(n.size) === -1) {
                        sizes.push(n.size)
                    }
                })
                return sizes
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [event])

    const editorChangeHandler = (editorState: EditorState, lang: string) => {
        const contentState = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        const description = contentState;
        descriptionChangeHandler(lang, description, editorState)
    }

    const toggleHandler = () => {
        setEventStatus(eventStatus === 'active' ? 'inactive' : 'active');
    }

    const withoutRegistraionHandler = () => {
        setWithoutRegistration(withoutRegistration === 'No' ? 'Yes' : 'No');
    }

    return <ModalWithHeader
        buttonText={t('Save')}
        headerText={props.id ? t('Edit Event') : t('Add Event')}
        loading={loading}
        onClose={props.onClose}
        onSave={submitHandler}
    >
        <div className='row mb-4'>
            <div className='col'>
                <SelectableTileSection
                    list={languages}
                    getSelectableTileProps={language => ({
                        key: language,
                        active: selectedLanguages.some(slng => slng === language),
                        onClick: languageClickHandler,
                        value: language,
                        label: language
                    })}
                    title={t('Language')}
                    instantInput={{
                        onAdd: addLanguageClickHandler,
                        label: t('Language')
                    }}
                    errorText={selectedLanguagesError}
                />
            </div>
        </div>

        <div className='row'>
            <div className='col'>
                <div className='row'>
                    {selectedLanguages.map(lng => {
                        const name = names.find(n => n.language === lng)

                        return <ResponsiveFormInput
                            responsiveClassName='col-md-6'
                            containerClass='mb-4'
                            type='text'
                            key={lng}
                            label={lng.charAt(0).toUpperCase() + lng.slice(1) + t('Name')}
                            value={name?.name || ''}
                            onChange={e => nameChangeHandler(name?.id || null, e.target.value)}
                            errorText={name?.errorText}
                        />
                    })}
                </div>
            </div>
        </div>

        <div className='row'>
            <div className='col'>
                <div className='row'>
                    {selectedLanguages.map(lng => {
                        const description = descriptions.find(d => d.language === lng)
                        let error = description?.errorText === '' ? false : true

                        return <div className='col-md-6 mb-4'>
                            {lng.charAt(0).toUpperCase() + lng.slice(1) + t(' description')}
                            <Editor
                                key={lng}
                                editorState={description?.editContent}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName border rounded-5 p-3"
                                editorClassName="editorClassName"
                                onEditorStateChange={(e) => editorChangeHandler(e, lng)}
                            />
                            <span className={errorStyle.errorText}>{description?.errorText}</span>
                        </div>
                    })}
                </div>
            </div>
        </div>
        {/* <div className='row'>
            <div className='col'>
                <div className='row'>
                    {selectedLanguages.map(lng => {
                        const description = descriptions.find(d => d.language === lng)

                        return <ResponsiveTextArea
                            key={lng}
                            label={lng.charAt(0).toUpperCase() + lng.slice(1) + t('Description')}
                            value={description?.description || ''}
                            onChange={e => descriptionChangeHandler(description?.id || null, e.target.value)}
                            responsiveClassName='col-md-6'
                            containerClass='mb-4'
                            errorText={description?.errorText}
                        />
                    })}
                </div>
            </div>
        </div> */}

        <div className='row mb-4'>
            <div className='col'>
                <FormInput
                    type={onCamera ? 'file' : 'text'}
                    label={t('Image')}
                    placeholder={t('Image')}
                    value={onCamera ? image.filname : image}
                    onChange={imageChangeHandler}
                    rightRenderer={!onCamera ? <Camera strokeWidth={2} /> : <Edit />}
                    onRightRendererClick={() => setOnCamera(onCamera === true ? false : true)}
                    containerClass='mb-4'
                    errorText={imageError}
                />
                <div className='position-relative'>
                    <div className='hstack gap-2 flex-wrap'>
                        {image !== '' && <SmallImage
                            image={image}
                        />}
                        {additionalImages.length > 0 && additionalImages.map(img => {
                            return <SmallImage
                                key={img.id}
                                image={img.image}
                                onClose={() => removeAdditionalImage(img.id)}
                            />
                        })}
                        <InstantInputForm
                            onAdd={addMoreImageClickHandler}
                            type="events"
                            label={t('More image')}
                        />
                    </div>
                    <ErrorText errorText={additionalImagesError} />
                </div>
            </div>
        </div>

        <div className='row mb-4'>
            <div className='col'>
                <SelectableTileSection
                    list={categories}
                    addButtonText={t('Add Category')}
                    errorText={selectedCategoriesError}
                    getSelectableTileProps={category => ({
                        key: category.id,
                        value: category.id,
                        label: category?.names?.length! > 1 ?
                            category?.names.find(n => n.language === translationLanguage)?.name :
                            category?.names[0].name,
                        active: selectedCategories.some(ss => ss === category.id),
                        onClick: categoryClickHandler,
                        onEdit: () => adminContext?.onEditCategory?.(category.id),
                        error: selectedCategoriesError !== ''
                    })}
                    onAdd={adminContext?.onAddCategory}
                    title={t('Categories')}
                />
            </div>
        </div>
        <div className='row'>
            <div className='col'>
                <FormInput
                    type='text'
                    label={t('Location')}
                    placeholder={t('Location')}
                    value={location}
                    errorText={locationError}
                    onChange={locationChangeHandler}
                    containerClass='mb-4'
                />
            </div>
        </div>
        <div className='row'>
            <div className='col'>
                <FormInput
                    type='text'
                    label={t('Offer label')}
                    placeholder={t('Offer label')}
                    value={offerLabel}
                    errorText={offerLabelError}
                    onChange={offerLabelChangeHandler}
                    containerClass='mb-4'
                />
            </div>
        </div>
        <div className='row'>
            <div className='col'>
                <FormInput
                    type='date'
                    label={t('Date')}
                    placeholder=''
                    value={fromDate}
                    errorText={fromDateError}
                    onChange={fromDateChangeHandler}
                    containerClass='mb-4'
                />
            </div>
            <div className='col'>
                <FormInput
                    type='time'
                    label={t('Time')}
                    placeholder=''
                    value={fromTime}
                    errorText={fromTimeError}
                    onChange={fromTimeChangeHandler}
                    containerClass='mb-4'
                />
            </div>
            {/* <div className='col'>
                <FormInput
                    type='date'
                    label='To'
                    placeholder=''
                    value={toDate}
                    errorText={toDateError}
                    onChange={toDateChangeHandler}
                    containerClass='mb-4'
                />
            </div> */}
            <div className='col'>
                <FormInput
                    type='time'
                    label='_'
                    placeholder=''
                    value={toTime}
                    errorText={toTimeError}
                    onChange={toTimeChangeHandler}
                    containerClass='mb-4'
                />
            </div>
        </div>
        <div className='row mb-4'>
            <div className='col'>
                <div className='position-relative'>
                    <div className={formStyle.sectionHeader}>{t("Type")}</div>
                    <div className='hstack gap-3'>
                        <Radio checked={type === 'paid'} onClick={() => typeClickHandler('paid')}>{t("Paid")}</Radio>
                        <Radio checked={type === 'free'} onClick={() => typeClickHandler('free')}>{t("Free")}</Radio>
                    </div>
                    <ErrorText errorText={typeError} />
                </div>
            </div>
        </div>
        <div className='row mb-4'>
            <div className='col'>
                {type === 'paid' && <SelectableTileSection
                    list={sizes}
                    addButtonText={t('Add Size')}
                    errorText={selectedSizesError}
                    getSelectableTileProps={size => ({
                        key: size,
                        value: size,
                        label: size,
                        active: selectedSizes.some(s => s === size),
                        onClick: selectSizeHandler,
                        error: selectedSizesError !== ''
                    })}
                    instantInput={{
                        onAdd: addNewSizeClickHandler,
                        label: t('Size')
                    }}
                    title={t('Prices')}
                />}
                <div className='row'>
                    {type === 'paid' && selectedSizes.map(size => {
                        const price = prices.find(p => p.size === size)

                        return <FoodPriceForm
                            responsiveClassName='col-sm-6 col-md-4 my-2'
                            label={price?.size}
                            price={price?.price || ''}
                            priceError={price?.priceError}
                            offerPrice={price?.offerPrice || ''}
                            offerPriceError={price?.offerPriceError}
                            onOfferPriceChange={e => offerPriceChangeHandler(e.target.value, price?.id)}
                            onPriceChange={e => priceChangeHandler(e.target.value, price?.id)}
                            key={size}
                            showOfferPrice
                        />
                    })}

                    {type === 'free' && <SelectableTileSection
                        list={sizes}
                        errorText={selectedSizesError}
                        getSelectableTileProps={size => ({
                            key: size,
                            value: size,
                            label: size,
                            active: selectedSizes.some(s => s === size),
                            onClick: selectSizeHandler,
                            error: selectedSizesError !== ''
                        })}
                        instantInput={{
                            onAdd: addNewSizeClickHandler,
                            label: t('Size')
                        }}
                        title={t('Prices')}
                    />}
                </div>
            </div>
        </div>
        <div className='row mb-4'>
            <div className='col'>
                <SelectableTileSectionEvent
                    list={provideFoodStatus}
                    getSelectableTileProps={footype => ({
                        key: footype,
                        active: foodPreferences?.some(slng => slng === footype),
                        onClick: foodPreferenceClickHandler,
                        value: footype,
                        label: footype
                    })}
                    title={t('Food Preference')}
                />
            </div>
        </div>

        <div className='row mb-4'>
            <div className='col'>
                <SelectableTileSectionEvent
                    list={volunteersMenus}
                    getSelectableTileProps={volunteer => ({
                        key: volunteer,
                        active: volunteers?.some(slng => slng === volunteer),
                        onClick: VolunteersClickHandler,
                        value: volunteer,
                        label: volunteer
                    })}
                    title={t('Volunteering')}
                />
            </div>
        </div>
        <div className='row mb-4'>
            <div className='col'>
                <SelectableTileSectionEvent
                    list={cultureVouchersMenus}
                    getSelectableTileProps={voucher => ({
                        key: voucher,
                        active: cultureVouchers?.some(cv => cv === voucher),
                        onClick: cultureVoucherClickHandler,
                        value: voucher,
                        label: voucher
                    })}
                    title={t('Culture Vouchers')}
                />
            </div>

        </div>
        <div className='row'>
            <div className='col-12'>
                <FormInput
                    type='number'
                    label={t('Tax')}
                    placeholder={t('Tax')}
                    value={tax}
                    onChange={taxChangeHandler}
                    errorText={taxError}
                    containerClass='mb-4'
                />
            </div>
        </div>
        <div className='row'>
            <div className='col'>
                <div className='d-flex gap-3 mb-3'>
                    <label>{t("Event Registrations :")} </label>
                    <Switch
                        onClick={toggleHandler}
                        checked={eventStatus === "active" ? true : false}
                        label={eventStatus === "active" ? t('Opened') : t('Closed')}
                    />
                </div>
            </div>
            <div className='col'>
                <div className='d-flex gap-3 mb-3'>
                    <label>{t("Without Registrations :")} </label>
                    <Switch
                        onClick={withoutRegistraionHandler}
                        checked={withoutRegistration === "Yes" ? true : false}
                        label={withoutRegistration === "Yes" ? t('Yes') : t('No')}
                    />
                </div>
            </div>
        </div>

        <div className='row'>
            <div className='col-12'>
                <label>{t("Event Rules")}</label>
                <div className='border rounded-5 p-3 m-1'>
                    <Editor
                        editorState={eventRule}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        onEditorStateChange={eventRuleChangeHandler}
                    />
                </div>
            </div>
        </div>



    </ModalWithHeader>
}

export default EventForm