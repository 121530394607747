import React from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { ToastType, useToast } from "../../components/ToastProvider";
import EventCard, { EventCardProps } from '../../components/cards/EventCard';
import Confirmation from "../../components/modals/Confirmation";
import AdminLayout from "../../components/sections/AdminLayout";
import AdminPanelHeader from '../../components/sections/AdminPanelHeader';
import Search from '../../components/utils/Search';
import { AdminContext, AdminContextType } from '../../context/AdminProvider';
import { UserContext, UserContextType } from '../../context/UserProvider';
import { TranslationLanguage, useT } from "../../i18n";
import { AddEvent, addEvent } from "../../redux/actions";
import { StoreState } from '../../redux/reducers';
import { AuthAdmin } from '../../redux/reducers/authAdmin.reducer';
import { Event as EventType } from '../../redux/reducers/event.reducer';
import { AppDispatch } from "../../redux/store";
import style from '../../styles/components/shop-product-details.module.scss';
import { toNumber, toString } from '../../utils';

interface Props {

}
const Events: React.FC<Props> = (props: Props) => {
    const t = useT()
    const dispatch = useDispatch<AppDispatch>()
    const authAdmin = useSelector<StoreState, AuthAdmin | null>(state => state.authAdmin)
    const translationLanguage = useSelector<StoreState, TranslationLanguage>(state => state.translationLanguage)

    const userContext = React.useContext<UserContextType | null>(UserContext)

    const adminContext = React.useContext<AdminContextType | null>(AdminContext)

    const [openImportModal, setOpenImportModal] = React.useState(false)

    const events = useSelector<StoreState, EventType[]>(state => state.events)

    const [filteredEvents, setFilteredEvents] = React.useState<EventType[]>(events)

    const searchRef = React.useRef<HTMLInputElement>(null)
    const [searchQuery, setSearchQuery] = useSearchParams()

    const searchTerm = searchQuery.get('search') || ''

    const searchTermChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setSearchQuery({ search: e.target.value })
    }

    React.useEffect(() => {
        if (searchTerm !== "") {
            const filteredEvents = events.filter(f => f.names?.length! > 1 ?
                f.names.find(n => n.language === translationLanguage)?.name :
                f.names[0].name.toLowerCase().includes(searchTerm.toLowerCase()))
            setFilteredEvents(filteredEvents)
        } else {
            const filteredEvents = events
            setFilteredEvents(filteredEvents)
        }
    }, [searchTerm, events])

    React.useEffect(() => {
        if (searchTerm !== '') {
            searchRef.current?.focus()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const [confirmClone, setConfirmClone] = React.useState<boolean>(false);
    const [event, setEvent] = React.useState<EventType | null>(null);
    const [loading, setLoading] = React.useState<boolean>(false)

    const toast = useToast()

    const cloneEventHandler = () => {

        const eventData: AddEvent = {
            additionalImages: event?.additionalImages.map(img => toString(img)) || [],
            categories: event?.categories.map(cat => toString(cat.id)) || [],
            descriptions: event?.descriptions.map(d => {
                return {
                    description: toString(d.description),
                    language: toString(d.language)
                }
            }) || [],
            image: toString(event?.image) || '',
            names: event?.names.map(n => {
                return {
                    language: toString(n.language),
                    name: toString(n.name) + " (Clone)"
                }
            }) || [],
            offerLabel: toString(event?.offerLabel) !== '' ? toString(event?.offerLabel) : null,
            location: event?.location || '',
            fromDate: event?.fromDate || '',
            fromTime: event?.fromTime || '',
            toDate: event?.toDate || '',
            toTime: event?.toTime || '',
            price: event?.type === 'free' ? toNumber(event.price) : null,
            offerPrice: event?.type === 'free' && toNumber(event.offerPrice) > 0 ? toNumber(event.offerPrice) : null,
            tax: toNumber(event?.tax) || 0,
            type: event?.type || 'free',
            foodPreferences: event?.foodPreferences || [],
            volunteers: event?.volunteers || [],
            cultureVouchers: event?.cultureVouchers || [],
            eventRule: event?.eventRule || '',
            eventStatus: 'inactive',
            withoutRegistration: 'No',
            status: 'inactive',
            prices: event?.prices.map(p => {
                return {
                    price: event.type === 'free' ? 0 : toNumber(p.price),
                    size: toString(p.size),
                    offerPrice: toString(p.offerPrice) !== '' && event.type !== 'free' ? toNumber(p.offerPrice) : null
                }
            }) || []
        }

        setLoading(true)
        dispatch(addEvent(eventData)).then(() => {
            toast(t('Event is Cloned'))
            setConfirmClone(false)
        }).catch(text => {
            toast(text, ToastType.ERROR)
        }).finally(() => {
            setLoading(false)
        })
    }

    return (
        <>
            <AdminLayout>
                <div className='row mb-4'>
                    {confirmClone && <Confirmation
                        onClose={() => setConfirmClone(false)}
                        confirm={cloneEventHandler}
                        itemName={t(`Are you sure you want to clone this event?`)}
                    />}
                    <AdminPanelHeader
                        btnText={t('Add Event')}
                        title={t('Events')}
                        onBtnClick={adminContext?.onAddEvent}
                        isShowTableIcon={true}
                    />
                    <div className={style.searchInputContainer}>
                        <Search
                            onChange={searchTermChangeHandler}
                            value={searchTerm}
                            onFocus={() => {
                                userContext?.mainContentRef?.current?.scrollTo({
                                    behavior: 'smooth',
                                    top: 270
                                })
                            }}
                            innerRef={searchRef}
                        />
                    </div>
                    {(filteredEvents && filteredEvents.length > 0) ? (filteredEvents?.map((event, i) => {
                        return <Event
                            name={event.names?.length! > 1 ?
                                event.names.find(n => n.language === translationLanguage)?.name :
                                event.names[0].name}
                            img={event.image}
                            status={event.status}
                            previewStatus={event.previewStatus}
                            offerLabel={event.offerLabel}
                            cloneEvent={() => { setConfirmClone(true); setEvent(event); }}
                            id={event.id}
                            key={i}
                            index={i}
                        />
                    })) : <div className='d-flex justify-content-center fw-bold'>{t("Oops!! No such result found")}</div>}
                </div>
            </AdminLayout>
        </>
    )
}

const Event = (props: EventCardProps) => {
    const adminContext = React.useContext<AdminContextType | null>(AdminContext)

    return <div className={adminContext?.isCardStyle ? 'col-sm-6 col-md-4 col-lg-3 my-2 d-flex align-items-center justify-content-center' : ""}>
        <EventCard
            name={props.name}
            img={props.img}
            offerLabel={props.offerLabel}
            price={props.price}
            status={props.status}
            previewStatus={props.previewStatus}
            offerPrice={props.offerPrice}
            cloneEvent={props.cloneEvent}
            id={props.id}
            index={props.index}
        />
    </div>
}

export default Events