import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import FoodCard, { FoodCardProps } from '../../components/cards/FoodCard'
import ProductImportForm from '../../components/forms/ProductImportForm'
import AdminLayout from '../../components/sections/AdminLayout'
import AdminPanelHeader from '../../components/sections/AdminPanelHeader'
import Search from '../../components/utils/Search'
import { AdminContext, AdminContextType } from '../../context/AdminProvider'
import { UserContext, UserContextType } from '../../context/UserProvider'
import { TranslationLanguage, useT } from '../../i18n'
import { StoreState } from '../../redux/reducers'
import { AuthAdmin } from '../../redux/reducers/authAdmin.reducer'
import { Food as FoodsType } from '../../redux/reducers/food.reducer'
import style from '../../styles/components/shop-product-details.module.scss'
import { getMinOfferPrice, getMinPrice } from '../../utils'

// import { type } from '@testing-library/user-event/dist/types/utility'
export const ResponsiveFoods = () => {
    return <div className='col-sm-6 col-lg-4 col-xxl-3 mb-3'>
        <div className='d-flex align-items-center justify-content-center'>
            <Foods />
        </div>
    </div>
}

const Foods = () => {
    const authAdmin = useSelector<StoreState, AuthAdmin | null>(state => state.authAdmin)
    const translationLanguage = useSelector<StoreState, TranslationLanguage>(state => state.translationLanguage)

    const userContext = React.useContext<UserContextType | null>(UserContext)

    const adminContext = React.useContext<AdminContextType | null>(AdminContext)

    const foods = useSelector<StoreState, FoodsType[]>(state => state.foods)
    const [openImportModal, setOpenImportModal] = React.useState(false)

    const [filteredFoods, setFilteredFoods] = React.useState<FoodsType[]>(foods)
    const [width, setWidth] = useState(window.innerWidth);

    const searchRef = React.useRef<HTMLInputElement>(null)
    const [searchQuery, setSearchQuery] = useSearchParams()

    const searchTerm = searchQuery.get('search') || ''

    const searchTermChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setSearchQuery({ search: e.target.value })
    }

    React.useEffect(() => {
        if (searchTerm && searchTerm !== "") {
            const filteredFoods = foods.filter(f => f.names?.length! > 1 ?
                f.names.find(n => n.language === translationLanguage)?.name :
                f.names[0].name.toLowerCase().includes(searchTerm.toLowerCase()))
            const filteredByProductCode = foods.filter(f => f.productCode && f.productCode.toLowerCase().includes(searchTerm.toLowerCase()));
            const combinedFilteredFoods = [...filteredFoods, ...filteredByProductCode];
            setFilteredFoods(combinedFilteredFoods);
        }
        else {
            const filteredFoods = foods
            setFilteredFoods(filteredFoods)
        }
    }, [searchTerm, foods])

    React.useEffect(() => {
        if (searchTerm !== '') {
            searchRef.current?.focus()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const t = useT()

    const products = React.useMemo(() => {
        let items: string[][] = [];
        foods.map((food: any) => {
            const productName = food.names.filter((n: any) => n.language === 'english')[0] ? food.names.filter((n: any) => n.language === 'english')[0].name : '';
            const category = food.categories[0].names.filter((c: any) => c.language === 'english')[0] ? food.categories[0].names.filter((c: any) => c.language === 'english')[0].name : '';
            const description = food.descriptions?.length! > 1 ?
                food.descriptions.find((n: { language: string }) => n.language === translationLanguage)?.description :
                food.descriptions[0].description;
            const price = food.price ? food.price : null;
            const offerPrice = food.offerPrice ? food.offerPrice : null;
            return items.push([food.productCode, productName, food.image, description, category, food.manufacture, price, offerPrice, food.tax, food.stock])
        })
        console.log(items);

        return [
            ["PRODUCT_CODE", "PRODUCT_NAME", "PRODUCT_IMAGE", "DESCRIPTION", "CATEGORY", "MANUFACTURE", "PRICE", "OFFER_PRICE", "TAX", "STOCK"],
            ...items
        ];
    }, [foods])

    return <AdminLayout>
        {
            openImportModal && <ProductImportForm onClose={() => setOpenImportModal(false)} />
        }
        <div className='row mb-4'>
            {
                authAdmin?.category === "Food" &&
                <AdminPanelHeader
                    btnText={('Add Product')}
                    title={t('Products')}
                    onBtnClick={adminContext?.onAddFood}
                    isShowTableIcon={true}
                />
            }
            {
                authAdmin?.category === "Product" &&
                <AdminPanelHeader
                    btnText={t('Add Product')}
                    title={t('Products')}
                    exportBtnText={"Export Products"}
                    importBtnTxt={"Import Products"}
                    csvProducts={products}
                    onImportBtnClick={() => setOpenImportModal(true)}
                    onBtnClick={adminContext?.onAddFood}
                    isShowTableIcon={true}
                />
            }

            <div className={style.searchInputContainer}>
                <Search
                    onChange={searchTermChangeHandler}
                    value={searchTerm}
                    onFocus={() => {
                        userContext?.mainContentRef?.current?.scrollTo({
                            behavior: 'smooth',
                            top: 270
                        })
                    }}
                    innerRef={searchRef}
                />
            </div>

            {(filteredFoods && filteredFoods.length > 0) ? (filteredFoods?.map((food, i) => {
                const price = getMinPrice(food)
                const offerPrice = getMinOfferPrice(food)
                return <Food
                    name={food.names?.length! > 1 ?
                        food.names.find(n => n.language === translationLanguage)?.name :
                        food.names[0].name}
                    img={food.image}
                    offerLabel={food.offerLabel}
                    price={price}
                    status={food.status}
                    previewStatus={food.previewStatus}
                    offerPrice={offerPrice}
                    id={food.id}
                    key={i}
                    index={i}
                    currency={authAdmin?.shop.currency}
                />
            })) : <div className='d-flex justify-content-center fw-bold'>{t("Oops!! No such result found")}</div>}
        </div>
    </AdminLayout>
}

const Food = (props: FoodCardProps) => {
    const adminContext = React.useContext<AdminContextType | null>(AdminContext)

    return <div className={adminContext?.isCardStyle ? 'col-sm-6 col-md-4 col-lg-3 my-2 d-flex align-items-center justify-content-center' : ""}>
        <FoodCard
            name={props.name}
            img={props.img}
            offerLabel={props.offerLabel}
            price={props.price}
            status={props.status}
            previewStatus={props.previewStatus}
            offerPrice={props.offerPrice}
            id={props.id}
            index={props.index}
            currency={props.currency}
        />
    </div>
}

export default Foods