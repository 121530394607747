import React from 'react';
import { Carousel, Col, Row } from 'react-bootstrap';
import * as Feather from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { FacebookIcon, FacebookShareButton } from 'react-share';
import { api } from '../api';
import { useToast } from '../components/ToastProvider';
import Card from '../components/cards/Card';
import SectionWithTitle from '../components/sections/SectionWithTitle';
import UserLayout from '../components/sections/UserLayout';
import Veg from '../components/svgs/Veg';
import Button from '../components/utils/Button';
import Checkbox from '../components/utils/Checkbox';
import Radio from '../components/utils/Radio';
import TextArea from '../components/utils/TextArea';
import ShopProvider from '../context/ShopProvider';
import { UserContext, UserContextType } from '../context/UserProvider';
import { TranslationLanguage, useT } from '../i18n';
import { addToCart, updateCartItem } from '../redux/actions';
import { StoreState } from '../redux/reducers';
import { Cart, CartItem, CartItemExtraTopping } from '../redux/reducers/cart.reducer';
import { ExtraTopping } from '../redux/reducers/extraTopping.reducer';
import { Food, FoodPrice } from '../redux/reducers/food.reducer';
import { Shop } from '../redux/reducers/shop.reducer';
import { AppDispatch } from '../redux/store';
import style1 from '../styles/components/add-to-cart.module.scss';
import style from '../styles/pages/productview2.module.scss';
import { getFoodPrice, getOfferPrice, getPrice, toCurrencyFormat, toNumber } from '../utils';
// import './productview2.css';

interface AddToCartProps {
    onClose?: () => void
    cartId?: string | null
    food?: Food
}

const ProductViewV2 = (props: AddToCartProps) => {
    const t = useT()
    const { shopId, productId } = useParams()
    const userProvider = React.useContext<UserContextType | null>(UserContext)
    const shop = useSelector<StoreState, Shop | null>(state => state.shops.find(s => s.id === shopId) || null)
    const dispatch = useDispatch<AppDispatch>()
    const toast = useToast()
    const cartItem = useSelector<StoreState, CartItem | null>(state => state.cart.items.find(c => c.id === props.cartId) || null)
    const cart = useSelector<StoreState, Cart>(state => state.cart)
    const [foodPrices, setPrices] = React.useState<FoodPrice[] | null>(null)
    const [selectedPrice, setSelectedPrice] = React.useState<FoodPrice | null>(null)
    const [foodPrice, setFoodPrice] = React.useState<number>()
    const [comment, setComment] = React.useState<string>('')
    const [commentError, setCommentError] = React.useState<string>('')
    const [selectedExtraToppings, setSelectedExtraToppings] = React.useState<ExtraTopping[]>([])
    const [selectedSpices, setSelectedSpices] = React.useState<string[]>([])
    const [selectedAlternateToppings, setSelectedAlternateToppings] = React.useState<string[]>([])
    const [food, setFood] = React.useState<Food>()
    const [view, setView] = React.useState<string>(t('View All'))
    const [actualImage, setActualImage] = React.useState<string>(food?.image || '');
    const [width, setWidth] = React.useState(window.innerWidth);
    const translationLanguage = useSelector<StoreState, TranslationLanguage>(state => state.translationLanguage)
    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    React.useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const ViewAllHandler = () => {
        setView(prev => prev === t('View All') ? t('View Less') : t('View All'))
    }

    const goToProduct = (shopId: string | undefined, productId: string | undefined) => {
        if (shopId && productId) {
            const url = `${window.location.origin}/product/${shopId}/${productId}`;
            window.location.href = url;
        }
    }

    const goToPreviousPage = (shopId: string | undefined) => {
        if (shopId) {
            const url = `${window.location.origin}/shop/${shopId}`;
            window.location.href = url;
        }
    };

    const foodLists = React.useMemo(() => {
        let foodItems: Food[] = [];

        if (shopId) {
            api.get<{ data: Food[] }>(`foods/${shopId}`).then((res) => {
                if (res.status === 200 && res.data.data) {
                    const result = res.data.data
                    foodItems.push(...res.data.data)
                    let food = result?.find(f => f?.id === productId!)
                    setFood(food)
                }
            });
        }
        return foodItems
    }, [shopId])

    // const items = foodLists.filter(p => p.id !== productId).slice(0, 4)

    // const sameCategoryItems = foodLists.filter(c => c.categories[0].id === food?.categories[0].id && c.id !== productId).slice(0, 4)
    const items = foodLists
        .filter(p => p.id !== productId && p.status === "active")
        .slice(0, 4);

    const sameCategoryItems = foodLists
        .filter(c => c.categories[0].id === food?.categories[0].id && c.id !== productId && c.status === "active")
        .slice(0, 4);

    const categoryLength = sameCategoryItems.length < 4

    if (categoryLength) {
        const toAddLength = 4 - sameCategoryItems.length
        const otherCategoryProducts = items.filter(c => c.categories[0].id !== food?.categories[0].id)

        let extraProduct = otherCategoryProducts.slice(0, toAddLength)
        extraProduct.forEach(e => {
            sameCategoryItems.push(e)
        })
    }

    const sizeClickHandler = (foodPrice: FoodPrice) => {
        setSelectedPrice(foodPrice)
        setSelectedExtraToppings([])
    }

    const spiceClickHandler = (spiceId: string) => {
        setSelectedSpices(prev => {
            return selectedSpices.some(id => id === spiceId)
                ? prev.filter(p => p !== spiceId)
                : [
                    ...prev,
                    spiceId
                ]
        })
    }

    const toppingClickHandler = (toppingId: string) => {
        setSelectedAlternateToppings(prev => {
            return selectedAlternateToppings.some(id => id === toppingId)
                ? food?.alternateToppingsCount
                    ? prev.filter(p => p !== toppingId).slice(0, food?.alternateToppingsCount)
                    : prev
                : food?.alternateToppingsCount
                    ? [
                        toppingId,
                        ...prev
                    ].slice(0, food?.alternateToppingsCount)
                    : [
                        toppingId,
                        ...prev
                    ]
        })
    }

    const extraToppingClickHandler = (extraTopping: ExtraTopping) => {
        setSelectedExtraToppings(prev => {
            return selectedExtraToppings.some(e => e.id === extraTopping.id)
                ? prev.filter(p => p.id !== extraTopping.id)
                : [
                    ...prev,
                    extraTopping
                ]
        })
    }

    const addToCartClickHandler = async (food: Food) => {
        let obj = cart.items.find(c => c.foodId === food?.id)
        // if (obj) {

        if (food?.type === 'detail' && food?.status === 'active' && selectedPrice) {

            const index = food?.prices.findIndex(p => p.size === selectedPrice.size) ?? -1
            const price = getFoodPrice(food, index)

            const extraPrice = selectedExtraToppings.reduce((p, c) => {
                return index > -1 && c.prices[index] ? c.prices[index].price + p : p
            }, 0)

            const extraToppings = selectedExtraToppings.map<CartItemExtraTopping>(extraTopping => ({
                id: extraTopping.id,
                price: extraTopping.prices[index]?.price || 0
            }))

            if (props.cartId && cartItem) {
                dispatch(updateCartItem({
                    comment: (comment),
                    extraToppings: extraToppings,
                    foodId: food?.id,
                    image: food?.image,
                    names: food?.names.map(n => ({ language: (n.language), name: (n.name) })),
                    amount: toNumber(price + extraPrice),
                    size: selectedPrice.size,
                    spices: selectedSpices,
                    tax: food?.tax,
                    type: food?.type,
                    stock: food?.stock,
                    alternateToppings: selectedAlternateToppings,
                    status: food?.status === 'active' ? 'available' : 'unavailable',
                    category: food?.categories[0].id
                }, props.cartId))

                toast(t('Cart item updated'))
                props.onClose?.()
            } else {
                dispatch(addToCart({
                    comment: (comment),
                    extraToppings: extraToppings,
                    foodId: food?.id,
                    image: food?.image,
                    names: food?.names.map(n => ({ language: (n.language), name: (n.name) })),
                    amount: toNumber(price + extraPrice),
                    size: selectedPrice.size,
                    spices: selectedSpices,
                    tax: food?.tax,
                    type: food?.type,
                    stock: food?.stock,
                    alternateToppings: selectedAlternateToppings,
                    status: food?.status === 'active' ? 'available' : 'unavailable',
                    category: food?.categories[0].id
                }, food?.shopId))

                userProvider?.showAddedToCartModal?.(food?.names?.length! > 1 ?
                    food?.names.find(n => n.language === translationLanguage)?.name! :
                    food?.names[0].name!)
                props.onClose?.()
            }
        } else if (food?.type === 'simple' && food?.status === 'active') {
            const price = getPrice(food)

            if (props.cartId && cartItem) {
                dispatch(updateCartItem({
                    comment: (comment),
                    extraToppings: [],
                    foodId: food?.id,
                    image: food?.image,
                    names: food?.names.map(n => ({ language: (n.language), name: (n.name) })),
                    amount: toNumber(price),
                    size: null,
                    spices: [],
                    stock: food?.stock,
                    tax: food?.tax,
                    type: food?.type,
                    alternateToppings: [],
                    status: food?.status === 'active' ? 'available' : 'unavailable',
                    category: food?.categories[0].id
                }, props.cartId))

                toast(t('Cart item updated'))
                props.onClose?.()
            } else {
                dispatch(addToCart({
                    comment: (comment),
                    extraToppings: [],
                    foodId: food?.id,
                    image: food?.image,
                    names: food?.names.map(n => ({ language: (n.language), name: (n.name) })),
                    amount: toNumber(price),
                    size: null,
                    spices: [],
                    stock: food?.stock,
                    tax: food?.tax,
                    type: food?.type,
                    alternateToppings: [],
                    status: food?.status === 'active' ? 'available' : 'unavailable',
                    category: food?.categories[0].id
                }, food?.shopId))

                userProvider?.showAddedToCartModal?.(food?.names?.length! > 1 ?
                    food?.names.find(n => n.language === translationLanguage)?.name! :
                    food?.names[0].name!)
                props.onClose?.()
            }
        } else if (food?.type === "custom" && food?.status === 'active') {
            if (food.type === "custom" && comment === "") {
                setCommentError("Product Name Required")
            }
            else {
                if (props.cartId && cartItem) {
                    dispatch(updateCartItem({
                        comment: (comment),
                        extraToppings: [],
                        foodId: food?.id,
                        image: food?.image,
                        names: food?.names.map(n => ({ language: (n.language), name: (n.name) })),
                        amount: toNumber(0),
                        size: null,
                        spices: [],
                        stock: food?.stock,
                        tax: food?.tax,
                        type: food?.type,
                        alternateToppings: [],
                        status: food?.status === 'active' ? 'available' : 'unavailable',
                        category: food?.categories[0].id
                    }, props.cartId))

                    toast(t('Cart item updated'))
                    props.onClose?.()
                } else {
                    dispatch(addToCart({
                        comment: (comment),
                        extraToppings: [],
                        foodId: food?.id,
                        image: food?.image,
                        names: food?.names.map(n => ({ language: (n.language), name: (n.name) })),
                        amount: toNumber(0),
                        size: null,
                        spices: [],
                        stock: food?.stock,
                        tax: food?.tax,
                        type: food?.type,
                        alternateToppings: [],
                        status: food?.status === 'active' ? 'available' : 'unavailable',
                        category: food?.categories[0].id
                    }, food?.shopId))

                    userProvider?.showAddedToCartModal?.(food?.names?.length! > 1 ?
                        food?.names.find(n => n.language === translationLanguage)?.name! :
                        food?.names[0].name!)
                    props.onClose?.()
                }
            }
        }


        // }
        //  else { toast(t("Item already exists in the cart"), ToastType.ERROR) }
    }

    React.useEffect(() => {
        if (props.cartId && cartItem) {

            const extraToppings = food!.extraToppings.filter(et => cartItem.extraToppings.some(cet => cet.id === et.id))

            const spiceIds = food!.spices.filter(s => cartItem.spices.some(sid => sid === s.id)).map(s => s.id)

            const alternateToppingsIds = food!.alternateToppings.filter(t => cartItem.alternateToppings.some(atid => atid === t.id)).map(t => t.id)

            const priceIndex = food?.prices.findIndex(p => p.size === cartItem.size) ?? -1
            const selectedPrice = cartItem.size && food?.type === 'detail' && priceIndex > -1 ? food?.prices?.[priceIndex] : null
            setSelectedPrice(selectedPrice || null)
            setComment(cartItem.comment)
            setSelectedExtraToppings(extraToppings)

            setSelectedSpices(spiceIds)

            setSelectedAlternateToppings(alternateToppingsIds)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cartItem])

    React.useEffect(() => {
        if (food?.type === 'detail' && !cartItem) {
            const prices = [...food?.prices]
            prices.sort((a, b) => a.price - b.price)

            setPrices(prices)
            setSelectedPrice(prices[0])
        }
        else {
            const prices = [...food?.prices ?? []];
            prices.sort((a, b) => a.price - b.price)
            const priceIndex = food?.prices.findIndex(p => p.size === cartItem?.size) ?? -1
            const selectedPrice = cartItem?.size && food?.type === 'detail' && priceIndex > -1 ? food?.prices?.[priceIndex] : null
            setPrices(prices)
            setSelectedPrice(selectedPrice)
        }
    }, [cartItem, food])

    React.useEffect(() => {
        if (food?.type === 'detail') {
            const price = (selectedPrice?.offerPrice ? selectedPrice?.offerPrice : selectedPrice?.price) || 0
            const extraToppingsPrice = selectedExtraToppings.reduce((p, c) => p + (c.prices?.find(p => p.size === selectedPrice?.size)?.price || 0), 0)
            setFoodPrice(price + extraToppingsPrice)
        } else {
            setFoodPrice(getFoodPrice(food!))
        }
    }, [food, selectedExtraToppings, selectedPrice])

    React.useEffect(() => {
        if (food && food.image) {
            setActualImage(food.image)
        }
    }, [food])
    return (
        <UserLayout>
            {shopId && <ShopProvider shopId={shopId}>
                <div className="container p-3">
                    <div className='d-flex justify-content-between'>
                        <div className='hstack gap-2 mt-2'>
                            <div className={style.productName}>{food?.names?.length! > 1 ?
                                food?.names.find(n => n.language === translationLanguage)?.name :
                                food?.names[0].name}</div>
                            {shop?.category === "Food" && <Veg />}
                        </div>
                    </div>
                    <div className='d-flex justify-content-between'>
                        <div>
                            <FacebookShareButton
                                url={`${window.location.origin}/product/${shopId}/${productId}`}
                                className="facebookSharing"
                                hashtag={`#${food?.names?.length! > 1 ?
                                    food?.names.find(n => n.language === translationLanguage)?.name :
                                    food?.names[0].name}`}
                            >
                                <FacebookIcon size={34} />
                            </FacebookShareButton>
                        </div>
                        {width > 500 && <Button className={style.previousButton} onClick={() => goToPreviousPage(shopId)}>{t("Go to shop")}</Button>}
                        {width <= 500 && <div className="mt-2" onClick={() => goToPreviousPage(shopId)}><Feather.ArrowLeftCircle /></div>}
                    </div>
                    <hr />
                    <div className="">
                        {shop?.category === "Product" && <div><p className={`text-capitalize ${style.productName}`} style={{ color: '#8A8A8A', fontWeight: 600, fontSize: '13px' }}>{t("Product code")} : {food?.productCode}</p>
                        </div>}
                    </div>

                    <div className='row mb-3'>

                        <div className='col-lg-6 col-sm-12 col-xs-12 col-md-12'>
                            <Row>
                                {width > 500 && food?.additionalImages.length !== 0 &&
                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        {food?.additionalImages.length !== 0 ?

                                            <Row>
                                                {<Col lg={3} md={3} sm={3} xs={3}>
                                                    <div className='overflow-auto' style={{ height: "400px" }}>
                                                        <p className='mt-3'>
                                                            <img
                                                                src={food?.image}
                                                                style={{ cursor: "pointer" }}
                                                                onMouseOver={() => setActualImage(food!.image)}
                                                                alt=""
                                                                width="70px" height="70px" className="img-thumbnail"
                                                            /></p>
                                                        {food?.additionalImages.map((additionalimage, index) => (
                                                            <p key={index} className='mt-3'>
                                                                <img
                                                                    src={additionalimage !== undefined ? additionalimage : ""}
                                                                    onMouseOver={() => setActualImage(additionalimage)}
                                                                    style={{ cursor: "pointer" }}
                                                                    alt="" width="70px" height="70px" className="img-thumbnail" />
                                                            </p>

                                                        ))}
                                                    </div>
                                                </Col>}
                                                <Col lg={9} md={9} sm={9} xs={9}>

                                                    <img src={actualImage ? actualImage : ''} alt="icon" className={style.bigImage2} />

                                                </Col>
                                            </Row>
                                            :
                                            <img src={food?.image ? food?.image : ''} alt="icon" className={style.bigImage2} />
                                        }

                                    </Col>
                                }
                                {width > 500 && food?.additionalImages.length === 0 &&
                                    <img src={food?.image ? food?.image : ''} alt="icon" className={style.additionalImage} />
                                }
                                {width <= 500 &&
                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        {food?.additionalImages.length !== 0 ?
                                            <Carousel fade={true} slide={false}>

                                                <Carousel.Item key={0} interval={3000} >

                                                    <img
                                                        className="d-block w-100"
                                                        src={(food?.image && food?.image !== null) ? food?.image : ''}
                                                        alt={`${0} slide`}
                                                    />

                                                    <Carousel.Caption />

                                                </Carousel.Item>

                                                {food?.additionalImages.map((additionalImage, index) => (
                                                    <Carousel.Item key={index + 1} interval={3000} >

                                                        <img
                                                            className="d-block w-100"
                                                            src={(additionalImage && additionalImage !== null) ? additionalImage : ''}
                                                            alt={`${index + 1} slide`}
                                                        />

                                                        <Carousel.Caption />

                                                    </Carousel.Item>
                                                ))}

                                            </Carousel>
                                            :
                                            <img src={food?.image ? food?.image : ''} alt="icon" className={width <= 420 ? style.bigImage : style.bigImage2} />
                                        }

                                    </Col>
                                }
                            </Row>
                        </div>

                        {food?.type !== 'custom' && <div className='col-lg-3 col-sm-12 col-xs-12 col-md-12'>
                            {food?.type === 'detail' && <React.Fragment>
                                <div className={`${style1.editOrderContent} px-4`}>
                                    {food?.prices.length > 0 && <div className='mt-2'>
                                        <div className={`text-capitalize ${style.productName}`}>{t("Size")}:</div>
                                        {foodPrices?.map((p) => {
                                            const priceIndex = food?.prices.findIndex(fp => fp.size === p.size)
                                            const price = getPrice(food!, priceIndex)
                                            const offerPrice = getOfferPrice(food!, priceIndex)
                                            return <div className=''>
                                                <Radio
                                                    className='mb-1'
                                                    key={p.id}
                                                    onClick={() => sizeClickHandler(p)}
                                                    checked={p.size === selectedPrice?.size}
                                                >
                                                    <span className='text-capitalize'>{p.size}</span>
                                                </Radio>
                                                <div className='hstack gap-2'>
                                                    {offerPrice > 0
                                                        ? <React.Fragment>
                                                            <span>
                                                                {'('} {toCurrencyFormat(offerPrice, shop?.currency)}
                                                            </span>
                                                            <span>
                                                                <del className={style.offerPrice}>{toCurrencyFormat(price, shop?.currency)}</del>
                                                                {')'}
                                                            </span>

                                                        </React.Fragment>
                                                        : <span>{'('}{toCurrencyFormat(price, shop?.currency)}{')'}</span>}
                                                </div>
                                            </div>
                                        })}
                                    </div>}
                                    {food?.alternateToppings.length > 0 && food?.alternateToppingsCount && food?.alternateToppingsCount > 0 && <div className='mt-2'>
                                        <div className={`text-capitalize ${style.productName}`}>{t("Toppings")}:
                                            {/* <div>{`(Select up to ${food?.alternateToppingsCount})`}</div> */}
                                        </div>
                                        {food?.alternateToppings.map(alternate => {
                                            return <Checkbox
                                                className='mb-1'
                                                key={alternate.id}
                                                onClick={() => toppingClickHandler(alternate.id)}
                                                checked={selectedAlternateToppings.some(id => id === alternate.id)}
                                            >{alternate?.names?.length! > 1 ?
                                                alternate?.names.find(n => n.language === translationLanguage)?.name :
                                                alternate?.names[0].name}</Checkbox>
                                        })}
                                    </div>}
                                    {food?.extraToppings.length > 0 && food?.extraToppings.some(e => e.prices.some(p => p.size === selectedPrice?.size)) && <div className='mb-1'>
                                        <div className={`text-capitalize ${style.productName}`}>{t("Extra toppings")}:</div>
                                        {food?.extraToppings.map(extra => {
                                            const price = extra.prices.find(e => e.size === selectedPrice?.size)

                                            return <Checkbox
                                                className='mb-1'
                                                key={extra.id}
                                                onClick={() => extraToppingClickHandler(extra)}
                                                checked={selectedExtraToppings.some(e => e.id === extra.id)}
                                            >{`${extra?.names?.length! > 1 ?
                                                extra?.names.find(n => n.language === translationLanguage)?.name! :
                                                extra?.names[0].name!} (${toCurrencyFormat(price?.price, shop?.currency)})`}</Checkbox>
                                        })}
                                    </div>}
                                    {food?.spices.length > 0 && <div className='mt-2'>
                                        <div className={`text-capitalize ${style.productName}`}>{t("Spices")}:</div>
                                        {food?.spices.map(spice => {
                                            return <Checkbox
                                                className='mb-1'
                                                key={spice.id}
                                                onClick={() => spiceClickHandler(spice.id)}
                                                checked={selectedSpices.some(id => id === spice.id)}
                                            >{spice?.names?.length! > 1 ?
                                                spice?.names.find(n => n.language === translationLanguage)?.name! :
                                                spice?.names[0].name!}</Checkbox>
                                        })}
                                    </div>}
                                </div>
                            </React.Fragment>}
                            {food?.type === "simple" && <p className={`${style.description}`} dangerouslySetInnerHTML={{
                                __html: (food?.descriptions?.length! > 1 ?
                                    food?.descriptions.find(n => n.language === translationLanguage)?.description! :
                                    food?.descriptions[0].description!) || ''
                            }}>
                            </p>}
                        </div>}

                        {food?.type === 'custom' && (
                            <div className='col-lg-4 col-sm-12 col-xs-12 col-md-12 vstack gap-1'>
                                <SectionWithTitle title={t('Product Names')}>
                                    <TextArea
                                        placeholder={t('Product Names')}
                                        value={comment.split('\n').map((line, index) => `${index + 1}. ${line}`).join('\n')}
                                        onChange={(e) => {
                                            const newValue = e.target.value.split('\n').map(line => line.replace(/^\d+\.\s*/, '')).join('\n');
                                            setComment(newValue);
                                            setCommentError('');
                                        }}
                                        errorText={commentError}
                                        style={{ height: '200px' }}
                                    />
                                </SectionWithTitle>
                            </div>
                        )}

                        {food?.type !== 'custom' && <div className={`col-lg-3 col-sm-12 col-xs-12 col-md-12 mt-3`}>
                            <div className={style.container}>
                                {food?.type === 'detail'
                                    ? (selectedPrice?.offerPrice
                                        ? <div className='hstack gap-1 justify-content-between'>
                                            <div className={`${style.cartOfferPrice}`} >{toCurrencyFormat(foodPrice, shop?.currency)}</div>

                                            <del className={`${style.cartPrice}`}> {food?.type === "detail" ? selectedPrice?.price.toFixed(2) + " €" : food?.price?.toFixed(2) + " €"}</del>

                                            {food && food?.type === "detail" ? <div className={style.discount} >
                                                {(((selectedPrice?.price || 0) - foodPrice!) / (selectedPrice?.price || 0) * 100).toFixed(0)}%
                                            </div> : <div className={style.discount} >
                                                {(((food?.price || 0) - foodPrice!) / (food?.price || 0) * 100).toFixed(0)}%
                                            </div>}
                                        </div>
                                        : <div className={`${style.cartOfferPrice} ms-5 ps-4`} >{selectedPrice?.price?.toFixed(2) + " €"}</div>
                                    )
                                    : (food?.offerPrice
                                        ? <div className='hstack gap-1 justify-content-between'>
                                            <div className={`${style.cartOfferPrice}`} >{toCurrencyFormat(foodPrice, shop?.currency)}</div>

                                            <del className={`${style.cartPrice}`}> {food?.price?.toFixed(2) + " €"}</del>

                                            {food && <div className={style.discount} >
                                                {(((food?.price || 0) - foodPrice!) / (food?.price || 0) * 100).toFixed(0)}%
                                            </div>}
                                        </div>
                                        : <div className={`${style.cartOfferPrice} ms-5 ps-4`} >{food?.price?.toFixed(2) + " €"}</div>
                                    )
                                }


                                <Button className={`${style.cartButton}`} size='sm' onClick={() => addToCartClickHandler(food!)} disabled={shop?.category === "Product" && !food?.stock ? true : false}>{props.cartId && cartItem ? t('Update') : t('Add to cart')}</Button>
                            </div>


                            {shop?.category === "Product" && <p className={style.stock} >{food?.stock && food?.stock <= 10 ? `Only ${food?.stock} Left!! Hurry ` : !food?.stock ? 'Stock not available' : ''} </p>}
                        </div>}

                        {food?.type === 'custom' && <div className='col-lg-2 col-sm-12 col-xs-12 col-md-12'>
                            <Button className={`${style.cartButton}`} size='sm' onClick={() => addToCartClickHandler(food!)}>{props.cartId && cartItem ? t('Update') : t('Add to cart')}</Button>
                        </div>}

                    </div>

                    {food?.type === "detail" && <p className={`my-1 ${style1.description}`} dangerouslySetInnerHTML={{
                        __html: (food?.descriptions?.length! > 1 ?
                            food?.descriptions.find(n => n.language === translationLanguage)?.description! :
                            food?.descriptions[0].description!) || ''
                    }}>
                    </p>}
                </div>

                <div >
                    <div className='row'>
                        <div className='hstack justify-content-between mb-2'>
                            <div className={style.productName}>{t("Recommended Products")}</div>
                            {/* {items && items?.length > 0 && <div className={style2.viewAll}>
                                <div className={style2.label} onClick={ViewAllHandler}>{view}
                                </div>
                                <ChevronRightDark />
                            </div>} */}
                        </div>
                    </div>
                    {/* <div className='row'>
                        {view === t('View All') && items.map((oneproductdata, index) => (
                            <div className='col-sm-6 col-md-6 col-lg-3 hstack gap-1'>
                                <div className="row border rounded m-2">
                                    <div className="col sm-12">
                                        <div className='w-100 p-1'>
                                            <div>
                                                <img alt="img" src={oneproductdata?.image} className={style.blogImageSuper} onClick={() => goToProduct(shopId, oneproductdata?.id)} />
                                            </div>
                                            <div className={`text-capitalize ${style.name}`}>
                                                {oneproductdata?.names[0].name}
                                            </div>
                                            <div className="small_product_right_price">
                                                <h3>{oneproductdata?.type === "simple" ? (oneproductdata?.offerPrice || oneproductdata?.price) + " €" : (oneproductdata?.prices[0].offerPrice || oneproductdata?.prices[0].price) + " €"}</h3>
                                                <p className="small_product_right_price_full">{oneproductdata?.type === "simple" ? oneproductdata?.price + " €" : oneproductdata?.prices[0].price + " €"}</p>

                                            </div>
                                            <Button className={style.addToCartButton} onClick={() => goToProduct(shopId, oneproductdata?.id)}>{t("View Product")}</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                        {view === t('View Less') && foodLists.map((oneproductdata, index) => (
                            <div className='col-sm-6 col-md-6 col-lg-3 hstack gap-1 justify-content-center'>
                                <div className="row border rounded mb-3">
                                    <div className="col sm-12">
                                        <div className='w-100 p-1'>
                                            <div>
                                                <img alt="img" src={oneproductdata?.image} className={`${style.blogImageSuper}`} onClick={() => goToProduct(shopId, oneproductdata?.id)} />
                                            </div>
                                            <div className={`text-capitalize ${style.productName}`}>
                                                {oneproductdata?.names[0].name}
                                            </div>
                                            <div className="small_product_right_price">
                                                <h3>{oneproductdata?.type === "simple" ? (oneproductdata?.offerPrice || oneproductdata?.price) + " €" : (oneproductdata?.prices[0].offerPrice || oneproductdata?.prices[0].price) + " €"}</h3>
                                                <p className="small_product_right_price_full">{oneproductdata?.type === "simple" ? oneproductdata?.price + " €" : oneproductdata?.prices[0].price + " €"}</p>

                                            </div>
                                            <Button className={style.addToCartButton} onClick={() => goToProduct(shopId, oneproductdata?.id)}>{t("View Product")}</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div> */}
                    <div className='row'>
                        {sameCategoryItems.length > 0
                            ? sameCategoryItems
                                .filter(p => p.id !== productId && p.status === "active")
                                .map((oneproductdata, index) => (
                                    <div key={index} className='col-sm-6 col-lg-3 col-xxl-3 mb-2'>
                                        <Card
                                            img={oneproductdata.image}
                                            name={oneproductdata?.names?.length! > 1 ?
                                                oneproductdata?.names.find(n => n.language === translationLanguage)?.name :
                                                oneproductdata?.names[0].name}
                                            price={oneproductdata.type === "simple" ? oneproductdata.price : (oneproductdata.type === "detail" ? oneproductdata.prices[0].price : 0)}
                                            offerPrice={oneproductdata.type === "simple" ? oneproductdata.offerPrice : (oneproductdata.type === "detail" ? oneproductdata.prices[0].offerPrice : 0)}
                                            buttonText={t('View Product')}
                                            onClick={() => goToProduct(shopId, oneproductdata.id)}
                                            currency={shop?.currency}
                                        />
                                    </div>
                                ))
                            : items
                                .filter(oneproductdata => oneproductdata.status === "active")
                                .map((oneproductdata, index) => (
                                    <div key={index} className='col-sm-6 col-lg-3 col-xxl-3 mb-2'>
                                        <Card
                                            img={oneproductdata.image}
                                            name={oneproductdata?.names?.length! > 1 ?
                                                oneproductdata?.names.find(n => n.language === translationLanguage)?.name :
                                                oneproductdata?.names[0].name}
                                            price={oneproductdata.type === "simple" ? oneproductdata.price : (oneproductdata.type === "detail" ? oneproductdata.prices[0].price : 0)}
                                            offerPrice={oneproductdata.type === "simple" ? oneproductdata.offerPrice : (oneproductdata.type === "detail" ? oneproductdata.prices[0].offerPrice : 0)}
                                            buttonText={t('View Product')}
                                            onClick={() => goToProduct(shopId, oneproductdata.id)}
                                            currency={shop?.currency}
                                        />
                                    </div>
                                ))
                        }
                    </div>
                </div>
            </ShopProvider>}

        </UserLayout>
    );
}

export default ProductViewV2;