import { BLACK, HEX_COLOR, MONTH_NAMES, PASSWORD, PHONE_NUMBER } from '../config'
import { Appointment } from '../redux/reducers/appointment.reducer'
import { Event } from '../redux/reducers/event.reducer'
import { Food, FoodPrice, FoodType } from '../redux/reducers/food.reducer'
import { Timing } from '../redux/reducers/shop.reducer'

export const testNumber = (number: string) => PHONE_NUMBER.test(number)
export const testPassword = (password: string) => PASSWORD.test(password)
export const isValidHexColor = (color: string) => HEX_COLOR.test(color)

export const getValidColor = (color?: string) => {
    return color && isValidHexColor(color) ? color.toLowerCase() : BLACK
}

export const toString = (value: any) => {
    if (typeof value === 'string') {
        return value.trim()
    }

    if (typeof value === 'bigint' || typeof value === 'number') {
        return String(value).trim()
    }

    return ''
}

export const toNumber = (value: any) => {
    if (typeof value === 'number') {
        if (isNaN(value)) {
            return 0
        }

        return value
    }

    const number = Number(value)

    if (isNaN(number)) {
        return 0
    }

    return number
}

// export const toCurrencyFormat = (value?: number | null, currency?: string) => {
//     // const currency = Intl.NumberFormat('en-FI', {
//     //     style: 'currency',
//     //     currency: 'EUR',
//     //     maximumFractionDigits: 2
//     // }).format(value || 0)

//     // return currency.substring(1) + ' ' + currency.charAt(0)
//     const cur = currency === 'EUR' ? ' €' : currency === 'SEK' ? ' Kr' : ' €' || ' €'
//     return (value?.toFixed(2) + cur).toString();
// }
export const toCurrencyFormat = (value?: number | null, currency?: string) => {
    const cur = currency === 'EUR' ? ' €' : currency === 'SEK' ? ' Kr' : ' €';
    return (value?.toFixed(2) + cur).toString();
};

interface GetFoodPriceItem {
    type?: FoodType
    prices?: FoodPrice[]
    price?: number | null
    offerPrice?: number | null
}

export const getFoodPrice = (food?: GetFoodPriceItem, index?: number) => {
    if (food) {
        if (food.type === 'detail') {
            if (food.prices && food.prices.length > 0) {
                const price = food.prices.at(index || 0)
                if (price?.offerPrice && price.offerPrice) {
                    return price.offerPrice
                }

                if (price?.price) {
                    return price.price
                }
            }
        }

        if (food.type === 'simple') {
            if (food.offerPrice) {
                return food.offerPrice
            }

            if (food.price) {
                return food.price
            }
        }

    }

    return 0
}

export const getPrice = (food?: Food, index?: number) => {
    if (food) {
        if (food.type === 'detail') {
            if (food.prices && food.prices.length > 0) {
                const price = food.prices.at(index || 0)
                if (price?.price) {
                    return price.price
                }
            }
        }

        if (food.type === 'simple' && food.price) {
            return food.price
        }

    }

    return 0
}

export const getAppointmentMinPrice = (appointment?: Appointment, index?: number) => {
    if (appointment) {
        if (appointment.type === 'detail') {
            const price: any = appointment.prices.map((p) => p.price);
            return Math.min(...price.filter((p: number) => p !== 0));
        }

        if (appointment.type === 'simple' && appointment.price) {
            return appointment.price
        }

    }

    return 0
}

export const getAppointmentMinOfferPrice = (appointment?: Appointment, index?: number) => {
    if (appointment) {
        if (appointment.type === 'detail') {
            const price: any = appointment.prices.map((p) => p.offerPrice);
            return Math.min(...price.filter((p: number) => p !== 0));
        }

        if (appointment.type === 'simple' && appointment.offerPrice) {
            return appointment.offerPrice
        }

    }

    return 0
}

export const getMinPrice = (food?: Food, index?: number) => {
    if (food) {
        if (food.type === 'detail') {
            const price: any = food.prices.map((p) => p.price);
            return Math.min(...price.filter((p: number) => p !== 0));
        }

        if (food.type === 'simple' && food.price) {
            return food.price
        }

    }

    return 0
}

export const getMinOfferPrice = (food?: Food, index?: number) => {
    if (food) {
        if (food.type === 'detail') {
            const price: any = food.prices.map((p) => p.offerPrice);
            return Math.min(...price.filter((p: number) => p !== 0));
        }

        if (food.type === 'simple' && food.offerPrice) {
            return food.offerPrice
        }

    }

    return 0
}

export const getEventCheckoutPrice = (price?: { offerPrice: number, price: number }) => {
    if (price) {
        if (price.offerPrice && price.offerPrice > 0) {
            return price.offerPrice
        }
        else if (price?.price && price?.price > 0) {
            return price.price
        }

    }
    return 0
}
export const getAppointmentCheckoutPrice = (price?: { offerPrice: number, price: number }) => {
    if (price) {
        if (price.offerPrice && price.offerPrice > 0) {
            return price.offerPrice
        }
        else if (price?.price && price?.price > 0) {
            return price.price
        }

    }
    return 0
}

export const getEventPrice = (event?: Event, index?: number) => {
    if (event) {
        if (event.prices && event.prices.length > 0) {
            const price = event.prices.at(index || 0)
            if (price?.price) {
                return price.price
            }
        }
    }

    return 0
}
export const getAppointmentPrice = (appointment?: Appointment, index?: number) => {
    if (appointment) {
        if (appointment.prices && appointment.prices.length > 0) {
            const price = appointment.prices.at(index || 0)
            if (price?.price) {
                return price.price
            }
        }
    }

    return 0
}
export const getOfferPrice = (food?: Food, index?: number) => {
    if (food) {
        if (food.type === 'detail') {
            if (food.prices && food.prices.length > 0) {
                const price = food.prices.at(index || 0)
                if (price?.offerPrice && price.offerPrice) {
                    return price.offerPrice
                }
            }
        }

        if (food.type === 'simple' && food.offerPrice) {
            return food.offerPrice
        }

    }
    return 0
}
export const getAppointmentOfferPrice = (appointment?: Appointment, index?: number) => {

    if (appointment) {
        if (appointment.prices && appointment.prices.length > 0) {
            const price = appointment.prices.at(index || 0)
            if (price?.offerPrice && price.offerPrice) {
                return price.offerPrice
            }
        }

    }
    return 0
}
export const getEventOfferPrice = (event?: Event, index?: number) => {

    if (event) {
        if (event.prices && event.prices.length > 0) {
            const price = event.prices.at(index || 0)
            if (price?.offerPrice && price.offerPrice) {
                return price.offerPrice
            }
        }

    }
    return 0
}


interface Price {
    price?: number | null
}

export const getTotalPrice = <A extends Price>(aoo: A[]) => {
    return Array.isArray(aoo) && aoo.length > 0 ? aoo.reduce((p, c) => p + toNumber(c.price), 0) : 0
}

export const to12Hour = (time24Hour?: string) => {
    const splitted = time24Hour?.split(':')
    if (splitted && splitted.length === 2) {
        const hour = Number(splitted[0])
        const minute = Number(splitted[1])

        if (hour >= 0 && hour <= 23 && minute >= 0 && minute <= 59) {
            const hh = String(hour > 12 ? hour % 12 : hour === 0 ? 12 : hour).padStart(2, '0')
            const mm = minute.toString().padStart(2, '0')
            const me = hour >= 12 ? 'PM' : 'AM'
            return `${hh}:${mm} ${me}`
        }
        return null
    }
    return null
}

export const to24Hour = (time12Hour?: string) => {
    const splitted = time12Hour?.split(/[\s:]+/)
    if (splitted && splitted.length === 3) {
        const hour = Number(splitted[0])
        const minute = Number(splitted[1])
        const meridian = splitted[2]

        if (hour >= 1 && hour <= 12 && minute >= 0 && minute <= 59 && (meridian === 'AM' || meridian === 'PM')) {
            const hh = String(meridian === 'AM' ? hour === 12 ? 0 : hour : hour === 12 ? hour : hour + 12).padStart(2, '0')
            const mm = minute.toString().padStart(2, '0')

            return `${hh}:${mm}`
        }
        return null
    }
    return null
}

export const invertColor = (hex: string) => {
    if (hex.indexOf('#') === 0) {
        hex = hex.slice(1)
    }

    if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]
    }

    if (hex.length !== 6) {
        throw new Error('Invalid HEX color.')
    }

    let r = parseInt(hex.slice(0, 2), 16),
        g = parseInt(hex.slice(2, 4), 16),
        b = parseInt(hex.slice(4, 6), 16)

    let rs = (255 - r).toString(16),
        gs = (255 - g).toString(16),
        bs = (255 - b).toString(16)

    return '#' + padZero(rs) + padZero(gs) + padZero(bs)
}

const padZero = (str: string, len?: number) => {
    len = len || 2
    var zeros = new Array(len).join('0')
    return (zeros + str).slice(-len)
}

export const timingDeepCopy = (timing: Timing): Timing => {
    return {
        sunday: {
            opening: timing.sunday.opening,
            closing: timing.sunday.closing
        },
        monday: {
            opening: timing.monday.opening,
            closing: timing.monday.closing
        },
        tuesday: {
            opening: timing.tuesday.opening,
            closing: timing.tuesday.closing
        },
        wednesday: {
            opening: timing.wednesday.opening,
            closing: timing.wednesday.closing
        },
        thursday: {
            opening: timing.thursday.opening,
            closing: timing.thursday.closing
        },
        friday: {
            opening: timing.friday.opening,
            closing: timing.friday.closing
        },
        saturday: {
            opening: timing.saturday.opening,
            closing: timing.saturday.closing
        }
    }
}


export function isPositiveInteger(includeZero: boolean = false) {
    return function (number?: any) {
        if (typeof number !== 'number') return false
        if (isNaN(number)) return false
        if (includeZero) {
            if (Math.sign(number) === -1) return false
        } else {
            if (Math.sign(number) === -1 || Math.sign(number) === 0) return false
        }

        return parseInt(String(number), 10) === number
    }
}

export function isPositiveFloat(includeZero: boolean = false) {
    return function (number?: any) {
        if (typeof number !== 'number') return false
        if (isNaN(number)) return false
        if (includeZero) {
            if (Math.sign(number) === -1) return false
        } else {
            if (Math.sign(number) === -1 || Math.sign(number) === 0) return false
        }

        return parseFloat(String(number)) === number
    }
}

export const isValidTime = (time?: string | null) => {
    if (!time) return false

    const timeArr = String(time).split(':')

    if (timeArr.length !== 2) return false

    const hour = Number(timeArr[0])
    const minute = Number(timeArr[1])

    return hour >= 0 && hour <= 23 && minute >= 0 && minute <= 59
}

export const checkIfDuplicates = <T>(array?: T[] | null) => {
    if (Array.isArray(array)) {
        for (let i = 0; i < array.length; i++) {
            for (let j = i + 1; j < array.length; j++) {
                if (array[i] === array[j]) {
                    return false
                }
            }
        }
        return true
    }
    return false
}

const to2Digit = (value: number) => {
    return value.toString().padStart(2, '0')
}

export const date = (format: string, value?: string) => {
    const dateObj = new Date(value || Date.now())
    const d = dateObj.getDate()
    const m = dateObj.getMonth() + 1
    const month = MONTH_NAMES[dateObj.getMonth()]
    const y = dateObj.getFullYear()
    const h = dateObj.getHours()
    const i = dateObj.getMinutes()
    const s = dateObj.getSeconds()

    const time12Hour = to12Hour(to2Digit(h) + ':' + to2Digit(m))?.split(':')
    const H = time12Hour?.[0]
    const MN = time12Hour?.[1].split(' ')[1]

    return format
        .replace('d', toString(d))
        .replace('D', to2Digit(d))
        .replace('MON', month)
        .replace('mn', toString(m))
        .replace('Mn', to2Digit(m))
        .replace('Y', toString(y))
        .replace('h', toString(h))
        .replace('H', toString(H))
        .replace('i', to2Digit(i))
        .replace('s', to2Digit(s))
        .replace('Z', toString(MN))
}


export const formatDate = (date: any) => {
    var dd = date.getDate();
    var mm = date.getMonth() + 1;
    var yyyy = date.getFullYear();
    if (dd < 10) { dd = '0' + dd }
    if (mm < 10) { mm = '0' + mm }
    date = yyyy + "-" + mm + "-" + dd;
    return date
}

export const initDate = (d?: number | undefined) => {
    const today = new Date();
    today.setDate(d ? today.getDate() - d : today.getDate());
    return formatDate(today)
}

export const currentDate = (endDate: string, d?: number) => {
    const today = new Date(endDate);
    today.setDate(d ? today.getDate() + d : today.getDate());
    return formatDate(today)
}

export const datesArray = (startDate: Date | string, endDate: Date | string) => {

    const initDate = new Date(startDate);
    const closeDate = new Date(endDate)
    const dates = [];
    while (initDate <= closeDate) {
        dates.push(Intl.DateTimeFormat('sv-SE').format(new Date(initDate)))
        initDate.setDate(initDate.getDate() + 1);
    }
    return dates
}

export const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];

export const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export const countryCode = [{ value: '+358', title: 'Finland +358' }, { value: '+46', title: 'Sweden +46' }]

export const numberFormat = (value: any) => {
    let formatter = Intl.NumberFormat('en', { notation: 'compact' });
    return formatter.format(value);
}

export const timeAMPM = (value: string) => {
    const timeArray = value.split(':');
    const time = toNumber(timeArray[0]);
    let timePeriod = 'AM';
    if (time >= 12)
        timePeriod = 'PM';

    return `${timeArray[0]} : ${timeArray[1]} ${timePeriod}`;

}
export const times = [
    { value: '7 : 00', text: '07 : 00' },
    { value: '7 : 30', text: '07 : 30' },
    { value: '8 : 00', text: '08 : 00' },
    { value: '8 : 30', text: '08 : 30' },
    { value: '9 : 00', text: '09 : 00' },
    { value: '9 : 30', text: '09 : 30' },
    { value: '10 : 00', text: '10 : 00' },
    { value: '10 : 30', text: '10 : 30' },
    { value: '11 : 00', text: '11 : 00' },
    { value: '11 : 30', text: '11 : 30' },
    { value: '12 : 00', text: '12 : 00' },
    { value: '12 : 30', text: '12 : 30' },
    { value: '1 : 00', text: '01 : 00' },
    { value: '1 : 30', text: '01 : 30' },
    { value: '2 : 00', text: '02 : 00' },
    { value: '2 : 30', text: '02 : 30' },
    { value: '3 : 00', text: '03 : 00' },
    { value: '3 : 30', text: '03 : 30' },
    { value: '4 : 00', text: '04 : 00' },
    { value: '4 : 30', text: '04 : 30' },
    { value: '5 : 00', text: '05 : 00' },
    { value: '5 : 30', text: '05 : 30' },
    { value: '6 : 00', text: '06 : 00' },
    { value: '6 : 30', text: '06 : 30' },
    { value: '7 : 00', text: '07 : 00' },
    { value: '7 : 30', text: '07 : 30' },
    { value: '8 : 00', text: '08 : 00' },
    { value: '8 : 30', text: '08 : 30' }
]