import { PersonTypes } from '../../context/CheckoutProvider'
import { Action, BookingActionTypes } from '../actions'
import { Category } from './category.reducer'
import { OtherPayments } from './shop.reducer'


export type PaymentModeBooking = 'card' | 'upi' | 'paytrail' | 'bank-transfer' | 'free'
export type PaymentStatus = 'pending' | 'paid' | 'failed'
export type BookingStatus = 'booked' | 'attended' | 'cancelled' | 'failed'


export interface BookingDetails {
    id: string
    title: string
    size: string | null
    price: number
    tax: number | null
    status: boolean | null
    foodPreference: string | undefined
    category: Category[]
    createdAt: string
    updatedAt: string
}

export interface User {
    firstName: string
    lastName: string
    email: string
    phoneNumber: string
    address: {
        addressLine1: string
        addressLine2: string
        city: string
        pincode: number
        state: string
        landmark: string | null
    }
}

export interface Booking {
    id: string
    bookingNumber: string
    bookingDate: string
    eventId: { id: string, names: { id: string, language: string, name: string, updatedAt: string, createdAt: string }[], type: string }
    shopId: { id: string, otherPayments: OtherPayments[] }
    user: User
    userId: string
    totalAmount: number
    totalTax: number | null
    billAmount: number
    paymentMode: PaymentModeBooking
    paymentStatus: PaymentStatus
    paidAt: string | null
    bookingStatus: BookingStatus
    bookingDetails: BookingDetails[]
    personTypes?: PersonTypes[]
    cultureVoucher: string
    resendEmailCount: number
    volunteers: string[]
    createdAt: string
    updatedAt: string
    transactionImage?: string
    voucherTransactionImage?: string
}

const initialState: Booking[] = []

const bookingReducer = (state: Booking[] = initialState, action: Action): Booking[] => {
    switch (action.type) {
        case BookingActionTypes.ADD_BOOKING: {
            return [
                ...state,
                action.data
            ]
        }

        case BookingActionTypes.FETCH_BOOKINGS: {
            return action.data
        }

        case BookingActionTypes.REPLACE_BOOKING: {
            const newState = [...state]
            const index = newState.findIndex(s => s.id === action.data.id)

            if (index > -1) {
                newState[index] = { ...action.data }
            }

            return newState
        }

        default: {
            return state
        }
    }
}

export default bookingReducer