import React, { useEffect, useState } from 'react';
import { Carousel, Col, Row } from 'react-bootstrap';
import * as Feather from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { FacebookIcon, FacebookShareButton } from 'react-share';
import { api } from '../api';
import { useToast } from '../components/ToastProvider';
import Card from '../components/cards/Card';
import UserLayout from '../components/sections/UserLayout';
import Calender from '../components/svgs/Calender';
import Clock from '../components/svgs/Clock';
import LocationOn from '../components/svgs/LocationOn';
import Button from '../components/utils/Button';
import ShopProvider from '../context/ShopProvider';
import { UserContext, UserContextType } from '../context/UserProvider';
import { TranslationLanguage, useT } from '../i18n';
import { eventAddToCart } from '../redux/actions/eventCart.actions';
import { StoreState } from '../redux/reducers';
import { Cart, CartItem } from '../redux/reducers/cart.reducer';
import { Event } from '../redux/reducers/event.reducer';
import { Shop } from '../redux/reducers/shop.reducer';
import { AppDispatch } from '../redux/store';
import style1 from '../styles/components/add-to-cart.module.scss';
import style from '../styles/pages/productview2.module.scss';
import { dayNames, getEventOfferPrice, getEventPrice, monthNames, to12Hour, toCurrencyFormat } from '../utils';
// import './productview2.css';

interface AddToCartProps {
    onClose?: () => void
    cartId?: string | null
    event?: any
}

const ProductViewEvent = (props: AddToCartProps) => {
    const t = useT()
    const { shopId, eventId } = useParams()
    const navigate = useNavigate();
    const userProvider = React.useContext<UserContextType | null>(UserContext)
    const shop = useSelector<StoreState, Shop | null>(state => state.shops.find(s => s.id === shopId) || null)
    const dispatch = useDispatch<AppDispatch>()
    const toast = useToast()
    const cartItem = useSelector<StoreState, CartItem | null>(state => state.cart.items.find(c => c.id === props.cartId) || null)
    const cart = useSelector<StoreState, Cart>(state => state.cart)
    // const [eventPrices, setPrices] = React.useState<EventPrice[] | null>(null)
    // const [selectedPrice, setSelectedPrice] = React.useState<EventPrice | null>(null)
    // const [eventPrice, setEventPrice] = React.useState<number>()
    // const [comment, setComment] = React.useState<string>('')
    const translationLanguage = useSelector<StoreState, TranslationLanguage>(state => state.translationLanguage)
    const [event, setEvent] = React.useState<Event>()
    const [eventDate, setEventDate] = useState<string>("")

    const [view, setView] = React.useState<string>(t('View All'))
    const [actualImage, setActualImage] = React.useState<string>(props.event?.image || '');
    const [width, setWidth] = React.useState(window.innerWidth);

    function gmapHandler() {
        window.open("https://www.google.com/maps/place/" + event?.location, '_blank')
    }

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    React.useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    useEffect(() => {
        const fromDate = event?.fromDate;
        const from = new Date(fromDate ? fromDate : '');
        const date = dayNames[from.getDay()] + ', ' + from.getDate() + 'th ' + monthNames[from.getMonth()] + ', ' + from.getFullYear();
        setEventDate(date)
    }, [event])

    const ViewAllHandler = () => {
        setView(prev => prev === t('View All') ? t('View Less') : t('View All'))
    }

    const goToPreviousPage = (shopId: string | undefined) => {
        console.log("Hi")
        if (shopId) {
            const url = `${window.location.origin}/shop/${shopId}`;
            window.location.href = url;
        }
    };

    const goToProduct = (shopId: string | undefined, eventId: string | undefined) => {
        if (shopId && eventId) {
            const url = `${window.location.origin}/event/${shopId}/${eventId}`;
            window.location.href = url;
        }
    }

    const eventLists = React.useMemo(() => {
        let eventItems: Event[] = [];

        if (shopId) {
            api.get<{ data: Event[] }>(`events/${shopId}`).then((res) => {
                if (res.status === 200 && res.data.data) {
                    const result = res.data.data
                    eventItems.push(...res.data.data)
                    let event = result?.find(f => f?.id === eventId!)
                    setEvent(event)
                }
            });
        }
        return eventItems
    }, [shopId])

    // const items = eventLists.filter(p => p.id !== eventId).slice(0, 4)

    // const sameCategoryItems = eventLists.filter(c => c.categories[0].id === event?.categories[0].id && c.id !== eventId)
    const items = eventLists
        .filter(p => p.id !== eventId && p.status === "active")
        .slice(0, 4);

    const sameCategoryItems = eventLists
        .filter(c => c.categories[0].id === event?.categories[0].id && c.id !== eventId && c.status === "active")
        .slice(0, 4);

    const categoryLength = sameCategoryItems.length < 4

    if (categoryLength) {
        const toAddLength = 4 - sameCategoryItems.length
        const otherCategoryProducts = items.filter(c => c.categories[0].id !== event?.categories[0].id)

        let extraProduct = otherCategoryProducts.slice(0, toAddLength)
        extraProduct.forEach(e => {
            sameCategoryItems.push(e)
        })
    }

    const eventAddToCartClickHandler = () => {
        if (event && event.shopId) {
            dispatch(eventAddToCart({
                eventId: event?.id || '',
                eventType: event?.type || '',
                names: event?.names.map((n: { language: string, name: string }) => ({ language: (n.language), name: (n.name) })),
                prices: event?.prices.map(price => {
                    return {
                        id: price.id,
                        size: price.size,
                        price: price.price,
                        offerPrice: price.offerPrice || 0
                    }
                }),
                foodPreferences: event?.foodPreferences || [],
                volunteers: event?.volunteers || [],
                eventRule: event?.eventRule || '',
                cultureVouchers: event?.cultureVouchers || [],
                category: event?.categories[0].id,
                withoutRegistration: event?.withoutRegistration || ''
            }, event?.shopId))
        }
        navigate("/checkout-event");
        props.onClose?.()
    }

    React.useEffect(() => {
        if (event && event.image) {
            setActualImage(event.image)
        }
    }, [event])

    return (
        <UserLayout>
            {shopId && <ShopProvider shopId={shopId}>
                <div className="container p-3">
                    <div className='d-flex justify-content-between'>
                        <div className='hstack gap-2 mt-2'>
                            <div className={style.productName}>{event?.names?.length! > 1 ?
                                event?.names.find(n => n.language === translationLanguage)?.name! :
                                event?.names[0].name!}</div>
                            <div>
                                <FacebookShareButton
                                    url={`${window.location.origin}/event/${shopId}/${eventId}`}
                                    className="facebookSharing"
                                    hashtag={`#${event?.names?.length! > 1 ?
                                        event?.names.find(n => n.language === translationLanguage)?.name! :
                                        event?.names[0].name!}`}
                                >
                                    <FacebookIcon size={34} />
                                </FacebookShareButton>
                            </div>
                        </div>
                        {width > 500 && <Button className={style.previousButton} size="sm" onClick={() => goToPreviousPage(shopId)}>{t("Go to shop")}</Button>}
                        {width <= 500 && <div className="mt-2" onClick={() => goToPreviousPage(shopId)}><Feather.ArrowLeftCircle /></div>}
                    </div>
                    <hr />

                    <div className='row mb-3'>

                        <div className='col-lg-6 col-sm-12 col-xs-12 col-md-12'>
                            <Row>
                                {width > 500 && event?.additionalImages.length !== 0 &&
                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        {event?.additionalImages.length !== 0 ?

                                            <Row>
                                                {<Col lg={3} md={3} sm={3} xs={3}>
                                                    <div className='overflow-auto' style={{ height: "400px" }}>
                                                        <p className='mt-3'>
                                                            <img
                                                                src={event?.image}
                                                                style={{ cursor: "pointer" }}
                                                                onMouseOver={() => setActualImage(event!.image)}
                                                                alt=""
                                                                width="70px" height="70px" className="img-thumbnail"
                                                            /></p>
                                                        {event?.additionalImages.map((additionalimage, index) => (
                                                            <p key={index} className='mt-3'>
                                                                <img
                                                                    src={additionalimage !== undefined ? additionalimage : ""}
                                                                    onMouseOver={() => setActualImage(additionalimage)}
                                                                    style={{ cursor: "pointer" }}
                                                                    alt="" width="70px" height="70px" className="img-thumbnail" />
                                                            </p>

                                                        ))}
                                                    </div>
                                                </Col>}
                                                <Col lg={9} md={9} sm={9} xs={9}>

                                                    <img src={actualImage ? actualImage : ''} alt="icon" className={style.bigImage2} />

                                                </Col>
                                            </Row>
                                            :
                                            <img src={event?.image ? event?.image : ''} alt="icon" className={style.bigImage2} />
                                        }

                                    </Col>
                                }
                                {width > 500 && event?.additionalImages.length === 0 &&
                                    <img src={event?.image ? event?.image : ''} alt="icon" className={style.additionalImage} />
                                }
                                {width <= 500 &&
                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        {event?.additionalImages.length !== 0 ?
                                            <Carousel fade={true} slide={false}>

                                                <Carousel.Item key={0} interval={3000} >

                                                    <img
                                                        className="d-block w-100"
                                                        src={(event?.image && event?.image !== null) ? event?.image : ''}
                                                        alt={`${0} slide`}
                                                    />

                                                    <Carousel.Caption />

                                                </Carousel.Item>

                                                {event?.additionalImages.map((additionalImage, index) => (
                                                    <Carousel.Item key={index + 1} interval={3000} >

                                                        <img
                                                            className="d-block w-100"
                                                            src={(additionalImage && additionalImage !== null) ? additionalImage : ''}
                                                            alt={`${index + 1} slide`}
                                                        />

                                                        <Carousel.Caption />

                                                    </Carousel.Item>
                                                ))}

                                            </Carousel>
                                            :
                                            <img src={event?.image ? event?.image : ''} alt="icon" className={width <= 420 ? style.bigImage : style.bigImage2} />
                                        }

                                    </Col>
                                }
                            </Row>
                        </div>

                        <div className='col-lg-3 col-sm-12 col-xs-12 col-md-12'>
                            <div className={`text-capitalize ${style.productName} mt-2`}>{t("Event Location")}</div>
                            <div className='mt-1'>
                                <Calender /> {eventDate}
                            </div>
                            <div className='mt-1'>
                                <Clock /> {to12Hour(event?.fromTime)} to {to12Hour(event?.toTime)}
                            </div>
                            <div className='mt-1'>
                                <LocationOn /> <span className={style1.location} onClick={gmapHandler}>{event?.location}</span>
                            </div>


                        </div>

                        <div className={`col-lg-3 col-sm-12 col-xs-12 col-md-12`}>
                            <div className={`mb-2 ${style1.editOrderContent} `}>
                                {event && event.prices && event.prices.length > 0 && (
                                    <div >
                                        <div className={`text-capitalize ${style.productName} mt-2`}>{t("Event Fees")}:</div>
                                        {event.prices.map((p: any) => {
                                            const priceIndex = event.prices.findIndex((fp: any) => fp.size === p.size);
                                            const price = getEventPrice(event, priceIndex);
                                            const offerPrice = getEventOfferPrice(event, priceIndex);
                                            return (
                                                <div className='hstack gap-2 justify-content-between' key={p.size}>
                                                    <span className='text-capitalize'>{p.size}</span>
                                                    <div className='hstack gap-2'>
                                                        {offerPrice > 0 ? (
                                                            <React.Fragment>
                                                                <span>
                                                                    {'('}
                                                                    <span className={style.offerPrice}>{toCurrencyFormat(price, shop?.currency)}</span>
                                                                </span>
                                                                <span>
                                                                    {toCurrencyFormat(offerPrice, shop?.currency)}{')'}
                                                                </span>
                                                            </React.Fragment>
                                                        ) : (
                                                            <span>{'('}{toCurrencyFormat(price, shop?.currency)}{')'}</span>
                                                        )}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                )}
                                {event?.eventStatus === "inactive" && <p className={`${style.stock} mb-3`}>{t("Reservation has been closed.")}</p>}
                            </div>
                            <Button className={`${style.cartButton} mt-2`} size='sm' onClick={eventAddToCartClickHandler} disabled={event?.eventStatus === "active" ? false : true}>{t("Book Now")}</Button>
                        </div>
                        <p className={` mt-2 ${style.description}`} dangerouslySetInnerHTML={{
                            __html: (event?.descriptions?.length! > 1 ?
                                event?.descriptions.find(n => n.language === translationLanguage)?.description! :
                                event?.descriptions[0].description!) || ''
                        }}>
                        </p>
                    </div>

                </div>

                <div >
                    <div className='row'>
                        <div className='hstack justify-content-between'>
                            <div className={style.productName}>{t("Recommended Products")}</div>
                            {/* {items && items?.length > 0 && <div className={style2.viewAll}>
                                <div className={style2.label} onClick={ViewAllHandler}>{view}
                                </div>
                                <ChevronRightDark />
                            </div>} */}
                        </div>
                    </div>
                    {/* <div className='row'>
                        {view === t('View All') && items.map((oneproductdata, index) => (
                            <div className='col-sm-6 col-md-4 col-lg-3 mb-1 hstack gap-1'>
                                <div className="row border rounded m-1 vstack gap-2">
                                    <div className="col sm-12">
                                        <div className='w-100 p-2  mb-2'>
                                            <div>
                                                <img alt="img" src={oneproductdata?.image} className={style.blogImageSuper} />
                                            </div>
                                            <div className={`text-capitalize ${style.productName}`}>
                                                {oneproductdata?.names[0].name}
                                            </div>

                                            <Button className={style.addToCartButton} onClick={() => goToProduct(shopId, oneproductdata?.id)} >{t("View Event")}</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                        {view === t('View Less') && eventLists.map((oneproductdata, index) => (
                            <div className='col-sm-6 col-md-6 col-lg-3 hstack gap-1'>
                                <div className="row border rounded m-2">
                                    <div className="col sm-12">
                                        <div className='w-100 p-1'>
                                            <div>
                                                <img alt="img" src={oneproductdata?.image} className={style.blogImageSuper} />
                                            </div>
                                            <div className={`text-capitalize ${style.productName}`}>
                                                {oneproductdata?.names[0].name}
                                            </div>

                                            <Button className={style.addToCartButton} onClick={() => goToProduct(shopId, oneproductdata?.id)} >{t("View Event")}</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div> */}
                    <div className='row'>
                        {sameCategoryItems.length > 0
                            ? sameCategoryItems
                                .filter(p => p.id !== eventId && p.status === "active")
                                .map((oneproductdata, index) => (
                                    <div key={index} className='col-sm-6 col-lg-3 col-xxl-3 mb-2'>
                                        <Card
                                            img={oneproductdata.image}
                                            name={oneproductdata?.names?.length! > 1 ?
                                                oneproductdata?.names.find(n => n.language === translationLanguage)?.name :
                                                oneproductdata?.names[0].name}
                                            buttonText={t('View Event')}
                                            onClick={() => goToProduct(shopId, oneproductdata.id)}
                                        // favourites
                                        // isFavourite={isFavourite}
                                        // onFavouritesClick={favouritesClickHandler}

                                        />
                                    </div>
                                ))
                            : items
                                .filter(oneproductdata => oneproductdata.status === "active")
                                .map((oneproductdata, index) => (
                                    <div key={index} className='col-sm-6 col-lg-3 col-xxl-3 mb-2'>
                                        <Card
                                            img={oneproductdata.image}
                                            name={oneproductdata?.names?.length! > 1 ?
                                                oneproductdata?.names.find(n => n.language === translationLanguage)?.name :
                                                oneproductdata?.names[0].name}
                                            buttonText={t('View Event')}
                                            onClick={() => goToProduct(shopId, oneproductdata.id)}
                                        // favourites
                                        // isFavourite={isFavourite}
                                        // onFavouritesClick={favouritesClickHandler}

                                        />
                                    </div>
                                ))
                        }
                    </div>
                </div>
            </ShopProvider>}

        </UserLayout >
    );
}

export default ProductViewEvent;