import { useSelector } from 'react-redux';
import CheckoutAppointmentDetails from "../../components/sections/CheckoutAppointmentDetails";
import CheckoutPaymentSection from "../../components/sections/CheckoutPaymentSection";
import ShouldAuthenticate from "../../components/sections/ShouldAuthenticate";
import UserLayout from "../../components/sections/UserLayout";
import CheckoutProvider, { CheckoutContext } from "../../context/CheckoutProvider";
import { TranslationLanguage, useT } from '../../i18n';
import { StoreState } from '../../redux/reducers';
import { AppointmentCart } from '../../redux/reducers/appointmentCart.reducer';
import { Shop } from '../../redux/reducers/shop.reducer';
import ceckoutStyle from '../../styles/components/checkout-shopping-cart.module.scss';
import style from '../../styles/pages/checkout.module.scss';
import { toCurrencyFormat } from "../../utils";

const CheckoutAppointment = () => {
    const t = useT()
    const appointmentCart = useSelector<StoreState, AppointmentCart>((state) => state.appointmentCart);
    const shop = useSelector<StoreState, Shop | null>(state => state.shops.find(s => s.id === state.appointmentCart.shopId) || null)
    const translationLanguage = useSelector<StoreState, TranslationLanguage>(state => state.translationLanguage)

    return (
        <ShouldAuthenticate>
            <UserLayout>
                <CheckoutProvider>

                    <CheckoutContext.Consumer>


                        {context => {
                            return (

                                <>
                                    <div className='row'>
                                        <div className={style.title}>{appointmentCart.item?.names?.length! > 1 ?
                                            appointmentCart.item?.names.find(n => n.language === translationLanguage)?.name :
                                            appointmentCart.item?.names[0].name} {t("Booking")}</div>
                                        <div className='col-lg-12 order-2 order-lg-1'>
                                            {/* <SectionWithTitle title='Order type' className='mb-4' /> */}

                                            {/* <CheckoutCustomerInformation /> */}
                                            <CheckoutAppointmentDetails />
                                            <div className={ceckoutStyle.container}>
                                                <div className={`${ceckoutStyle.priceSection}`}>
                                                    <div className={ceckoutStyle.inTotal}>{t("In total")}</div>
                                                    <div className={ceckoutStyle.inTotal}>{toCurrencyFormat(context?.total)}</div>
                                                </div>
                                            </div>

                                            <CheckoutPaymentSection otherPayments={shop?.otherPayments} countinueHandler={context?.continueReservationClickHandler} paytrailStatus={shop?.paytrail.status} category='Booking' />
                                        </div>
                                    </div>
                                </>
                            )
                        }}

                    </CheckoutContext.Consumer>
                </CheckoutProvider>
            </UserLayout>
        </ShouldAuthenticate>

    )
}

export default CheckoutAppointment;