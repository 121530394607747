import React from 'react'
import { useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import AppointmentCard, { AppointmentCardProps } from '../../components/cards/AppointmentCard'
import AdminLayout from '../../components/sections/AdminLayout'
import AdminPanelHeader from '../../components/sections/AdminPanelHeader'
import Search from '../../components/utils/Search'
import { AdminContext, AdminContextType } from '../../context/AdminProvider'
import { UserContext, UserContextType } from '../../context/UserProvider'
import { TranslationLanguage, useT } from '../../i18n'
import { StoreState } from '../../redux/reducers'
import { Appointment as AppointmentType } from '../../redux/reducers/appointment.reducer'
import style from '../../styles/components/shop-product-details.module.scss'
import { getAppointmentMinOfferPrice, getAppointmentMinPrice } from '../../utils'

const Appointments = () => {

    const adminContext = React.useContext<AdminContextType | null>(AdminContext)
    const userContext = React.useContext<UserContextType | null>(UserContext)

    const appointments = useSelector<StoreState, AppointmentType[]>(state => state.appointment)
    const translationLanguage = useSelector<StoreState, TranslationLanguage>(state => state.translationLanguage)
    const t = useT()

    const [filteredAppointments, setFilteredAppointments] = React.useState<AppointmentType[]>(appointments)

    const searchRef = React.useRef<HTMLInputElement>(null)
    const [searchQuery, setSearchQuery] = useSearchParams()

    const searchTerm = searchQuery.get('search') || ''

    const searchTermChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setSearchQuery({ search: e.target.value })
    }

    React.useEffect(() => {
        if (searchTerm !== "") {
            const filteredAppointments = appointments.filter(f => f.names?.length! > 1 ?
                f.names.find(n => n.language === translationLanguage)?.name :
                f.names[0].name.toLowerCase().includes(searchTerm.toLowerCase()))
            setFilteredAppointments(filteredAppointments)
        } else {
            const filteredAppointments = appointments
            setFilteredAppointments(filteredAppointments)
        }
    }, [searchTerm, appointments])

    React.useEffect(() => {
        if (searchTerm !== '') {
            searchRef.current?.focus()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <AdminLayout>
        <div className='row mb-4'>
            <AdminPanelHeader
                btnText={t('Add Appointment')}
                title={t('Appointments')}
                onBtnClick={adminContext?.onAddAppointment}
                isShowTableIcon={true}
            />
            <div className={style.searchInputContainer}>
                <Search
                    onChange={searchTermChangeHandler}
                    value={searchTerm}
                    onFocus={() => {
                        userContext?.mainContentRef?.current?.scrollTo({
                            behavior: 'smooth',
                            top: 270
                        })
                    }}
                    innerRef={searchRef}
                />
            </div>
            {(filteredAppointments && filteredAppointments.length > 0) ? (filteredAppointments?.map((appointment, i) => {
                const price = getAppointmentMinPrice(appointment)
                const offerPrice = getAppointmentMinOfferPrice(appointment)

                return <Appointment
                    name={appointment?.names?.length! > 1 ?
                        appointment?.names.find(n => n.language === translationLanguage)?.name :
                        appointment?.names[0].name}
                    img={appointment.image}
                    offerLabel={appointment.offerLabel}
                    price={price}
                    status={appointment.status}
                    offerPrice={offerPrice}
                    id={appointment.id}
                    key={i}
                    index={i}
                />
            })) : <div className='d-flex justify-content-center fw-bold'>{t("Oops!! No such result found")}</div>}
        </div>
    </AdminLayout>
}

const Appointment = (props: AppointmentCardProps) => {
    const adminContext = React.useContext<AdminContextType | null>(AdminContext)

    return <div className={adminContext?.isCardStyle ? 'col-sm-6 col-md-4 col-lg-3 my-2 d-flex align-items-center justify-content-center' : ""}>

        <AppointmentCard
            name={props.name}
            img={props.img}
            offerLabel={props.offerLabel}
            price={props.price}
            status={props.status}
            offerPrice={props.offerPrice}
            id={props.id}
            index={props.index}
        />
    </div>
}

export default Appointments