import React, { FC, Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { CheckoutContext, CheckoutContextType } from "../../context/CheckoutProvider";
import { useT } from "../../i18n";
import { StoreState } from "../../redux/reducers";
import { AuthUser } from "../../redux/reducers/authUser.reducer";
import { EventCart } from "../../redux/reducers/eventCart.reducer";
import { Shop } from "../../redux/reducers/shop.reducer";
import style from '../../styles/pages/checkout.module.scss';
import { getEventCheckoutPrice, toCurrencyFormat } from "../../utils";
import FileUpload from "../utils/FileUpload";
import FormInput from "../utils/FormInput";
import FormTextArea from "../utils/FormTextArea";
import Radio from "../utils/Radio";
import Select from "../utils/Select";
import SectionWithTitle from "./SectionWithTitle";
const CheckoutEventBookingDetails: FC = () => {
    const t = useT()
    const authUser = useSelector<StoreState, AuthUser | null>(state => state.authUser)
    const eventCart = useSelector<StoreState, EventCart>((state) => state.eventCart);
    const checkoutContext = React.useContext<CheckoutContextType | null>(CheckoutContext)
    const cartShop = useSelector<StoreState, Shop | null>(state => state.shops.find(s => s.id === state.eventCart.shopId) || null)

    const [uploadedFile, setUploadedFile] = useState<File | null>(null);
    const [imagePreview, setImagePreview] = useState<string | null>(null);


    React.useEffect(() => {
        if (checkoutContext?.personTypes) {
            checkoutContext?.setTotal?.(checkoutContext?.personTypes?.map((x: any) => getEventCheckoutPrice(x) * x.count).reduce((a, b) => a + b, 0))
        }
    }, [checkoutContext?.personTypes])

    const handleFileSelect = (file: File | null) => {
        if (file) {
            const previewUrl = URL.createObjectURL(file); // Generate preview URL
            setImagePreview(previewUrl); // Set preview
        } else {
            setImagePreview(null);
        }
        setUploadedFile(file);
        checkoutContext?.setVoucherTransactionImage?.(file);
        checkoutContext?.setVoucherTransactionImageError?.('');
    };

    React.useEffect(() => {
        if (checkoutContext?.personTypes) {
            checkoutContext?.setTotal?.(checkoutContext?.personTypes?.map((x: any) => getEventCheckoutPrice(x) * x.count).reduce((a, b) => a + b, 0))
        }
    }, [checkoutContext?.personTypes])

    return (
        <>

            <SectionWithTitle title={t('Participant Informations')} className='mb-4' withRow>
                {!authUser && eventCart.item?.withoutRegistration && eventCart.item?.withoutRegistration === "Yes" && <div className="row">
                    <div className={`col-sm-12 col-lg-4 col-xl-4`}>
                        <FormInput
                            containerClass='mb-3'
                            label={t("Email")}
                            type='text'
                            name="email"
                            value={checkoutContext?.email}
                            errorText={checkoutContext?.emailError}
                            placeholder={'Email'}
                            onChange={(e) => checkoutContext?.emailChangeHandler?.(e)}
                        />
                    </div>
                </div>
                }
                {eventCart.item?.cultureVouchers && eventCart.item?.cultureVouchers.length !== 0 &&
                    <div className="mb-3">
                        <h6>{t("Are you paying with Culture Voucher?")}</h6>

                        <Radio checked={checkoutContext?.cultureVoucher === 'yes'} onClick={() => checkoutContext?.cultureVoucherChangeHandler?.("yes")}>{t("Yes")}</Radio>
                        <div className="d-flex gap-2 pb-2 p-4 pt-2">
                            {checkoutContext?.cultureVoucher === "yes" &&
                                <Fragment>
                                    {eventCart.item?.cultureVouchers.map((cv) => (
                                        <Radio checked={checkoutContext?.cultureVoucherSelected === cv} onClick={() => checkoutContext?.cultureVocuherSelectHandler?.(cv)}>{cv}</Radio>
                                    ))}
                                    {/* <Radio checked={checkoutContext?.cultureVoucherSelected === 'E-Passi'} onClick={() => checkoutContext?.cultureVocuherSelectHandler?.("E-Passi")}>{"E-Passi"}</Radio>
                        <Radio checked={checkoutContext?.cultureVoucherSelected === 'Smartum'} onClick={() => checkoutContext?.cultureVocuherSelectHandler?.("Smartum")}>{"Smartum"}</Radio>
                        <Radio checked={checkoutContext?.cultureVoucherSelected === 'Edenred'} onClick={() => checkoutContext?.cultureVocuherSelectHandler?.("Edenred")}>{"Edenred"}</Radio> */}
                                </Fragment>
                            }
                            {checkoutContext?.cultureVoucherSelectedError && <span className={style.requiredLabel}>( {checkoutContext?.cultureVoucherSelectedError} )</span>}
                        </div>
                        <Radio checked={checkoutContext?.cultureVoucher === 'no'} onClick={() => checkoutContext?.cultureVoucherChangeHandler?.("no")}>{t("No")}</Radio>
                    </div>
                }
                {/* Upload Receipt Section */}
                {checkoutContext?.cultureVoucher === "yes" && (
                    <div className="mb-4">
                        <h6>{("Upload Receipt or Proof of Payment")}</h6>
                        <FileUpload onFileSelect={handleFileSelect} />
                        {checkoutContext?.voucherTransactionImageError && <span className={style.requiredLabel}>( {checkoutContext?.voucherTransactionImageError} )</span>}
                        {imagePreview && (
                            <div className={style.previewContainer}>
                                <h6 className="mt-3">{("Preview:")}</h6>
                                <img
                                    src={imagePreview}
                                    alt="Receipt Preview"
                                    className={style.imagePreview}
                                />
                            </div>
                        )}
                    </div>
                )}

                {checkoutContext?.cultureVoucher === "yes" &&
                    checkoutContext?.bookingDetails?.map((b: any, i: number) => (
                        <Fragment>
                            <div className={`col-sm-6 col-lg-${eventCart.item?.foodPreferences ? '5' : '7'} col-xl-${eventCart.item?.foodPreferences ? '5' : '7'}`}>
                                <FormInput
                                    containerClass='mb-3'
                                    type='text'
                                    name="name"
                                    value={b.name}
                                    errorText={checkoutContext?.nameError}
                                    placeholder={t('Full Name')}
                                    onChange={(e) => checkoutContext?.inputHanlder?.(e, i)}
                                />
                            </div>
                            <div className='col-sm-6 col-lg-3 col-xl-3'>
                                <FormInput
                                    containerClass='mb-3'
                                    name="text"
                                    value={b.type.size}
                                    placeholder={t('Select Type')}
                                    disabled={true}
                                />
                            </div>
                            {eventCart.item?.foodPreferences && eventCart.item?.foodPreferences.length > 0 &&
                                <div className='col-sm-6 col-lg-2 col-xl-2'>
                                    <Select
                                        containerClass='mb-3'
                                        name="foodPreference"
                                        value={b.foodPreference}
                                        errorText={checkoutContext?.foodPreferenceError}
                                        options={eventCart.item?.foodPreferences.map((fp: string) => (
                                            { text: fp, value: fp }
                                        ))}
                                        placeholder={t('Choice of food')}
                                        onChange={(e) => checkoutContext?.foodChoiceHanlder?.(e, i)}
                                    />
                                </div>
                            }
                        </Fragment>
                    ))

                }
                {checkoutContext?.cultureVoucher === "no" &&
                    <Fragment>
                        {checkoutContext?.personTypes?.map((price: any, i: number) => {
                            return (
                                <div className={`col-sm-6 col-lg-3 col-xl-3`}>
                                    <FormInput
                                        containerClass='mb-3'
                                        type='text'
                                        name={price.size}
                                        placeholder={t(`Number`)}
                                        label={`No. of ${price.size} (${toCurrencyFormat(getEventCheckoutPrice(price), cartShop?.currency)} )`}
                                        value={price.count ? price.count : ''}
                                        onChange={(e) => checkoutContext.typeHanlder?.(e, price)}
                                    />
                                </div>)
                        })}
                        {checkoutContext?.participantError && <span className={style.requiredLabel}>( {checkoutContext?.participantError} )</span>}
                        {checkoutContext?.bookingDetails?.map((b: any, i: number) => (
                            <Fragment>
                                <div className={`d-flex gap-3`}>
                                    <FormInput
                                        containerClass='mb-3'
                                        type='text'
                                        name="name"
                                        errorText={b.name ? '' : checkoutContext?.nameError}
                                        value={b.name}
                                        placeholder={t('Full Name')}
                                        onChange={(e) => checkoutContext?.inputHanlder?.(e, i)}
                                    />
                                    <FormInput
                                        containerClass='mb-3'
                                        name="text"
                                        value={b.type.size}
                                        placeholder={t('Select Type')}
                                        disabled={true}
                                    />
                                    {eventCart.item?.foodPreferences && eventCart.item?.foodPreferences.length > 0 &&
                                        <Select
                                            containerClass='mb-3'
                                            name="foodPreference"
                                            value={b.foodPreference}
                                            errorText={eventCart.item?.foodPreferences.length > 0 && b.foodPreference ? '' : checkoutContext?.foodPreferenceError}
                                            options={eventCart.item?.foodPreferences.map((fp: string) => (
                                                { text: fp, value: fp }
                                            ))}
                                            placeholder={t('Choice of food')}
                                            onChange={(e) => checkoutContext?.foodChoiceHanlder?.(e, i)}
                                        />
                                    }
                                </div>

                            </Fragment>

                        ))}
                    </Fragment>
                }
                {!authUser && eventCart.item?.withoutRegistration && eventCart.item?.withoutRegistration === "Yes" && <div className="row">
                    <div className={`col-sm-12 col-lg-6 col-xl-6`}>
                        <FormTextArea
                            containerClass='mb-3'
                            label={t("Additional Info")}
                            value={checkoutContext?.additionalInfo}
                            errorText={checkoutContext?.additionalInfoError}
                            placeholder={t('Text...')}
                            onChange={(e) => checkoutContext?.additionalInfoChangeHandler?.(e)}
                        />
                    </div>
                </div>
                }
            </SectionWithTitle>
        </>
    )
}

export default CheckoutEventBookingDetails;