import { nanoid } from '@reduxjs/toolkit'
import { ContentState, EditorState, convertFromHTML, convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import React from 'react'
import { Editor } from 'react-draft-wysiwyg'
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { useDispatch, useSelector } from 'react-redux'
import { api } from '../../api'
import { DEFAULT_LANGUAGES } from '../../config'
import { AdminContext, AdminContextType } from '../../context/AdminProvider'
import { TranslationLanguage, useT } from '../../i18n'
import { AddFood, addFood, addLanguage, addSize, updateFood } from '../../redux/actions'
import { StoreState } from '../../redux/reducers'
import { AuthAdmin } from '../../redux/reducers/authAdmin.reducer'
import { Category } from '../../redux/reducers/category.reducer'
import { ExtraTopping } from '../../redux/reducers/extraTopping.reducer'
import { Food, FoodType } from '../../redux/reducers/food.reducer'
import { Spice } from '../../redux/reducers/spice.reducer'
import { Topping } from '../../redux/reducers/topping.reducer'
import { AppDispatch } from '../../redux/store'
import errorStyle from '../../styles/components/error-text.module.scss'
import formStyle from '../../styles/components/form.module.scss'
import style from '../../styles/components/selectable-tile-section.module.scss'
import { toNumber, toString } from '../../utils'
import { ToastType, useToast } from '../ToastProvider'
import ModalWithHeader from '../modals/ModalWithHeader'
import SelectableTileSection from '../sections/SelectableTileSection'
import Camera from '../svgs/Camera'
import Edit from '../svgs/Edit'
import ErrorText from '../utils/ErrorText'
import FormInput from '../utils/FormInput'
import Radio from '../utils/Radio'
import ResponsiveFormInput from '../utils/ResponsiveFormInput'
import SmallImage from '../utils/SmallImage'
import FoodPriceForm from './FoodPriceForm'
import InstantInputForm from './InstantInputForm'

interface Name {
    language: string
    name: string
    errorText: string
    id: string
}

interface Description {
    language: string
    description: string
    editContent: EditorState
    errorText: string
    id: string
}

interface Price {
    price: string
    priceError: string
    offerPrice: string
    offerPriceError: string
    size: string
    id: string
}

interface FoodAdditionImage {
    image: string
    id: string
}

interface FoodFormProps {
    onClose?: () => void
    id?: string | null
}

const FoodForm = (props: FoodFormProps) => {
    const dispatch = useDispatch<AppDispatch>()
    const toast = useToast()
    const t = useT()

    const authAdmin = useSelector<StoreState, AuthAdmin | null>(state => state.authAdmin)
    const adminContext = React.useContext<AdminContextType | null>(AdminContext)
    const translationLanguage = useSelector<StoreState, TranslationLanguage>(state => state.translationLanguage)
    const languages = useSelector<StoreState, string[]>(state => state.languages)
    const categories = useSelector<StoreState, Category[]>(state => state.categories)
    const toppings = useSelector<StoreState, Topping[]>(state => state.toppings)
    const extraToppings = useSelector<StoreState, ExtraTopping[]>(state => state.extraToppings)
    const spices = useSelector<StoreState, Spice[]>(state => state.spices)
    const sizes = useSelector<StoreState, string[]>(state => state.sizes)

    const food = useSelector<StoreState, Food | null>(state => props.id ? state.foods.find(f => f.id === props.id) || null : null)
    const product = useSelector<StoreState, Food[]>(state => state.foods)
    const [onCamera, setOnCamera] = React.useState<boolean>(false)
    const [selectedLanguages, setSelectedLanguages] = React.useState<string[]>(DEFAULT_LANGUAGES)
    const [selectedLanguagesError, setSelectedLanguagesError] = React.useState<string>('')
    const [additionalImages, setAdditionalImages] = React.useState<FoodAdditionImage[]>([])
    const [additionalImagesError, setAdditionalImagesError] = React.useState<string>('')
    const [alternatesCount, setAlternatesCount] = React.useState<string>('')
    const [alternatesCountError, setAlternatesCountError] = React.useState<string>('')
    const [selectedCategories, setSelectedCategories] = React.useState<string[]>([])
    const [selectedSizes, setSelectedSizes] = React.useState<string[]>([])
    const [selectedSizesError, setSelectedSizesError] = React.useState<string>('')
    const [selectedCategoriesError, setSelectedCategoriesError] = React.useState<string>('')
    const [descriptions, setDescriptions] = React.useState<Description[]>([])
    const [image, setImage] = React.useState<any>(authAdmin?.shop.theme.bannerImage ? authAdmin?.shop.theme.bannerImage : '')
    const [imageError, setImageError] = React.useState<string>('')
    const [names, setNames] = React.useState<Name[]>([])
    const [offerLabel, setOfferLabel] = React.useState<string>('')
    const [offerLabelError, setOfferLabelError] = React.useState<string>('')
    const [prices, setPrices] = React.useState<Price[]>([])
    const [tax, setTax] = React.useState<string>('')
    const [taxError, setTaxError] = React.useState<string>('')
    const [stock, setStock] = React.useState<string>('')
    const [productCode, setProductCode] = React.useState<string>('')
    const [productCodeError, setProductCodeError] = React.useState<string>('')
    const [manufacture, setManufacture] = React.useState<string>('')
    const [manufactureError, setManufactureError] = React.useState<string>('')
    const [expectedDate, setExpectedDate] = React.useState<string>('')
    const [expectedDateError, setExpectedDateError] = React.useState<string>('')
    const [expiryDate, setExpiryDate] = React.useState<string>('')
    const [expiryDateError, setExpiryDateError] = React.useState<string>('')
    const [stockError, setStockError] = React.useState<string>('')
    const [selectedToppings, setSelectedToppings] = React.useState<string[]>([])
    const [selectedToppingsError, setSelectedToppingsError] = React.useState<string>('')
    const [selectedAlternateToppings, setSelectedAlternateToppings] = React.useState<string[]>([])
    const [selectedAlternateToppingsError, setSelectedAlternateToppingsError] = React.useState<string>('')
    const [selectedExtraToppings, setSelectedExtraToppings] = React.useState<string[]>([])
    const [selectedExtraToppingsError, setSelectedExtraToppingsError] = React.useState<string>('')
    const [selectedSpices, setSelectedSpices] = React.useState<string[]>([])
    const [selectedSpicesError, setSelectedSpicesError] = React.useState<string>('')
    const [type, setType] = React.useState<FoodType>(authAdmin?.category !== 'Product' ? 'detail' : 'simple')
    const [typeError, setTypeError] = React.useState<string>('')
    const [price, setPrice] = React.useState<string>('')
    const [priceError, setPriceError] = React.useState<string>('')
    const [offerPrice, setOfferPrice] = React.useState<string>('')
    const [offerPriceError, setOfferPriceError] = React.useState<string>('')
    // const [description, setDescription] = React.useState<EditorState[]>([EditorState.createEmpty()])

    const [loading, setLoading] = React.useState<boolean>(false)

    const languageClickHandler = (lng?: string) => {
        if (lng && lng !== '') {
            setSelectedLanguages(prev => {
                return prev.some(p => p === lng)
                    ? prev.length > 1
                        ? prev.filter(p => p !== lng)
                        : prev
                    : [
                        ...prev,
                        lng
                    ]
            })

            setSelectedLanguagesError('')
        }
    }

    const addLanguageClickHandler = (language: string) => {
        dispatch(addLanguage(language))
    }

    const nameChangeHandler = (id: string | null, value: string) => {
        setNames(prev => {
            const names = [...prev]
            const index = names.findIndex(p => p.id === id)

            if (index > -1) {
                names[index] = {
                    ...names[index],
                    name: value,
                    errorText: ''
                }
            }

            return names
        })
    }

    const descriptionChangeHandler = (lang: string | null, value: string, state: EditorState) => {
        setDescriptions(prev => {
            const descriptions = [...prev]
            const index = descriptions.findIndex(p => p.language === lang)
            if (index > -1) {
                descriptions[index] = {
                    ...descriptions[index],
                    description: value,
                    editContent: state,
                    errorText: ''
                }
            }

            return descriptions
        })
    }

    const priceChangeHandler = (value: string, id?: string) => {
        if (id) {
            setPrices(prev => {
                const prices = [...prev]
                const index = prices.findIndex(p => p.id === id)

                if (index > -1) {
                    prices[index] = {
                        ...prices[index],
                        price: value,
                        priceError: ''
                    }
                }

                return prices
            })
        } else {
            setPrice(value)
            setPriceError('')
        }
    }

    const offerPriceChangeHandler = (value: string, id?: string) => {
        if (id) {
            setPrices(prev => {
                const prices = [...prev]
                const index = prices.findIndex(p => p.id === id)

                if (index > -1) {
                    prices[index] = {
                        ...prices[index],
                        offerPrice: value,
                        offerPriceError: ''
                    }
                }

                return prices
            })
        } else {
            setOfferPrice(value)
            setOfferPriceError('')
        }
    }

    const addNewSizeClickHandler = (size: string) => {
        dispatch(addSize(size))
    }

    const addMoreImageClickHandler = async (image: string) => {
        if (image !== '') {
            setAdditionalImages(prev => {
                return [
                    ...prev,
                    {
                        image: image,
                        id: nanoid()
                    }
                ]
            })
            setAdditionalImagesError('')
        }
    }

    const imageChangeHandler: React.ChangeEventHandler<HTMLInputElement | HTMLFormElement> = async (e) => {
        let response: any
        if (onCamera) {
            const formData: any = new FormData()
            formData.append("file", e.target.files[0]);
            response = await api.post<Response>(`upload-img/food`, formData);
        }
        onCamera ? setImage(response.data.data) : setImage(e.target.value)
        setImageError('')
    }

    const removeAdditionalImage = (id: string) => {
        setAdditionalImages(prev => {
            return prev.filter(img => img.id !== id)
        })
    }

    const categoryClickHandler = (id?: string) => {
        if (id && id !== '') {
            setSelectedCategories(prev => {
                return prev.some(p => p === id)
                    ? prev.filter(p => p !== id)
                    : [
                        ...prev,
                        id
                    ]
            })
            setSelectedCategoriesError('')
        }
    }

    const selectSizeHandler = (size?: string) => {
        if (size && size !== '') {
            adminContext?.setSelectedSizes?.(prev => {
                return prev.some(p => p === size)
                    ? prev.filter(p => p !== size)
                    : [
                        ...prev,
                        size
                    ]
            })
            setSelectedSizesError('')
        }
    }

    const toppingClickHandler = (id?: string) => {
        if (id && id !== '') {
            setSelectedToppings(prev => {
                return prev.some(p => p === id)
                    ? prev.filter(p => p !== id)
                    : [
                        ...prev,
                        id
                    ]
            })
            setSelectedToppingsError('')
        }
    }

    const extraToppingClickHandler = (id?: string) => {
        if (id && id !== '') {
            setSelectedExtraToppings(prev => {
                return prev.some(p => p === id)
                    ? prev.filter(p => p !== id)
                    : [
                        ...prev,
                        id
                    ]
            })
            setSelectedExtraToppingsError('')
        }
    }

    const alternateToppingClickHandler = (id?: string) => {
        if (id && id !== '') {
            setSelectedAlternateToppings(prev => {
                return prev.some(p => p === id)
                    ? prev.filter(p => p !== id)
                    : [
                        ...prev,
                        id
                    ]
            })
            setSelectedAlternateToppingsError('')
            setAlternatesCountError('')
        }
    }

    const spiceClickHandler = (id?: string) => {
        if (id && id !== '') {
            setSelectedSpices(prev => {
                return prev.some(p => p === id)
                    ? prev.filter(p => p !== id)
                    : [
                        ...prev,
                        id
                    ]
            })
            setSelectedSpicesError('')
        }
    }

    const offerLabelChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setOfferLabel(e.target.value)
        setOfferLabelError('')
    }

    const taxChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setTax(e.target.value)
        setTaxError('')
    }

    const stockChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        const value = e.target.value;

        // Regular expression to check if the value is a positive integer
        const positiveIntegerRegex = /^[1-9]\d*$/;

        if (positiveIntegerRegex.test(value)) {
            setStock(value);
            setStockError('');
        } else {
            setStockError('Please enter a positive integer.');
        }
    };

    const expectedChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setExpectedDate(e.target.value)
        setExpectedDateError('')
    }
    const expiryChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setExpiryDate(e.target.value)
        setExpiryDateError('')
    }

    const productCodeChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        const productCodes = product.filter(p => p.productCode?.toLowerCase() === e.target.value.toLowerCase())
        setProductCode(e.target.value)
        if (productCodes.length > 0) {
            setProductCodeError('Product code already exists')
        } else {
            setProductCodeError('')
        }
    }


    const manufactureChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setManufacture(e.target.value)
        setManufactureError('')
    }


    const alternatesCountChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setAlternatesCount(e.target.value)
        setAlternatesCountError('')
    }

    const typeClickHandler = (value: FoodType) => {
        setType(value)
        setTypeError('')
    }

    const productCodeClickHandler = async () => {
        const response = await fetch(
            `https://www.googleapis.com/customsearch/v1?q=${productCode}&key=AIzaSyD3KV5BHg5b19u99t93biHRmHvPBGqQt-Y&cx=34d8ffe590e55489c&searchType=image`)
            .then((response) => response.json());
        selectedLanguages.forEach(lng => {
            const index = names.findIndex(p => p.language.toLowerCase() === lng.toLowerCase())

            if (index > -1) {
                names[index] = {
                    ...names[index],
                    name: response.items[0].title,
                    errorText: ''
                }
            }

            const titleString = response.items[0].title;

            const blocksFromHTML = convertFromHTML(titleString);

            const contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);

            const titleEditorState = EditorState.createWithContent(contentState);

            const htmlContent = draftToHtml(convertToRaw(titleEditorState.getCurrentContent()));

            const indexs = descriptions.findIndex(p => p.language.toLowerCase() === lng.toLowerCase());

            if (indexs > -1) {
                descriptions[indexs] = {
                    ...descriptions[indexs],
                    description: htmlContent,
                    editContent: titleEditorState,
                    errorText: ''
                };
            }
        })

        setNames(names);
        setImage(response.items[0].image.thumbnailLink)
        setDescriptions(descriptions)
        setStock(stock)
        setTax(tax)
        setPrice(price)
        setOfferPrice(offerPrice)
        setExpectedDate(expectedDate)
        setOfferLabel(offerLabel)
        setManufacture(manufacture)
        setSelectedCategories(selectedCategories)

    }

    const submitHandler: React.MouseEventHandler<HTMLButtonElement> = (e) => {
        e.preventDefault()

        let error = false

        if (selectedLanguages.length <= 0) {
            error = true
            setSelectedLanguagesError(t('Language required'))
        }

        names.forEach((name, i) => {
            if (toString(name.name) === '') {
                error = true
                setNames(prev => {
                    const names = [...prev]
                    names[i].errorText = t('Name required')
                    return names
                })
            }
        })

        descriptions.forEach((description, i) => {
            if (toString(description.description) === '') {
                error = true
                setDescriptions(prev => {
                    const descriptions = [...prev]
                    descriptions[i].errorText = t('Description required')
                    return descriptions
                })
            }
        })

        if (selectedCategories.length <= 0) {
            error = true
            setSelectedCategoriesError(t('Categories required'))
        }

        if (toString(type) === '') {
            error = true
            setTypeError(t('Type required'))
        } else if ((['detail', 'simple', 'custom'] as FoodType[]).indexOf(type) === -1) {
            error = true
            setTypeError(t('Invalid type given'))
        }

        // if (toString(image) === '') {
        //     error = true
        //     setImageError('Image required')
        // }
        if (productCode !== '' && productCodeError !== '') {
            error = true
        }

        if (toString(tax) !== '' && toNumber(tax) < 0) {
            error = true
            setTaxError(t('Invalid tax given'))
        }

        if (toString(stock) !== '' && toNumber(stock) < 0 && authAdmin?.category === "Product") {
            error = true
            setStockError(t('Invalid stock given'))
        }

        if (toString(stock) === '' && authAdmin?.category === "Product") {
            error = true
            setStockError(t('Stock required'))
        }

        if (type === 'detail') {
            if (prices.length <= 0) {
                error = true
                setSelectedSizesError(t('Prices required'))
            } else {
                prices.forEach((price, i) => {
                    if (toString(price.size) === '') {
                        error = true
                        setPrices(prev => {
                            const prices = [...prev]
                            prices[i].priceError = (t('Size required'))
                            return prices
                        })
                    }

                    if (toString(price.price) === '') {
                        error = true
                        setPrices(prev => {
                            const prices = [...prev]
                            prices[i].priceError = (t('Price required'))
                            return prices
                        })
                    } else if (toNumber(price.price) < 0) {
                        error = true
                        setPrices(prev => {
                            const prices = [...prev]
                            prices[i].priceError = t('Invalid price given')
                            return prices
                        })
                    }

                    if (toString(price.offerPrice) !== '' && toNumber(price.offerPrice) < 0) {
                        error = true
                        setPrices(prev => {
                            const prices = [...prev]
                            prices[i].offerPriceError = t('Invalid offer price given')
                            return prices
                        })
                    }
                })
            }

            if (selectedAlternateToppings.length > 0) {
                if (toString(alternatesCount) === '') {
                    error = true
                    setAlternatesCountError(t('Alternates count required'))
                } else if (toNumber(alternatesCount) < 0) {
                    error = true
                    setAlternatesCountError(t('Invalid alternates count given'))
                }
            }
        }

        if (type === 'simple') {
            if (toString(price) === '') {
                error = true
                setPriceError(t('Price required'))
            } else if (toNumber(price) < 0) {
                error = true
                setPriceError(t('Invalid price given'))
            }

            if (toString(offerPrice) !== '' && toNumber(offerPrice) < 0) {
                error = true
                setOfferPriceError(t('Invalid offer price given'))
            }
        }

        if (!error) {
            const foodData: AddFood = {
                additionalImages: additionalImages.map(img => toString(img.image)),
                categories: selectedCategories.map(toString),
                descriptions: descriptions.map(d => {
                    return {
                        description: toString(d.description),
                        language: toString(d.language)
                    }
                }),
                image: toString(image),
                names: names.map(n => {
                    return {
                        language: toString(n.language),
                        name: toString(n.name)
                    }
                }),
                offerLabel: toString(offerLabel) !== '' ? toString(offerLabel) : null,
                price: type === 'simple' ? toNumber(price) : null,
                offerPrice: type === 'simple' && toNumber(offerPrice) > 0 ? toNumber(offerPrice) : null,
                tax: toNumber(tax) || null,
                toppings: selectedToppings.map(toString),
                type: type,
                stock: toNumber(stock) || null,
                productCode: productCode || null,
                manufacture: manufacture || null,
                expiryDate: expiryDate || null,
                expectedDate: expectedDate || null,
                alternateToppings: selectedAlternateToppings.map(toString),
                alternateToppingsCount: toString(alternatesCount) !== '' ? toNumber(alternatesCount) : null,
                extraToppings: selectedExtraToppings.map(toString),
                prices: type === 'detail'
                    ? prices.map(p => {
                        return {
                            price: toNumber(p.price),
                            size: toString(p.size),
                            offerPrice: toString(p.offerPrice) !== '' && toNumber(p.offerPrice) > 0 ? toNumber(p.offerPrice) : null
                        }
                    }) : [],
                spices: selectedSpices.map(toString)
            }

            if (props.id && food) {
                setLoading(true)
                dispatch(updateFood(foodData, food.id)).then(() => {
                    toast(t('Product updated'))
                    props.onClose?.()
                }).catch(text => {
                    toast(text, ToastType.ERROR)
                }).finally(() => {
                    setLoading(false)
                })
            } else {
                setLoading(true)
                dispatch(addFood(foodData)).then(() => {
                    toast(t('Product added'))
                    props.onClose?.()
                }).catch(text => {
                    toast(text, ToastType.ERROR)
                }).finally(() => {
                    setLoading(false)
                })
            }
        }
    }

    React.useEffect(() => {
        setPrices((prev): any => {
            const newState = [...prev]
            return adminContext?.selectedSizes?.map<Price>(size => {
                const index = newState.findIndex(p => p.size === size)
                return index > -1
                    ? newState[index]
                    : {
                        offerPrice: '',
                        offerPriceError: '',
                        price: '',
                        priceError: '',
                        size: size,
                        id: nanoid()
                    }
            })
        })
    }, [adminContext?.selectedSizes])

    React.useEffect(() => {
        setNames(prev => {
            return selectedLanguages.map(lng => {
                return prev.find(p => p.language === lng) || {
                    errorText: '',
                    id: nanoid(),
                    language: lng,
                    name: ''
                }
            })
        })

        setDescriptions(prev => {
            return selectedLanguages.map(lng => {
                return prev.find(p => p.language === lng) || {
                    errorText: '',
                    id: nanoid(),
                    language: lng,
                    editContent: EditorState.createEmpty(),
                    description: ''
                }
            })
        })
    }, [selectedLanguages])

    React.useEffect(() => {
        if (props.id && food) {
            setAdditionalImages(food.additionalImages.map(i => ({ id: nanoid(), image: i })))
            setSelectedCategories(food.categories.map(c => c.id))
            setDescriptions(() => {
                const descriptions: Description[] = []

                food.descriptions.forEach(n => {
                    let contenthtmlToDraft = htmlToDraft(n.description);
                    const contentState = ContentState.createFromBlockArray(contenthtmlToDraft.contentBlocks);
                    if (!descriptions.some(p => p.language === n.language)) {
                        descriptions.push({
                            errorText: '',
                            id: n.id,
                            language: n.language,
                            editContent: EditorState.createWithContent(contentState),
                            description: n.description
                        })
                    }
                })

                return descriptions
            })
            setImage(food.image)
            setNames(() => {
                const names: Name[] = []

                food.names.forEach(n => {
                    if (!names.some(p => p.language === n.language)) {
                        names.push({
                            errorText: '',
                            id: n.id,
                            language: n.language,
                            name: n.name
                        })
                    }
                })

                return names
            })
            setOfferLabel(food.offerLabel || '')
            setTax(food.tax?.toString() || '')
            setStock(food.stock?.toString() || '')
            setProductCode(food.productCode?.toString() || '')
            setManufacture(food.manufacture?.toString() || '')
            setExpiryDate(food.expiryDate?.toString() || '')
            setExpectedDate(food.expectedDate?.toString() || '')
            setSelectedToppings(food.toppings.map(t => t.id))
            setType(food.type)
            setPrices(() => {
                const prices: Price[] = []

                food.prices.forEach(n => {
                    if (!prices.some(p => p.size === n.size)) {
                        prices.push({
                            id: n.id,
                            size: n.size,
                            offerPrice: n.offerPrice?.toString() || '',
                            offerPriceError: '',
                            price: n.price.toString(),
                            priceError: ''
                        })
                    }
                })

                return prices
            })
            setSelectedAlternateToppings(food.alternateToppings.map(a => a.id))
            setSelectedExtraToppings(food.extraToppings.map(e => e.id))
            setSelectedSpices(food.spices.map(s => s.id))
            setPrice(food.price?.toString() || '')
            setOfferPrice(food.offerPrice?.toString() || '')
            setAlternatesCount(food.alternateToppingsCount?.toString() || '')
            setSelectedLanguages(() => {
                const lngs: string[] = []
                food.names.forEach(n => {
                    if (lngs.indexOf(n.language) === -1) {
                        lngs.push(n.language)
                    }
                })
                return lngs
            })
            adminContext?.setSelectedSizes?.(() => {
                const sizes: string[] = []
                food.prices.forEach(n => {
                    if (sizes.indexOf(n.size) === -1) {
                        sizes.push(n.size)
                    }
                })
                return sizes
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [food])

    const editorChangeHandler = (editorState: EditorState, lang: string) => {
        const contentState = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        const description = contentState;
        descriptionChangeHandler(lang, description, editorState)
    }

    return <ModalWithHeader
        buttonText={t('Save')}
        headerText={props.id ? t('Edit Product') : t('Add Product')}
        loading={loading}
        onClose={props.onClose}
        onSave={submitHandler}
    >
        <div className='row mb-4'>
            <div className='col'>
                <SelectableTileSection
                    list={languages}
                    getSelectableTileProps={language => ({
                        key: language,
                        active: selectedLanguages.some(slng => slng === language),
                        onClick: languageClickHandler,
                        value: language,
                        label: language
                    })}
                    title={t('Language')}
                    instantInput={{
                        onAdd: addLanguageClickHandler,
                        label: t('Language')
                    }}
                    errorText={selectedLanguagesError}
                />
            </div>
        </div>
        {authAdmin?.category === "Product" &&
            <div className='row'>
                <div className='col-6'>
                    <div className='row'>
                        <FormInput
                            type='text'
                            label={t('Product code')}
                            placeholder={t('Product code')}
                            value={productCode}
                            onChange={productCodeChangeHandler}
                            errorText={productCodeError}
                            containerClass='mb-4'
                        />
                    </div>
                </div>
                <div className='col-2'>
                    <div className='row'>
                        <div className={`${style.button} mt-4 text-center`} onClick={productCodeClickHandler}>{t("Search")}</div>
                    </div>
                </div>
            </div>
        }
        <div className='row'>
            <div className='col'>
                <div className='row'>
                    {selectedLanguages.map(lng => {
                        const name = names.find(n => n.language === lng)

                        return <ResponsiveFormInput
                            responsiveClassName='col-md-6'
                            containerClass='mb-4'
                            type='text'
                            key={lng}
                            label={lng.charAt(0).toUpperCase() + lng.slice(1) + (' Name')}
                            value={name?.name || ''}
                            onChange={e => nameChangeHandler(name?.id || null, e.target.value)}
                            errorText={name?.errorText}
                        />
                    })}
                </div>
            </div>
        </div>
        <div className='row'>
            <div className='col'>
                <div className='row'>
                    {selectedLanguages.map(lng => {
                        const description = descriptions.find(d => d.language === lng)
                        let error = description?.errorText === '' ? false : true
                        console.log(description);

                        return <div className='col-md-6 mb-4'>
                            {lng.charAt(0).toUpperCase() + lng.slice(1) + t(' description')}
                            <Editor
                                key={lng}
                                editorState={description?.editContent}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName border rounded-5 p-3"
                                editorClassName="editorClassName"
                                onEditorStateChange={(e) => editorChangeHandler(e, lng)}
                            />
                            <span className={errorStyle.errorText}>{description?.errorText}</span>
                        </div>
                    })}
                </div>
            </div>
        </div>
        {/* <div className='row'>
            <div className='col'>
                <div className='row'>
                    {selectedLanguages.map(lng => {
                        const description = descriptions.find(d => d.language === lng)

                        let error = description?.errorText === '' ? false : true
                        return <div className='col-md-6 mb-4'>
                            {lng.charAt(0).toUpperCase() + lng.slice(1) + ' description'}
                            <Editor
                                key={lng}
                                editorState={description?.editContent}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName={`${error ? 'border border-danger' : ''} wrapperClassName border rounded-5 p-3`}
                                editorClassName="editorClassName"
                                onEditorStateChange={(e) => editorChangeHandler(e, lng)}
                            />
                            <span className={errorStyle.errorText}>{description?.errorText}</span>
                        </div>
                    })}
                </div>
            </div>
        </div> */}
        {/* <div className='row'>
            <div className='col'>
                <div className='row'>
                    {selectedLanguages.map(lng => {
                        const description = descriptions.find(d => d.language === lng)

                        return <ResponsiveTextArea
                            key={lng}
                            label={lng.charAt(0).toUpperCase() + lng.slice(1) + ' description'}
                            value={description?.description || ''}
                            onChange={e => descriptionChangeHandler(description?.id || null, e.target.value)}
                            responsiveClassName='col-md-6'
                            containerClass='mb-4'
                            errorText={description?.errorText}
                        />
                    })}
                </div>
            </div>
        </div> */}

        <div className='row mb-4'>
            <div className='col'>
                <FormInput
                    type={onCamera ? 'file' : 'text'}
                    label={t('Image')}
                    placeholder={t('Image')}
                    value={onCamera ? image.filename : image}
                    onChange={imageChangeHandler}
                    containerClass='mb-4'
                    rightRenderer={!onCamera ? <Camera strokeWidth={2} /> : <Edit />}
                    onRightRendererClick={() => setOnCamera(onCamera === true ? false : true)}
                    errorText={imageError}
                />
                <div className='position-relative'>
                    <div className='hstack gap-2 flex-wrap'>
                        {image !== '' && <SmallImage
                            image={image}
                        />}
                        {additionalImages.length > 0 && additionalImages.map(img => {
                            return <SmallImage
                                key={img.id}
                                image={img.image}
                                onClose={() => removeAdditionalImage(img.id)}
                            />
                        })}
                        <InstantInputForm
                            onAdd={addMoreImageClickHandler}
                            type="food"
                            label={t('More image')}
                        />
                    </div>
                    <ErrorText errorText={additionalImagesError} />
                </div>
            </div>
        </div>

        <div className='row mb-4'>
            <div className='col'>
                <SelectableTileSection
                    list={categories}
                    addButtonText={t('Add Category')}
                    errorText={selectedCategoriesError}
                    getSelectableTileProps={category => ({
                        key: category.id,
                        value: category.id,
                        label: category?.names?.length! > 1 ?
                            category?.names.find(n => n.language === translationLanguage)?.name :
                            category?.names[0].name,
                        active: selectedCategories.some(ss => ss === category.id),
                        onClick: categoryClickHandler,
                        onEdit: () => adminContext?.onEditCategory?.(category.id),
                        error: selectedCategoriesError !== ''
                    })}
                    onAdd={adminContext?.onAddCategory}
                    title={t('Categories')}
                />
            </div>
        </div>

        <div className='row'>
            <div className='col'>
                <FormInput
                    type='text'
                    label={t('Offer label')}
                    placeholder={t('Offer label')}
                    value={offerLabel}
                    errorText={offerLabelError}
                    onChange={offerLabelChangeHandler}
                    containerClass='mb-4'
                />
            </div>
        </div>
        {authAdmin?.category !== "Product" &&
            <div className='row mb-4'>
                <div className='col'>
                    <div className='position-relative'>
                        <div className={formStyle.sectionHeader}>{t("Type")}</div>
                        <div className='hstack gap-3'>
                            <Radio checked={type === 'detail'} onClick={() => typeClickHandler('detail')}>{t("Detail")}</Radio>
                            <Radio checked={type === 'simple'} onClick={() => typeClickHandler('simple')}>{t("Simple")}</Radio>
                        </div>
                        <ErrorText errorText={typeError} />
                    </div>
                </div>
            </div>
        }

        {authAdmin?.category === "Product" &&
            <div className='row mb-4'>
                <div className='col'>
                    <div className='position-relative'>
                        <div className={formStyle.sectionHeader}>{t("Type")}</div>
                        <div className=''>
                            <Radio checked={type === 'custom'} onClick={() => typeClickHandler('custom')}>{("Custom")}</Radio>
                        </div>
                        <ErrorText errorText={typeError} />
                    </div>
                </div>
            </div>
        }

        <div className='row mb-4'>
            <div className='col'>
                {type === 'detail' && <SelectableTileSection
                    list={sizes}
                    addButtonText={t('Add Size')}
                    errorText={selectedSizesError}
                    getSelectableTileProps={size => ({
                        key: size,
                        value: size,
                        label: size,
                        active: adminContext?.selectedSizes?.some(s => s === size),
                        onClick: selectSizeHandler,
                        error: selectedSizesError !== ''
                    })}
                    instantInput={{
                        onAdd: addNewSizeClickHandler,
                        label: t('Size')
                    }}
                    title={t('Prices')}
                />}
                <div className='row'>
                    {type === 'detail' && adminContext?.selectedSizes?.map(size => {
                        const price = prices.find(p => p.size === size)

                        return <FoodPriceForm
                            responsiveClassName='col-sm-6 col-md-4 my-2'
                            label={price?.size}
                            price={price?.price || ''}
                            priceError={price?.priceError}
                            offerPrice={price?.offerPrice || ''}
                            offerPriceError={price?.offerPriceError}
                            onOfferPriceChange={e => offerPriceChangeHandler(e.target.value, price?.id)}
                            onPriceChange={e => priceChangeHandler(e.target.value, price?.id)}
                            key={size}
                            showOfferPrice
                        />
                    })}

                    {type === 'simple' && <FoodPriceForm
                        responsiveClassName='col-4'
                        price={price}
                        priceError={priceError}
                        offerPrice={offerPrice}
                        offerPriceError={offerPriceError}
                        onOfferPriceChange={e => offerPriceChangeHandler(e.target.value)}
                        onPriceChange={e => priceChangeHandler(e.target.value)}
                        showOfferPrice
                    />}
                </div>
            </div>
        </div>

        <div className='row'>
            <div className='col-12'>
                <FormInput
                    type='number'
                    label={t('Tax')}
                    placeholder={t('Tax')}
                    value={tax}
                    onChange={taxChangeHandler}
                    errorText={taxError}
                    containerClass='mb-4'
                />
            </div>
        </div>

        {authAdmin?.category === "Product" &&
            <div className='row'>
                <div className='col-12'>
                    <FormInput
                        type='number'
                        label={t('Total Stock')}
                        placeholder={t('No. of stock')}
                        value={stock}
                        onChange={stockChangeHandler}
                        errorText={stockError}
                        containerClass='mb-4'
                    />
                </div>
            </div>}

        {authAdmin?.category === "Product" &&
            <div className='row'>
                <div className='col-12'>
                    <FormInput
                        type='date'
                        label={t('Expected Date')}
                        placeholder=''
                        value={expectedDate}
                        onChange={expectedChangeHandler}
                        errorText={expectedDateError}
                        containerClass='mb-4'
                    />
                </div>
            </div>
        }
        {authAdmin?.category === "Food" && <div className='row'>
            <div className='col-12'>
                <FormInput
                    type='date'
                    label={t('Expiry Date')}
                    placeholder=''
                    value={expiryDate}
                    onChange={expiryChangeHandler}
                    errorText={expiryDateError}
                    containerClass='mb-4'
                />
            </div>
        </div>}

        {authAdmin?.category === "Product" &&
            <div className='row'>
                <div className='col-12'>
                    <FormInput
                        type='text'
                        label={t('Manufacture')}
                        placeholder={t('Manufacture')}
                        value={manufacture}
                        onChange={manufactureChangeHandler}
                        errorText={manufactureError}
                        containerClass='mb-4'
                    />
                </div>
            </div>}
        {authAdmin?.category === "Food" &&
            <div className='row mb-4'>
                <div className='col'>
                    <SelectableTileSection
                        list={toppings}
                        addButtonText={t('Add Topping')}
                        errorText={selectedToppingsError}
                        getSelectableTileProps={topping => ({
                            key: topping.id,
                            value: topping.id,
                            label: topping?.names?.length! > 1 ?
                                topping?.names.find(n => n.language === translationLanguage)?.name :
                                topping?.names[0].name,
                            active: selectedToppings.some(ss => ss === topping.id),
                            onClick: toppingClickHandler,
                            onEdit: () => adminContext?.onEditTopping?.(topping.id),
                            error: selectedToppingsError !== ''
                        })}
                        onAdd={adminContext?.onAddTopping}
                        title={t('Default toppings')}
                    />
                </div>
            </div>}

        {type === 'detail' && <React.Fragment>
            <div className='row mb-4'>
                <div className='col'>
                    <SelectableTileSection
                        list={toppings}
                        addButtonText={t('Add Topping')}
                        errorText={selectedAlternateToppingsError}
                        getSelectableTileProps={topping => ({
                            key: topping.id,
                            value: topping.id,
                            label: topping?.names?.length! > 1 ?
                                topping?.names.find(n => n.language === translationLanguage)?.name :
                                topping?.names[0].name,
                            active: selectedAlternateToppings.some(ss => ss === topping.id),
                            onClick: alternateToppingClickHandler,
                            onEdit: () => adminContext?.onEditTopping?.(topping.id),
                            error: selectedAlternateToppingsError !== ''
                        })}
                        onAdd={adminContext?.onAddTopping}
                        title={t('Alternate toppings')}
                    />
                </div>
            </div>

            <div className='row'>
                <div className='col-12'>
                    <FormInput
                        type='number'
                        label={t('Allowed alternate toppings count')}
                        placeholder={t('Allowed alternate toppings count')}
                        value={alternatesCount}
                        onChange={alternatesCountChangeHandler}
                        errorText={alternatesCountError}
                        containerClass='mb-4'
                    />
                </div>
            </div>

            <div className='row mb-4'>
                <div className='col'>
                    <SelectableTileSection
                        list={extraToppings}
                        addButtonText={t('Add Extra Topping')}
                        errorText={selectedExtraToppingsError}
                        getSelectableTileProps={et => ({
                            key: et.id,
                            value: et.id,
                            label: et?.names?.length! > 1 ?
                                et?.names.find(n => n.language === translationLanguage)?.name :
                                et?.names[0].name,
                            active: selectedExtraToppings.some(ss => ss === et.id),
                            onClick: extraToppingClickHandler,
                            onEdit: () => adminContext?.onEditExtraTopping?.(et.id),
                            error: selectedExtraToppingsError !== ''
                        })}
                        onAdd={adminContext?.onAddExtraTopping}
                        title={t('Extra Toppings')}
                    />
                </div>
            </div>

            <div className='row'>
                <div className='col'>
                    <SelectableTileSection
                        list={spices}
                        addButtonText={t('Add Spice')}
                        errorText={selectedSpicesError}
                        getSelectableTileProps={spice => ({
                            key: spice.id,
                            value: spice.id,
                            label: spice?.names?.length! > 1 ?
                                spice?.names.find(n => n.language === translationLanguage)?.name :
                                spice?.names[0].name,
                            active: selectedSpices.some(ss => ss === spice.id),
                            onClick: spiceClickHandler,
                            onEdit: () => adminContext?.onEditSpice?.(spice.id),
                            error: selectedSpicesError !== ''
                        })}
                        onAdd={adminContext?.onAddSpice}
                        title={t('Spices')}
                    />
                </div>
            </div>
        </React.Fragment>}
    </ModalWithHeader>
}

export default FoodForm